import { IconName } from "../../types/icon-type";
import * as Icon from "../icons";
import { FC, useMemo } from "react";

type AppIconProps = {
  name: IconName;
} & Icon.IconProps;

export const AppIcon: FC<AppIconProps> = ({ name, ...props }) => {
  const TmpIcon = useMemo(() => {
    switch (name) {
      case "Facility":
        return Icon.Facility;
      case "FacilityOverview":
        return Icon.FacilityOverview;
      case "Process":
        return Icon.Process;
      case "Energy":
        return Icon.Energy;
      case "Water":
        return Icon.Water;
      case "WasteWater":
        return Icon.WasteWater;
      case "DryWaste":
        return Icon.DryWaste;
      case "GarmentEmbroidery":
        return Icon.GarmentEmbroidery;
      case "GarmentPrints":
        return Icon.GarmentPrints;
      case "GarmentDyeing":
        return Icon.GarmentDyeing;
      case "GarmentFinishing":
        return Icon.GarmentFinishing;
      case "GarmentProcesses":
        return Icon.GarmentProcesses;
      case "GarmentWash":
        return Icon.GarmentWash;
      case "Layers":
        return Icon.Layers;
      case "PlusCircle":
        return Icon.PlusCircle;
      case "MinusCircle":
        return Icon.MinusCircle;
      case "X":
        return Icon.X;
      case "ChevronLeft":
        return Icon.ChevronLeft;
      case "ChevronRight":
        return Icon.ChevronRight;
      case "ProductionFabric":
        return Icon.ProductionFabric;
      case "Traceability":
        return Icon.Traceability;
      case "SuppliersOverview":
        return Icon.SuppliersOverview;
      default:
        return null;
    }
  }, [name]);

  return TmpIcon ? <TmpIcon {...props} /> : <></>;
};
