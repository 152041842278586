import { createElement } from "react";
import { QuestionMatrixDynamicModel } from "survey-core";
import {
  ReactElementFactory,
  SurveyQuestionMatrixDynamicRemoveButton,
} from "survey-react-ui";
import * as Icon from "../icons";

/**
 * Custom styled remove button component for matrix dynamic questions.
 * Extends the base SurveyJS matrix dynamic remove button with custom styling.
 */
class MatrixDynamicRemoveStyledButton extends SurveyQuestionMatrixDynamicRemoveButton {
  /**
   * Gets the matrix question model associated with this button
   * @returns {QuestionMatrixDynamicModel} The matrix dynamic question model
   */
  protected get matrix(): QuestionMatrixDynamicModel {
    return this.props.item.data.question as QuestionMatrixDynamicModel;
  }

  /**
   * Renders the remove button element with custom styling
   * @returns {JSX.Element} The rendered remove button
   */
  protected renderElement(): JSX.Element {
    const question = this.props.item.data.question as QuestionMatrixDynamicModel;
    const removeRowText = this.renderLocString(question.locRemoveRowText);

    return (
      <div>
        <button
          className="flex gap-[7px] items-center px-[21px] py-[7px]"
          type="button"
          disabled={this.matrix.isInputReadOnly}
          onClick={this.handleOnRowRemoveClick}
        >
          <Icon.MinusCircle color="var(--error-500)" />
          <span className="text-sm font-semibold text-error-500">
            {removeRowText}
          </span>
        </button>
      </div>
    );
  }
}

// Register the custom component with SurveyJS
ReactElementFactory.Instance.registerElement(
  "sv-matrixdynamic-styled-remove-btn",
  (props) => {
    return createElement(MatrixDynamicRemoveStyledButton, props);
  }
);