import { FC, useCallback, useContext, useEffect, useState } from "react";
import { SurveyModel } from "survey-core";
import { ApiServiceContext } from "../../contexts";
import { isDisplayMode } from "../../utils";
import { appConfig } from "../../config";
import { useBreakpoint } from "../../hooks";

import {
  NextButton,
  Pagination,
  PrevButton,
  PreviewButton,
  SubmitButton,
} from "../atoms";
import { useSetAtom } from "jotai";
import {
  completeTriesAtom,
  showValidationPopupAtom,
  validationPopupPropertiesAtom,
} from "../../jotai-atoms";

const { itemsPerPage } = appConfig.paginationConfig;

interface NavMenuProps {
  survey: SurveyModel;
  visible?: boolean;
}

export const NavMenu: FC<NavMenuProps> = ({ survey, visible }) => {
  const { isAboveSm } = useBreakpoint("sm");
  const { isAboveMd } = useBreakpoint("md");
  const { state } = survey;
  const setShowValidationPopup = useSetAtom(showValidationPopupAtom);
  const setValidationPopupProperties = useSetAtom(
    validationPopupPropertiesAtom
  );
  const [currentPageNo, setCurrentPageNo] = useState(survey.currentPageNo);
  const [visiblePages, setVisiblePages] = useState(survey.visiblePages);
  const setCompleteTries = useSetAtom(completeTriesAtom);
  const { apiService } = useContext(ApiServiceContext);
  const withPagination =
    survey.getPropertyValue("navigationMenu") === "pagination" && isAboveMd;
  const isLastPage = currentPageNo === visiblePages.length - 1;
  const isFirstPage = currentPageNo > 0;
  const showPreviewButton =
    survey.getPropertyValue("showPreviewBeforeComplete") !== "noPreview" &&
    isLastPage &&
    state !== "preview";
  const showSubmitButton =
    (showPreviewButton && state === "preview") ||
    (!showPreviewButton && (withPagination || isLastPage));
  const showNextButton = !isLastPage && !withPagination;
  const showPrevButton = isFirstPage && !withPagination && !showSubmitButton;

  /**
   * handle the visibility of the page
   * update the current page number and the visible pages
   * @param sender
   * @param options
   */
  const pageVisibilityHandler = (sender: SurveyModel) => {
    setCurrentPageNo(sender.currentPageNo);
    setVisiblePages(sender.visiblePages);
  };

  useEffect(() => {
    survey.onPageVisibleChanged.add(pageVisibilityHandler);
    survey.onCurrentPageChanged.add(pageVisibilityHandler);
    return () => {
      survey.onPageVisibleChanged.remove(pageVisibilityHandler);
      survey.onCurrentPageChanged.remove(pageVisibilityHandler);
    };
  }, [survey]);

  const onNextClick = () => survey.nextPage();
  const onPrevClick = () => survey.prevPage();
  const onPreviewClick = () => survey.showPreview();

  const onPageChange = useCallback(
    ({ selected }: { selected: number }) => {
      survey.currentPageNo = selected;
      apiService.savePageNumber(selected);
      window.scrollTo(0, 0);
    },
    [apiService, survey]
  );

  const onSubmitClick = useCallback(() => {
    const isAbleToComplete = survey.tryComplete();
    setCompleteTries((prev) => prev + 1);

    if (
      !isAbleToComplete &&
      survey.hasErrors() &&
      survey.getPropertyValue("showValidationPopup", false)
    ) {
      setShowValidationPopup(true);
      const validationPopupProperties = survey.getPropertyValue(
        "validationPopupProperties"
      );
      if (validationPopupProperties) {
        setValidationPopupProperties(validationPopupProperties);
      }
    }
  }, [
    setCompleteTries,
    setShowValidationPopup,
    setValidationPopupProperties,
    survey,
  ]);

  if (!visible || state === "completed" || isDisplayMode(survey)) return null;

  return (
    <div className="flex items-center justify-between w-full bg-white">
      {
        withPagination ? (
          <Pagination
            pageRangeDisplayed={itemsPerPage}
            pageCount={survey.pageCount - 1} // all pages but the start page.
            forcePage={survey.currentPageNo}
            onPageChange={onPageChange}
          />
        ) : isAboveSm ? (
          <div className="flex-1" />
        ) : (
          <></>
        ) // Placeholder to maintain spacing when no pagination
      }
      <div
        id="nav-button"
        className="flex items-center justify-between md:justify-normal md:w-fit w-full gap-[15px]"
      >
        {showPrevButton ? (
          <PrevButton onClick={onPrevClick} />
        ) : (
          <div className="flex-1" />
        )}
        {showNextButton && <NextButton onClick={onNextClick} />}
        {showPreviewButton && <PreviewButton onClick={onPreviewClick} />}
        {showSubmitButton && <SubmitButton onClick={onSubmitClick} />}
      </div>
    </div>
  );
};
