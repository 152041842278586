import * as Popover from "@radix-ui/react-popover";
import { useCallback, useMemo, useState, useRef } from "react";
import { Command } from "cmdk";
import { ChevronDown, Plus, Search, X } from "../../icons";
import { useBreakpoint } from "../../../hooks/use-breakpoint";
import cn from "../../../utils/cn";
import { T, useTolgee } from "@tolgee/react";

type ComboboxProps = {
  items?: { value: string; title: string }[];
  placeholder?: string;
  value?: string;
  className?: string;
  ariaLabel?: string;
  ariaRequired?: boolean;
  ariaInvalid?: boolean;
  ariaErrormessage?: string;
  autoComplete?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  dense?: boolean;
  defaultValue?: string;
  onValueChange?: (value: string) => void;
  isOtherSelected?: boolean;
  commentPlaceHolder?: string;
  allowClear?: boolean;
  hasOther?: boolean;
  hasNone?: boolean;
  otherItem?: { title: string; value: any };
  noneItem?: { title: string; value: any };
  comment?: string;
  hasError?: boolean;
  cssClasses?: { [key: string]: any };
  allowAddNewItem?: boolean;
};

export const Combobox = ({
  items,
  placeholder,
  value,
  className,
  ariaInvalid,
  disabled,
  onValueChange,
  otherItem,
  noneItem,
  allowAddNewItem,
}: ComboboxProps) => {
  const [search, setSearch] = useState("");
  const [open, isOpen] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);
  const { isAboveMd } = useBreakpoint("md");
  const onSelect = useCallback(
    (value: string) => {
      onValueChange?.(value);
      isOpen(false);
    },
    [onValueChange]
  );
  const { t } = useTolgee();

  const onCleanSelectedItem = useCallback(() => {
    onValueChange?.("");
    isOpen(false);
  }, [onValueChange]);

  const selectedItem = useMemo(() => {
    return items?.filter((item) => item.value === value)[0];
  }, [items, value]);

  const filterItems = useCallback(
    (value: string) => {
      const item = items?.find((item) => item.value === value);
      if (!item) return 0;

      const otherItemValue = otherItem?.value || "other";
      const noneItemValue = noneItem?.value || "none";
      const isOtherValue = item.value === otherItemValue;
      const isNoneValue = item.value === noneItemValue;
      const isClearValue = item.value === "undefined";

      if (isOtherValue || isNoneValue || isClearValue) {
        return 1;
      }

      if (typeof item.title === "string") {
        const itemTitle = item.title.toLowerCase();
        //Fix to solve an issue with search through the Tolgee translations
        const renderedTitle =
          listRef.current
            ?.querySelector(`[data-value="${item.value}"]`)
            ?.textContent?.toLowerCase() || "";
        const searchTerm = search.toLowerCase().trim();

        return itemTitle.includes(searchTerm) ||
          renderedTitle.includes(searchTerm)
          ? 1
          : 0;
      }

      return 1;
    },
    [items, search, otherItem, noneItem]
  );

  const handleAddNewItem = useCallback(() => {
    onValueChange?.(search);
    isOpen(false);
    setSearch("");
  }, [onValueChange, search]);

  return (
    <Popover.Root open={open} onOpenChange={isOpen}>
      <Popover.Trigger
        className={cn(
          "shrink-0 group disabled:bg-gray-50 inline-flex items-center justify-between text-start shadow-xs py-2.5 px-[14px] rounded-lg border border-gray-300 bg-white focus:ring-4 ring-indigo-100 focus:border-indigo-500 outline-none",
          { "!text-gray-300": !value },
          open && "ring-4 border-indigo-500",
          ariaInvalid && "border-red-500 bg-red-50",
          className
        )}
        disabled={disabled}
      >
        <div className="flex items-center gap-2.5 justify-between w-full">
          <span
            className={`w-full text-xs bg-transparent outline-none appearance-none cursor-pointer focus:text-gray-800 line-clamp-1 md:text-sm font-regular ${
              open ? "text-gray-800" : ""
            } ${selectedItem?.title ? "text-gray-500" : "text-gray-300"}`}
            onClick={() => isOpen(true)}
          >
            {selectedItem?.title || placeholder}
          </span>
          <div className="flex items-center gap-2.5">
            <div
              hidden={disabled || !value}
              className="text-gray-500 rounded-[5px] hover:bg-gray-100 cursor-pointer"
              onClick={onCleanSelectedItem}
            >
              <X size={18} />
            </div>
            <ChevronDown
              color={disabled ? "var(--gray-300)" : "var(--gray-500)"}
              size={isAboveMd ? 20 : 18}
              className="group-data-[state=open]:rotate-180"
            />
          </div>
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side="top"
          className="z-50 overflow-hidden bg-white border border-gray-100 rounded-lg shadow-lg w-[var(--radix-popover-trigger-width)] max-h-[calc(var(--radix-popover-content-available-height))]"
        >
          <Command filter={filterItems} className="py-1 px-[14px] bg-white">
            <div className="flex justify-between items-center gap-2.5 py-2.5 w-full border-b border-b-gray-300 text-gray-900">
              <Command.Input
                value={search}
                onValueChange={setSearch}
                placeholder={t("pq-bom-t1-combobox-search-placeholder")}
                autoFocus
                className="w-full text-xs font-semibold text-gray-900 outline-none md:text-sm"
              />
              <Search size={18} />
            </div>
            <Command.List
              className="overflow-y-auto mx-[-14px] box-border max-h-[200px]"
              ref={listRef}
            >
              <Command.Empty className="w-full h-full text-xs font-medium text-gray-900 md:text-sm text-wrap">
                {allowAddNewItem ? (
                  <button
                    className="flex justify-between w-full px-[14px] py-2.5 gap-2 cursor-pointer apperance-none hover:bg-gray-50"
                    onClick={handleAddNewItem}
                  >
                    <Plus size={18} />
                    <T keyName="pq-bom-t1-combobox-add-new-item" />
                  </button>
                ) : (
                  <div className="px-[14px] py-2.5 w-full">
                    <T keyName="pq-bom-t1-combobox-no-results-found" />
                  </div>
                )}
              </Command.Empty>
              {items?.map((item, index) => (
                <Command.Item
                  key={item.value + "-" + index}
                  className="py-2.5 px-[14px] text-xs md:text-sm font-medium text-gray-900 text-wrap hover:bg-gray-50 min-h-[40px] cursor-pointer break-words"
                  value={item.value}
                  onSelect={onSelect}
                  data-item-value={item.value}
                >
                  {item.title}
                </Command.Item>
              ))}
            </Command.List>
          </Command>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
