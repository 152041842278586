import {
  FC,
  createElement,
  useCallback,
  useMemo,
} from "react";
import { QuestionTagboxModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { MultiSelectDownshift } from "../ui/multi-select-downshift";

interface TagboxQuestionProps {
  isDisplayMode: boolean;
  question: QuestionTagboxModel;
}

const TagboxQuestion: FC<TagboxQuestionProps> = ({
  isDisplayMode,
  question,
}) => {
  const {
    value,
    isReadOnly,
    name,
    allowClear,
    isRequired,
    placeholder,
    otherItem,
    noneItem,
    hasOther,
    hasNone,
    isOtherSelected,
    commentPlaceHolder,
  } = question;

  const isDisabled = useMemo(() => {
    return isReadOnly;
  }, [isReadOnly]);

  /**
   * @description Handle the value change of the dropdown
   */
  const onValueChange = useCallback(
    (newVals: string[]) => {
      question.value = newVals;
    },
    [question]
  );

  /**
   * @description Set the comment for the dropdown
   */
  const setComment = (comment: string) => {
    question.comment = comment;
  };

  /**
   * @description Get the items for the dropdown
   */
  const getItems = useMemo(() => {
    const items = question.dataChoices.map(({ title, value }) => ({
      title,
      value,
    }));
    return items;
  }, [question.dataChoices]);

  return (
    <div className="flex flex-col gap-2">
      <MultiSelectDownshift
        aria-required={question.ariaRequired}
        aria-label={question.ariaLabel}
        aria-invalid={question.ariaInvalid}
        aria-errormessage={question.ariaErrormessage}
        autoComplete={question.autocomplete}
        id={question.inputId}
        items={getItems}
        otherItem={otherItem}
        noneItem={noneItem}
        hasOther={hasOther}
        hasNone={hasNone}
        required={isRequired}
        disabled={isDisabled}
        allowClear={allowClear}
        values={value}
        name={name}
        dense
        isOtherSelected={isOtherSelected}
        commentPlaceHolder={commentPlaceHolder}
        onValueChange={onValueChange}
        placeholder={placeholder}
        defaultValue={question.getDefaultValue()}
        comment={question.comment}
        onCommentChange={setComment}
      />
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-tagbox-question",
  function (props: any) {
    return createElement(TagboxQuestion, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "tagbox",
  "tagbox-question",
  "sv-tagbox-question"
);