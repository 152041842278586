import { getDefaultStore } from "jotai";
import {
  ICustomQuestionTypeConfiguration,
  MatrixDropdownColumn,
  Question,
  QuestionCustomModel,
  QuestionMatrixDynamicModel,
  Serializer,
  SurveyModel,
} from "survey-core";
import {
  productsAtom,
  productsMaterialsAtom,
  suppliersAtom,
} from "../../../../../jotai-atoms";
import { focusAtom } from "jotai-optics";
import { IMaterial } from "../../../../../types";
import {
  getProductsQuestions,
  updateProductMaterialsCount,
} from "../../../../../utils/bom-utils";
import "./bill-of-materials-question.css";
const QUESTION_TYPE_NAME = "bill-of-materials";
const QUESTION_TITLE_IN_BUILDER = "Bill of Materials";
const DEFAULT_QUESTION_TITLE = "Bill of Materials";

interface IMaterialRow {
  styleNumber: string;
  position: string;
  compositionSummary: string;
  yarnSuppliersSummary: string;
  materialsInfoSummary: string;
  billOfMaterialsExtraInfo: Omit<IMaterial, "position">;
}

interface IBillOfMaterialsQuestion extends ICustomQuestionTypeConfiguration {}

const billOfMaterialsQuestion: IBillOfMaterialsQuestion = {
  name: QUESTION_TYPE_NAME,
  title: QUESTION_TITLE_IN_BUILDER,
  defaultQuestionTitle: DEFAULT_QUESTION_TITLE,
  questionJSON: {
    type: "matrixdynamic",
    name: "billOfMaterials",
    titleLocation: "hidden",
    minRowCount: 1,
    rowCount: 1,
    isRemoteRemoveAllowed: false,
    addRowText: "%-%tolgee:add-material-button%-%",
    columns: [
      {
        cellType: "html",
        name: "rowIndex",
        title: " ",
        width: "25px",
        minWidth: "25px",
        html: "<div class='grid h-10 text-xs leading-[18px] font-semibold text-indigo-500 place-items-center'>{rowIndex}</div>",
      },
      {
        name: "styleNumber", // Amit: DO NOT CHANGE THIS NAME
        cellType: "text",
        title: "%-%tolgee:style-number-title%-%",
        width: "150px",
        minWidth: "150px",
        visible: false,
      },
      {
        cellType: "dropdown",
        renderAs: "combobox-question",
        allowClear: true,
        name: "position", // Amit: DO NOT CHANGE THIS NAME
        title: "%-%tolgee:position-title%-%",
        isRequired: true,
        width: "20%",
        maxWidth: "150px",
        choicesByUrl: {
          url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_positioning",
          valueName: "value",
          titleName: "title",
        },
      },
      {
        cellType: "expression",
        name: "compositionSummary",
        title: "%-%tolgee:composition-title%-%",
        renderAs: "grey-expression",
        placeholder: "%-%tolgee:material-composition-no-materials-selected%-%",
        width: "25%",
        maxWidth: "260px",
        expression:
          "buildCompositionSummary({row.billOfMaterialsExtraInfo.composition}, {row.billOfMaterialsExtraInfo.materialType}, 'https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/pq_bop_t2_material_composition_percentage_fiber_composition', 'https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/pq_bop_t2_fabric_material_info_material_type')",
      },

      {
        cellType: "expression",
        name: "yarnSuppliersSummary",
        title: "%-%tolgee:material-traceability-information%-%",
        renderAs: "grey-expression",
        placeholder: "%-%tolgee:yarn-suppliers-no-suppliers-selected%-%",
        width: "25%",
        maxWidth: "260px",
        expression:
          "buildYarnSuppliersSummary({row.billOfMaterialsExtraInfo}, 'https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/pq_bop_t2_material_composition_percentage_fiber_composition')",
      },
      {
        cellType: "expression",
        name: "materialsInfoSummary",
        title: "%-%tolgee:materials-info-title%-%",
        renderAs: "grey-expression",
        placeholder: "%-%tolgee:materials-info-no-info%-%",
        width: "25%",
        maxWidth: "260px",
        expression:
          "buildMaterialsInfoSummary({row.billOfMaterialsExtraInfo}, 'https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/pq_bop_t2_fabric_material_info_material_width_unit', 'https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/pq_bop_t2_fabric_material_info_material_weight_unit', 'https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_material_usage_units', 'https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_waste_unit')",
      },
      {
        cellType: "dialog",
        name: "billOfMaterialsExtraInfo", // Amit: DO NOT CHANGE THIS NAME
        dialogTitleExpression:
          "composeBomT1DialogQuestionTitle({row.styleNumber}, {row.position}, 'https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_positioning')",
        contentType: "bom-t1",
        title: " ",
        width: "5%",
        enableIf: "{row.position} notempty",
        validators: [
          {
            type: "expression",
            text: "%-%tolgee:popup-validation-error-message%-%",
            expression:
              "validateDialogBomContent({row.billOfMaterialsExtraInfo})",
          },
        ],
      },
    ],
  },
  onInit: () => {
    Serializer.addProperty(QUESTION_TYPE_NAME, {
      name: "tsPrefix",
      default: "",
    });
  },
  onLoaded: (question: QuestionCustomModel) => {
    const bomQuestion = question.contentQuestion as QuestionMatrixDynamicModel;
    const productKey = question.getPropertyValue("forProductKey");
    const value = bomQuestion.value as IMaterialRow[];
    const defaultStore = getDefaultStore();
    const tsPrefix = question.getPropertyValue("tsPrefix");
    const survey = question.survey as SurveyModel;

    if (tsPrefix) {
      bomQuestion.addRowText = bomQuestion.addRowText.replace(
        /(%-%tolgee:)(.+?)(%-%)/,
        `$1${tsPrefix}-$2$3`
      );
      // Update matrix column names based on tsPrefix
      const columns = bomQuestion.columns as MatrixDropdownColumn[];
      columns.forEach((column) => {
        // Skip dialog cells entirely
        if (column.cellType === "dialog") {
          const validators = column.validators;
          validators.forEach((validator) => {
            if (validator.text.includes("tolgee:")) {
              validator.text = validator.text.replace(
                /(%-%tolgee:)(.+?)(%-%)/,
                `$1${tsPrefix}-$2$3`
              );
            }
          });
          return;
        }

        if (column.name === "rowIndex") {
          return;
        }

        const title = column.title;
        const placeholder = (column as any).placeholder;
        let prefixTitle = "";
        if (title && title.includes("tolgee:")) {
          prefixTitle = title.replace(
            /(%-%tolgee:)(.+?)(%-%)/,
            `$1${tsPrefix}-$2$3`
          );
        } else {
          prefixTitle = `${tsPrefix}-${title}`;
        }

        let prefixPlaceholder = "";
        if (placeholder) {
          prefixPlaceholder = placeholder.replace(
            /(%-%tolgee:)(.+?)(%-%)/,
            `$1${tsPrefix}-$2$3`
          );
        } else {
          prefixPlaceholder = `${tsPrefix}-${placeholder}`;
        }

        column.title = prefixTitle;
        (column as any).placeholder = prefixPlaceholder;
      });
    }

    if (
      productKey &&
      !defaultStore
        .get(productsMaterialsAtom)
        .find((productMaterials) => productMaterials.productKey === productKey)
    ) {
      if (value) {
        defaultStore.set(productsMaterialsAtom, [
          ...defaultStore.get(productsMaterialsAtom),
          {
            productKey,
            materials: value.map((val) => ({
              position: val.position,
              yarnSuppliers: [],
              fabricSupplierName: "",
            })),
          },
        ]);
      } else {
        defaultStore.set(productsMaterialsAtom, [
          ...defaultStore.get(productsMaterialsAtom),
          {
            productKey,
            materials: [],
          },
        ]);
      }
    }

    /**
     * Set the default row value.
     * Get the style number from the product and set it as the default row value.
     */
    if (productKey) {
      const productAtom = focusAtom(productsAtom, (optic) =>
        optic.find((product) => product.key === productKey)
      );
      const product = defaultStore.get(productAtom);
      if (product) {
        bomQuestion.defaultRowValue = { styleNumber: product.styleNumber };
      }
    }

    // Set up CSS classes handling
    if (survey) {
      /**
       * This following two events are meant to solve a very strange problem in which for some reason
       * the add and remove buttons are hidden after trying to submit a questionnaire that has validation errors.
       * This bug happens randomly and there is no clear pattern to it.
       * The only solution found so far is to hide the buttons when the preview is shown and show them again
       * when the current page is changed to the page that contains the question.
       */
      survey.onShowingPreview.add(() => {
        bomQuestion.allowAddRows = false;
        bomQuestion.allowRemoveRows = false;
      });

      survey.onCurrentPageChanged.add((sender, options) => {
        if (options.newCurrentPage === question.page && !sender.readOnly) {
          bomQuestion.allowAddRows = true;
          bomQuestion.allowRemoveRows = true;
        }
      });

      /**
       * End of the fix for the bug mentioned above.
       */

      survey.onUpdateQuestionCssClasses.add(
        (
          sender: unknown,
          { question, cssClasses }: { question: Question; cssClasses: any }
        ) => {
          const contentElement = cssClasses.content;
          const isBillOfMaterials = contentElement.includes(
            "bill-of-materials-matrix"
          );
          const isNameBillOfMaterials = question.name === "billOfMaterials";
          if (isNameBillOfMaterials && !isBillOfMaterials) {
            cssClasses.content += " bill-of-materials-matrix";
          }
        }
      );
    }
  },
  onValueChanged: (question, _, newValue: IMaterialRow[]) => {
    const defaultStore = getDefaultStore();
    const productKey = question.getPropertyValue("forProductKey");
    const suppliersSet = new Set<string>();
    const materialsCount = newValue.length;

    const productsQuestions = getProductsQuestions(
      question.survey as SurveyModel
    );

    updateProductMaterialsCount(productsQuestions, productKey, materialsCount);

    newValue.forEach((val) => {
      if (val.billOfMaterialsExtraInfo?.fabricSupplierName) {
        suppliersSet.add(val.billOfMaterialsExtraInfo.fabricSupplierName);
      }
      val.billOfMaterialsExtraInfo?.yarnSuppliers?.forEach((supplier) => {
        if (supplier.name) suppliersSet.add(supplier.name);
      });
    });

    Array.from(suppliersSet).forEach((supplierName) => {
      defaultStore.set(suppliersAtom, (suppliers) => {
        const isIncluded = suppliers?.find(
          (supplier) => supplier.name === supplierName
        );
        return isIncluded ? suppliers : [...suppliers, { name: supplierName }];
      });
    });

    if (productKey) {
      const newMaterails = newValue.map((val) =>
        buildMaterialFromMaterialRow(val)
      );
      const productMaterialsAtom = focusAtom(productsMaterialsAtom, (optic) =>
        optic.find(
          (productMaterials) => productMaterials.productKey === productKey
        )
      );
      defaultStore.set(productMaterialsAtom, {
        productKey,
        materials: newMaterails,
      });
    }
  },
};

export default billOfMaterialsQuestion;

function buildMaterialFromMaterialRow(materialRow: IMaterialRow): IMaterial {
  return {
    position: materialRow.position,
    yarnSuppliers: materialRow.billOfMaterialsExtraInfo?.yarnSuppliers ?? [],
    fabricSupplierName:
      materialRow.billOfMaterialsExtraInfo?.fabricSupplierName ?? "",
    composition: materialRow.billOfMaterialsExtraInfo?.composition ?? [],
    fabricWidth: materialRow.billOfMaterialsExtraInfo?.fabricWidth ?? 0,
    fabricUnit: materialRow.billOfMaterialsExtraInfo?.fabricUnit ?? "",
    fabricWeight: materialRow.billOfMaterialsExtraInfo?.fabricWeight ?? 0,
    fabricWeightUnit:
      materialRow.billOfMaterialsExtraInfo?.fabricWeightUnit ?? "",
    fabricUsage: materialRow.billOfMaterialsExtraInfo?.fabricUsage ?? 0,
    fabricUsageUnit:
      materialRow.billOfMaterialsExtraInfo?.fabricUsageUnit ?? "",
    fabricWaste: materialRow.billOfMaterialsExtraInfo?.fabricWaste ?? 0,
  };
}
