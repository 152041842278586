import { ChevronDown, Check } from "../../icons";
import classNames from "classnames";
import {
  ChangeEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import { useBreakpoint } from "../../../hooks";
import { debounce } from "lodash";
import { useSelect } from "downshift";

type SearchDropdownProps = {
  items?: { value: string; title: string | ReactElement }[];
  placeholder?: string;
  value?: string;
  className?: string;
  ariaLabel?: string;
  ariaRequired?: boolean;
  ariaInvalid?: boolean;
  ariaErrormessage?: string;
  autoComplete?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  dense?: boolean;
  defaultValue?: string;
  onValueChange?: (value: string | undefined) => void;
  isOtherSelected?: boolean;
  commentPlaceHolder?: string;
  allowClear?: boolean;
  hasOther?: boolean;
  hasNone?: boolean;
  otherItem?: { title: string; value: any };
  noneItem?: { title: string; value: any };
  comment?: string;
  onCommentChange?: (comment: string) => void;
};

export function SearchDropdownDownshift({
  items,
  placeholder,
  value,
  className,
  ariaLabel,
  required,
  ariaInvalid,
  disabled,
  name,
  onValueChange,
  defaultValue,
  allowClear,
  hasOther,
  hasNone,
  otherItem,
  noneItem,
  isOtherSelected,
  commentPlaceHolder,
  comment,
  onCommentChange,
}: SearchDropdownProps) {
  const { isAboveMd } = useBreakpoint("md");
  const [searchQuery, setSearchQuery] = useState("");
  const [otherValue, setOtherValue] = useState(comment || "");

  const itemsWithKeys = useMemo(() => {
    const otherOption = {
      value: otherItem?.value || "other",
      title: otherItem?.title || "Other",
      key: "other-option",
    };
    const noneOption = {
      value: noneItem?.value || "none",
      title: noneItem?.title || "None",
      key: "none-option",
    };
    const clearOption = { value: "undefined", title: "", key: "clear-option" };
    const regularItems = items?.map((item, index) => ({
      ...item,
      key: String(item.value) + String(index),
    }));

    const finalItems = [...(regularItems || [])];
    if (hasOther) finalItems.push(otherOption);
    if (hasNone) finalItems.push(noneOption);
    if (allowClear) finalItems.unshift(clearOption);
    return finalItems;
  }, [items, otherItem, noneItem, hasOther, hasNone, allowClear]);

  const filteredItems = useMemo(
    () =>
      itemsWithKeys?.filter((item) => {
        const isOtherValue = item.key === "other-option";
        const isNoneValue = item.key === "none-option";
        const isClearValue = item.key === "clear-option";

        if (isOtherValue || isNoneValue || isClearValue) {
          return true;
        }

        if (typeof item.title === "string") {
          return item.title.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return true;
      }),
    [itemsWithKeys, searchQuery]
  );

  const searchInputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [showAbove, setShowAbove] = useState(false);
  const [isAutoFocus, setIsAutoFocus] = useState(false);
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
  } = useSelect({
    items: filteredItems || [],
    itemToString: (item) => (item ? String(item.title) : ""),
    initialSelectedItem: useMemo(() => {
      const initialValue = defaultValue || value;
      return filteredItems?.find((item) => item.value === initialValue);
    }, [filteredItems, defaultValue, value]),
    onSelectedItemChange: useCallback(
      ({ selectedItem }: { selectedItem: any }) => {
        const selectedValue = selectedItem?.value;
        setIsAutoFocus(true);
        setTimeout(() => {
          //Fix to prevent dropdown rendering freezing. onValueChange is very resource intensive.
          onValueChange?.(selectedValue);
        }, 0);
      },
      [setIsAutoFocus, onValueChange]
    ),
  });

  /**
   * @description Handle the change event of the search input
   * @param event - The change event
   */
  const handleSearchQueryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const searchValue = event.target.value;
      setSearchQuery(searchValue);
      if (isOpen === false) {
        openMenu();
      }
    },
    [isOpen, openMenu]
  );

  /**
   * @description Handle the change event of the other input
   * @param event - The change event
   */
  const handleOtherValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const otherValue = event.target.value;
      setOtherValue(otherValue);
      onCommentChange?.(otherValue);
    },
    [onCommentChange]
  );

  /**
   * @description Debounce the other value change event
   * @param newOtherValue - The new other value
   */
  const debouncedHandleOtherValueChange = useMemo(
    () =>
      debounce((newOtherValue: string) => {
        if (newOtherValue !== String(otherValue)) {
          onCommentChange?.(newOtherValue);
        }
      }, 500),
    [onCommentChange, otherValue]
  );

  useEffect(() => {
    debouncedHandleOtherValueChange(otherValue);
  }, [otherValue, debouncedHandleOtherValueChange]);

  /**
   * @description Handle the focus event of the search input
   * @param event - The focus event
   */
  const handleSearchFocus = useCallback(
    (
      event:
        | React.FocusEvent<HTMLInputElement>
        | React.MouseEvent<HTMLInputElement>
    ) => {
      event.preventDefault();
      event.stopPropagation();

      if (!isOpen && !isAutoFocus) {
        openMenu();
      }
    },
    [isAutoFocus, isOpen, openMenu]
  );

  useEffect(() => {
    if (isAutoFocus) {
      searchInputRef.current?.focus();
      setIsAutoFocus(false);
      return;
    }
  }, [isAutoFocus]);

  useLayoutEffect(() => {
    if (isOpen && searchInputRef.current && menuRef.current) {
      const inputRect = searchInputRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - inputRect.bottom;
      const spaceAbove = inputRect.top;

      setShowAbove(spaceBelow < spaceAbove);
    }
  }, [isOpen]);

  /**
   * @description Prevent the default event of the search input
   * @param event - The event
   */
  const searchFieldPreventDefault = (
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    event.stopPropagation();
  };

  // Add scroll handler
  useEffect(() => {
    /**
     * @description Handle the scroll event of the dropdown
     * @param event - The scroll event
     */
    const handleScroll = (event: Event) => {
      if (isOpen === false) {
        return;
      }

      const menuElement = menuRef.current;
      const target = event.target as Node;

      if (
        menuElement &&
        (menuElement === target || menuElement.contains(target))
      ) {
        return;
      }

      closeMenu();
    };

    if (isOpen === true) {
      window.addEventListener("scroll", handleScroll, true);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [isOpen, closeMenu]);

  return (
    <div className="relative flex flex-col gap-2">
      <div className="w-full">
        <button
          type="button"
          {...getToggleButtonProps()}
          disabled={disabled}
          className={classNames(
            "w-full shrink-0 group " +
              "disabled:bg-gray-50 inline-flex items-center " +
              "justify-between text-start font-regular text-xs " +
              "md:text-sm leading-[16px] md:leading-5 text-gray-500 " +
              "shadow-xs py-2.5 pr-[14px] pl-[4px] rounded-lg border " +
              "focus:ring-4 focus:ring-indigo-100 " +
              "focus:border-indigo-500 outline-none focus:text-gray-800",
            { "!text-gray-300": !selectedItem },
            { "ring-4 ring-indigo-100 border-indigo-500": isOpen },
            ariaInvalid
              ? "border-red-500 bg-red-500 bg-opacity-10"
              : "border-gray-300 bg-white",
            className
          )}
        >
          {selectedItem && selectedItem.key !== "clear-option" && (
            <span className="pl-[10px] overflow-hidden text-ellipsis whitespace-nowrap">
              {selectedItem.title}
            </span>
          )}
          <input
            ref={searchInputRef}
            type="text"
            placeholder={placeholder}
            value={searchQuery}
            disabled={disabled}
            onChange={handleSearchQueryChange}
            onFocus={handleSearchFocus}
            onClick={handleSearchFocus}
            onMouseDown={searchFieldPreventDefault}
            className="w-full bg-transparent border-none outline-none pl-[10px] focus:ring-0"
          />
          <ChevronDown
            color={disabled ? "var(--gray-300)" : "var(--gray-500)"}
            size={isAboveMd ? 20 : 18}
            className={classNames("transition-transform", {
              "rotate-180": isOpen,
            })}
          />
        </button>

        <div className="relative w-full">
          <div
            ref={menuRef}
            className={classNames(
              "absolute z-50 bg-white border border-gray-100 rounded-lg shadow-lg w-full max-h-[300px] overflow-auto",
              { hidden: !isOpen }
            )}
            style={{
              width: "100%",
              ...(showAbove
                ? { bottom: "calc(100% + 50px)" }
                : { top: "calc(100% + 10px)" }),
              left: 0,
            }}
          >
            <ul {...getMenuProps()}>
              {isOpen &&
                filteredItems?.map((item, index) => (
                  <li
                    {...getItemProps({ item, index })}
                    key={item.key}
                    className={classNames(
                      "flex justify-between gap-2 py-2 px-4 " +
                        "select-none min-h-10 text-sm font-semibold " +
                        "text-gray-700 cursor-pointer overflow-hidden",
                      {
                        "bg-gray-50": highlightedIndex === index,
                      }
                    )}
                  >
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                      {item.title}
                    </span>
                    {selectedItem?.value === item.value && (
                      <span className="text-primary-600">
                        <Check size={20} />
                      </span>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>

      {isOtherSelected && (
        <input
          type="text"
          value={otherValue}
          onChange={handleOtherValueChange}
          placeholder={commentPlaceHolder || "Please specify..."}
          className={classNames(
            "w-full px-3 py-2 text-sm border rounded-md",
            "focus:ring-4 focus:ring-indigo-100 focus:border-indigo-500 outline-none",
            ariaInvalid
              ? "border-red-500 bg-red-500 bg-opacity-10"
              : "border-gray-300 bg-white"
          )}
        />
      )}
    </div>
  );
}
