import { IconProps } from "react-feather";

export function Traceability({
  size = 24,
  color = "black",
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      {...props}
    >
      <path
        d="M12 5H18.5C20.433 5 22 6.567 22 8.5C22 10.433 20.433 12 18.5 12H16M12 5C12 6.65685 10.6569 8 9 8C7.34315 8 6 6.65685 6 5C6 3.34315 7.34315 2 9 2C10.6569 2 12 3.34315 12 5ZM16 12C16 13.6569 14.6569 15 13 15C11.3431 15 10 13.6569 10 12M16 12C16 10.3431 14.6569 9 13 9C11.3431 9 10 10.3431 10 12M10 12H5.5C3.567 12 2 13.567 2 15.5C2 17.433 3.067 19 5 19H13M19 19C19 20.6569 17.6569 22 16 22C14.3431 22 13 20.6569 13 19M19 19C19 17.3431 17.6569 16 16 16C14.3431 16 13 17.3431 13 19M19 19H21M22.5 19H23.5M4 5.5H6M1.5 5.5H2.5"
        stroke={color}
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
