import { getDefaultStore } from "jotai";
import { IconNames } from "./types/icon-type";
import { PanelModel, Serializer, SurveyModel } from "survey-core";
import { selectedSuppliersAtom } from "./jotai-atoms";
import {
  addNewQuestionsForSuppliers,
  getNonTemplateQuestions,
  getTemplateQuestion,
  updateQuestions,
} from "./utils/bom-utils";

// survey props
Serializer.addProperty("survey", {
  name: "navigationMenu",
  category: "general",
  choices: ["none", "sidebar", "pagination"],
  default: "sidebar",
});

Serializer.addProperty("survey", {
  name: "sidebarItems",
  category: "general",
});

Serializer.addProperty("survey", {
  name: "totalProducts",
  category: "general",
  type: "number",
  default: 0,
});

Serializer.addProperty("survey", {
  name: "questionnaireId",
  category: "general",
  type: "string",
  default: "",
});

Serializer.addProperty("survey", {
  name: "facilityShortName",
  category: "general",
  type: "string",
});

Serializer.addProperty("survey", {
  name: "brandName",
  category: "general",
  type: "string",
});

Serializer.addProperty("survey", {
  name: "showValidationPopup",
  category: "general",
  type: "boolean",
  default: false,
});

Serializer.addProperty("survey", {
  name: "validationPopupProperties",
  category: "general",
  default: {},
});

//page props
Serializer.addProperty("page", {
  name: "withHelper",
  type: "boolean",
  default: false,
  category: "general",
});

Serializer.addProperty("page", {
  name: "infoToastText",
  type: "string",
  default: "",
  dependsOn: ["withHelper"],
  enableIf: (obj) => obj.withHelper === true,
});

//question props
Serializer.addProperty("question", {
  name: "icon",
  displayName: "Question Icon",
  choices: ["", ...IconNames],
  category: "general",
});

Serializer.addProperty("question", {
  name: "iconColor",
  displayName: "Question Icon Color",
  default: "gray-500",
  category: "general", //The best would be to find a way to create an array from all the different tailwind color classes and put it in "choices".
});

Serializer.addProperty("page", {
  name: "subtype",
  type: "string",
  default: "",
});

Serializer.addProperty("question", {
  name: "subtype",
  type: "string",
  default: "",
});

Serializer.addProperty("panel", {
  name: "isSupplierInfoPanel",
  type: "boolean",
  default: false,
  onSetValue(obj: PanelModel, isSupplierInfoPanel: boolean) {
    const survey = obj.getSurvey() as SurveyModel;
    if (isSupplierInfoPanel) {
      const defaultStore = getDefaultStore();
      defaultStore.sub(selectedSuppliersAtom, () => {
        const panel = survey.getPanelByName(obj.name);
        if (panel) {
          const selectedSuppliers = defaultStore.get(selectedSuppliersAtom);
          const templateQuestion = getTemplateQuestion(panel);
          const questions = getNonTemplateQuestions(panel);
          updateQuestions(questions, selectedSuppliers, panel);
          if (templateQuestion) {
            addNewQuestionsForSuppliers(
              selectedSuppliers,
              panel,
              templateQuestion,
              survey.data
            );
          }

          const parent = panel.parent;

          /**
           * If the parent is a page, we need to check if there are more than 1 question in the page.
           * If there are, we need to make sure that the page is always visible.
           * If there is only one question, we need to make sure that the page is visible only if there are suppliers selected.
           */
          if (parent.isPage) {
            const numOfQuestionsInPage = parent.questions.filter(
              (element) =>
                element.getType() === "contractor-form" && element.visible
            ).length;

            parent.visible =
              numOfQuestionsInPage >= 1 ? true : selectedSuppliers.length > 0;
          }
        }
      });
    }
  },
});

Serializer.addProperty("question", {
  name: "contentType",
  category: "general",
  choices: ["bom"],
});

Serializer.addProperty("question", {
  name: "forProductKey",
  category: "general",
  type: "string",
  default: "",
});

Serializer.addProperty("question", {
  name: "forProductKeyExpression",
  type: "expression",
  onExecuteExpression: (obj, res) => {
    obj.forProductKey = res;
  },
});

Serializer.addProperty("panel", {
  name: "forProductKey",
  category: "general",
  type: "string",
  default: "",
});

Serializer.addProperty("radiogroup", {
  name: "resetValueExpression",
  type: "expression",
  category: "general",
  onExecuteExpression: (obj, res) => {
    if (res === true) {
      obj.value = "";
    }
  },
});

Serializer.addProperty("expression", {
  name: "placeholder",
  category: "general",
  type: "string",
  default: "",
});

/**
 * This flag will disable calling the remote API when removing a row from the matrixdynamic.
 * We want to use it when wrapping a matrixdynamic questions in a Custom or a composite question.
 */
Serializer.addProperty("matrixdynamic", {
  name: "isRemoteRemoveAllowed",
  category: "general",
  type: "boolean",
  default: true,
});

Serializer.addProperty("expression", {
  name: "openExplainerDialogOnFirst",
  type: "boolean",
  default: false,
  category: "general",
  dependsOn: ["renderAs"],
  enableIf: (obj) => obj.renderAs === "page-title-expression",
});
