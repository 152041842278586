import { IMaterial } from "../types";

export function validateDialogBomContent(
  params?: Array<Omit<IMaterial, "position"> | undefined>
) {
  if (!params || !Array.isArray(params)) {
    return false;
  }

  // Since we're validating a single material's content, we'll look at the first item
  const material = params[0];
  if (!material) {
    return false;
  }

  // Validate composition array and its total percentage
  const hasValidComposition =
    Array.isArray(material.composition) &&
    material.composition.length > 0 &&
    material.composition.every(
      (item) =>
        item.percentage !== undefined &&
        item.percentage > 0 &&
        item.fibre !== undefined &&
        item.fibre !== ""
    );

  // Calculate total percentage
  const totalPercentage =
    material.composition?.reduce(
      (sum, item) => sum + (item.percentage || 0),
      0
    ) || 0;

  // Validate required units
  const hasRequiredUnits =
    material.fabricUsageUnit !== undefined &&
    material.fabricUsageUnit !== "" &&
    material.fabricWasteUnit !== undefined &&
    material.fabricWasteUnit !== "";

  const hasMaterialType =
    material.materialType !== undefined && material.materialType !== "";

  const hasFabricUsage =
    material.fabricUsage !== undefined && material.fabricUsage !== 0;

  const hasFabricWaste =
    material.fabricWaste !== undefined && material.fabricWaste !== 0;

  const hasFabricSupplier =
    material.fabricSupplierName !== undefined &&
    material.fabricSupplierName !== "";

  // Validate yarn suppliers
  let hasValidYarnSuppliers = true;

  if (
    Array.isArray(material.yarnSuppliers) &&
    material.yarnSuppliers.length > 0
  ) {
    hasValidYarnSuppliers = material.yarnSuppliers
      .filter((supplier) => supplier.name !== undefined && supplier.name !== "")
      .every(
        (supplier) =>
          supplier.composition !== undefined && supplier.composition !== ""
      );
  }

  const isDialogValid =
    hasValidComposition &&
    totalPercentage === 100 &&
    hasRequiredUnits &&
    hasValidYarnSuppliers &&
    hasMaterialType &&
    hasFabricUsage &&
    hasFabricWaste &&
    hasFabricSupplier;

  return isDialogValid;
}
