import { ReactQuestionFactory } from "survey-react-ui";
import { QuestionTextModel, RendererFactory } from "survey-core";
import { createElement, FC, useCallback, useState, useEffect, useRef, useMemo } from "react";
import { debounce } from "lodash";
import IMask from "imask";

interface TextPercentageProps {
  question: QuestionTextModel;
}

const TextPercentage: FC<TextPercentageProps> = ({ question }) => {
  const [localValue, setLocalValue] = useState(question.value || "");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      const mask = IMask(inputRef.current, {
        mask: Number,
        scale: 2, // Allow 1 decimal place
        min: 0,
        max: 100,
        radix: '.', // Decimal point character
        thousandsSeparator: '',
        normalizeZeros: true,
        padFractionalZeros: false,
      });

      return () => {
        mask.destroy();
      };
    }
  }, []);

  /**
   * Saves the result to the question and local state
   * @param value - The value to save
   */
  const saveResult = useCallback((value: string) => {
    question.value = value;
    setLocalValue(value);
  }, [question]);


  /**
   * Debounces the saveResult function
   */
  const debouncedSaveResult = useMemo(
    () => debounce(saveResult, 250),
    [saveResult]
  );

  /**
   * Handles the change event for the input
   * @param event - The change event
   */
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLocalValue(value);
    debouncedSaveResult(value);
  }, [debouncedSaveResult]);

  
  /**
   * Handles the blur event for the input
   * @param event - The blur event
   */
  const handleBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    saveResult(value);
  }, [saveResult]);

  return (
    <div className="flex items-center flex-row relative">
      <span className="flex items-center justify-center text-xs bg-[#D9D9D9] rounded-l-lg
       absolute left-[1px] top-[1px] h-[calc(100%-2px)] w-[24px] text-[14px] text-[#5E7882]">%</span>
      <input
        ref={inputRef}
        type="text"
        value={localValue}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`sd-text appearance-none static border-box bg-white 
          disabled:bg-gray-50 disabled:text-gray-500 w-full py-2.5 pr-[14px] 
          shadow-xs rounded-lg border border-gray-300 text-xs md:text-sm leading-[16px] 
          md:leading-5 text-start font-regular text-gray-500 focus:border-indigo-500 
          focus:text-gray-800 focus:ring-4 focus:ring-indigo-100 
          outline-none placeholder-gray-300 pl-[34px]`}
        placeholder="0"
      />
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-text-percentage",
  function (props: any) {
    return createElement(TextPercentage, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "text",
  "text-percentage",
  "sv-text-percentage"
);