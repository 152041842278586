/**
 * TODO: produces a a console error:
 * Invalid expression: [object Object],[object Object],[object Object],[object Object]
 *
 * Need to replace with pq-product-details-summary
 */

import classNames from "classnames";
import { FC, createElement } from "react";
import { QuestionExpressionModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { ExplainerExpression } from "../../types";

interface SummaryItem {
  title: string;
  value: string;
}

interface PQProductDetailsSummaryExpressionProps {
  question: QuestionExpressionModel;
}

const PQProductDetailsSummaryExpression: FC<
  PQProductDetailsSummaryExpressionProps
> = ({ question }) => {
  const summaryItems = question.expression as unknown as SummaryItem[];
  /**
   * Parses the summary items string back into an object
   * @returns SummaryItem[]
   */
  const getSummaryItems = (items: string | SummaryItem[]) => {
    try {
      if (typeof items === "string") {
        return JSON.parse(items);
      }
      return items as ExplainerExpression;
    } catch (event: any) {
      console.error("Failed to parse explainer expression:", event);
      return [];
    }
  };
  const summaryItemsParsed: SummaryItem[] = getSummaryItems(summaryItems);

  if (
    summaryItemsParsed === null ||
    summaryItemsParsed === undefined ||
    summaryItemsParsed.length === 0
  ) {
    return <></>;
  }

  return (
    <div className="flex flex-col p-2.5 md:p-5 divide-indigo-100 whitespace-normal rounded-lg shadow-sm md:flex-row md:divide-x bg-indigo-25 gap-1.5">
      {summaryItemsParsed.map((item, index) => (
        <div
          key={item.value}
          className={classNames(
            "md:px-5 md:block flex items-center justify-between gap-5",
            {
              "md:pl-0 md:pr-5": index === 0,
              "md:pl-5 md:pr-0": index === summaryItems.length - 1,
            }
          )}
        >
          <div className="text-xxs md:text-xs leading-[14px] md:leading-[18px] font-semibold text-indigo-600">
            {item.title}
          </div>
          <div className="md:mt-2.5 text-xs md:text-sm leading-[18px] md:leading-6 font-regular text-indigo-800">
            {item.value}
          </div>
        </div>
      ))}
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-pq-product-details-summary-expression",
  function (props: any) {
    return createElement(PQProductDetailsSummaryExpression, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "pq-product-details-summary-expression",
  "sv-pq-product-details-summary-expression"
);
