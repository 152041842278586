/**
 * QuestionMatrixDynamicModel doesn't provide renderAs option, so we need to extend it
 * This file implements a styled version of the Matrix Dynamic question type with custom rendering
 */

import * as React from "react";
import { ReactQuestionFactory, SurveyQuestionMatrixDropdownBase } from "survey-react-ui";
import { QuestionMatrixDynamicModel, Serializer, MatrixDropdownRowModelBase, Action, IAction, QuestionMatrixDropdownRenderedTable} from "survey-core";
import "./index.css";
import * as Icon from "../../icons";

const MATRIX_DYNAMIC_TYPE = "matrixdynamic-styled";

/**
 * Custom implementation of the Matrix Dynamic rendered table that provides styled buttons
 * and custom row actions for drag & drop, remove, and detail panel functionality
 */
class QuestionMatrixDynamicRenderedTableStyled extends QuestionMatrixDropdownRenderedTable {
  protected get question(): QuestionMatrixDynamicModel {
    return this.matrix as QuestionMatrixDynamicModel;
  }

  /**
   * Sets up the default actions available for each row in the matrix
   * @param row - The matrix row model to set actions for
   * @param actions - Array of actions to be modified
   */
  protected setDefaultRowActions(
    row: MatrixDropdownRowModelBase,
    actions: Array<IAction>
  ) {
    const matrix = this.matrix as QuestionMatrixDynamicModel;
    if (this.hasRemoveRows && matrix.canRemoveRow(row)) {
      if (!matrix.showRemoveButtonAsIcon) {
        actions.push(
          new Action({
            id: "remove-row",
            location: "end",
            enabled: !matrix.isInputReadOnly,
            component: "sv-matrixdynamic-styled-remove-btn",
            data: { row: row, question: this.matrix },
          })
        );
      } else {
        actions.push(
          new Action({
            id: "remove-row",
            iconName: "icon-delete",
            iconSize: "auto",
            component: "sv-action-bar-item",
            location: "end",
            showTitle: false,
            title: matrix.removeRowText,
            enabled: !matrix.isInputReadOnly,
            data: { row: row, question: matrix },
            action: () => {
              matrix.removeRowUI(row);
            },
          })
        );
      }
    }

    if (row.hasPanel) {
      if (matrix.isMobile) {
        actions.unshift(
          new Action({
            id: "show-detail-mobile",
            title: matrix.getLocalizationString(row.isDetailPanelShowing ? "hideDetails" : "showDetails"),
            showTitle: true,
            location: "end",
            action: () => {
              row.showHideDetailPanelClick();
            },
          })
        );
      } else {
        actions.push(
          new Action({
            id: "show-detail",
            title: matrix.getLocalizationString("editText"),
            showTitle: false,
            location: "start",
            component: "sv-matrix-detail-button",
            data: { row: row, question: matrix },
          })
        );
      }
    }
  }
}

/**
 * Extended Matrix Dynamic question model that adds styling capabilities
 * and custom properties like hasRemoveRows
 */
export class QuestionMatrixDynamicStyledModel extends QuestionMatrixDynamicModel {
  constructor(name: string) {
    super(name);
    this.hasRemoveRows = true;
    this.allowRemoveRows = true;
  }

  /**
   * Controls whether remove row buttons should be displayed
   */
  public get hasRemoveRows(): boolean {
    return this.getPropertyValue("hasRemoveRows", false);
  }
  
  public set hasRemoveRows(val: boolean) {
    this.setPropertyValue("hasRemoveRows", val);
  }

  /**
   * Returns the question type identifier
   */
  getType() { 
    return MATRIX_DYNAMIC_TYPE; 
  }

  /**
   * Creates a custom rendered table instance for this question
   */
  protected createRenderedTable(): QuestionMatrixDropdownRenderedTable {
    return new QuestionMatrixDynamicRenderedTableStyled(this);
  }
}

/**
 * React component that renders the styled Matrix Dynamic question
 * Provides custom rendering for add/remove row buttons and empty state
 */
export class SurveyQuestionMatrixDynamicStyled extends SurveyQuestionMatrixDropdownBase {
  get question(): QuestionMatrixDynamicStyledModel {
    return this.questionBase as QuestionMatrixDynamicStyledModel;
  }

  /**
   * Renders the main question element including table, buttons and empty state
   */
  protected renderElement(): JSX.Element {
    const cssClasses = this.question.cssClasses;
    const showTable = this.question.renderedTable.showTable;
    
    return (
      <div className="matrix-dynamic-styled text-sm">
        {this.renderAddRowButtonOnTop(cssClasses)}
        {showTable ? this.renderTableDiv() : this.renderNoRowsContent(cssClasses)}
        {this.renderAddRowButtonOnBottom(cssClasses)}
      </div>
    );
  }

  /**
   * Renders the add row button at the top of the matrix if enabled
   * @param cssClasses - CSS classes to apply to the button
   */
  protected renderAddRowButtonOnTop(cssClasses: any): JSX.Element | null {
    if (!this.question.renderedTable.showAddRowOnTop) return null;
    return this.renderAddRowButton(cssClasses);
  }

  /**
   * Renders the add row button at the bottom of the matrix if enabled
   * @param cssClasses - CSS classes to apply to the button
   */
  protected renderAddRowButtonOnBottom(cssClasses: any): JSX.Element | null {
    if (!this.question.renderedTable.showAddRowOnBottom) return null;
    return this.renderAddRowButton(cssClasses);
  }

  /**
   * Renders content shown when the matrix has no rows
   * @param cssClasses - CSS classes to apply to the empty state
   */
  protected renderNoRowsContent(cssClasses: any): JSX.Element {
    return (
      <div className={cssClasses.emptyRowsSection}>
        <div className={cssClasses.emptyRowsText}>
          {this.renderLocString(this.question.locEmptyRowsText)}
        </div>
        {this.question.renderedTable.showAddRow && 
          this.renderAddRowButton(cssClasses, true)
        }
      </div>
    );
  }

  /**
   * Renders an add row button with consistent styling
   * @param cssClasses - CSS classes to apply to the button
   * @param isEmptySection - Whether this button is being rendered in the empty state
   */
  protected renderAddRowButton(
    cssClasses: any,
    isEmptySection: boolean = false
  ): JSX.Element {
    return (
      <button 
        className="add-row-button"
        onClick={() => this.question.addRowUI()}
      >
        <Icon.PlusCircle color="var(--indigo-500)" />
        {this.question.addRowText}
      </button>
    );
  }
}

// Register the custom component
Serializer.addClass(
  MATRIX_DYNAMIC_TYPE,
  [],
  function() {
    return new QuestionMatrixDynamicStyledModel("");
  },
  "matrixdynamic"
);

ReactQuestionFactory.Instance.registerQuestion(MATRIX_DYNAMIC_TYPE, (props) => {
  return React.createElement(SurveyQuestionMatrixDynamicStyled, props);
});