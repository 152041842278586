import {
  ChangeEvent,
  FC,
  createElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { QuestionDropdownModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import classNames from "classnames";
import { Combobox } from "../ui/combobox";
import { useAtomValue } from "jotai";
import { suppliersAtom } from "../../jotai-atoms";
import { useTranslate } from "@tolgee/react";

const SPECIFY_OTHER_PLACEHOLDER = "specify-other-placeholder";

interface SuppliersComboboxQuestionProps {
  isDisplayMode: boolean;
  question: QuestionDropdownModel;
}

export const SuppliersComboboxQuestion: FC<SuppliersComboboxQuestionProps> = ({
  question,
}) => {
  const {
    errors,
    value,
    isReadOnly,
    name,
    isRequired,
    placeholder,
    otherItem,
    noneItem,
    hasOther,
    hasNone,
    isOtherSelected,
    commentPlaceHolder,
    cssClasses,
    allowClear,
  } = question;
  const suppliers = useAtomValue(suppliersAtom);
  const [items, setItems] = useState<{ title: string; value: string }[]>([]);
  const [textAreaInput, setTextAreaInput] = useState(question.comment);
  const { t } = useTranslate();
  if (hasOther) {
    setItems([...items, otherItem]);
  }

  if (hasNone) {
    setItems([...items, noneItem]);
  }

  useEffect(() => {
    const newItems = suppliers.map(({ name }) => ({
      title: name,
      value: name,
    }));
    setItems(newItems);
  }, [suppliers]);

  const onValueChange = useCallback(
    (newVal: string) => {
      question.value = newVal;
    },
    [question]
  );

  const onCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaInput(event.target.value);
  };

  const onCommentBlur = () => {
    question.comment = textAreaInput;
  };

  return (
    <div className="flex flex-col gap-2">
      <Combobox
        allowAddNewItem={true}
        hasError={errors?.length > 0}
        cssClasses={cssClasses}
        aria-required={question.ariaRequired}
        aria-label={question.ariaLabel}
        aria-invalid={question.ariaInvalid}
        aria-errormessage={question.ariaErrormessage}
        id={question.inputId}
        items={items}
        required={isRequired}
        disabled={isReadOnly}
        value={value}
        name={name}
        onValueChange={onValueChange}
        placeholder={placeholder}
        allowClear={allowClear}
      />
      {isOtherSelected && (
        <textarea
          disabled={isReadOnly}
          onChange={onCommentChange}
          onBlur={onCommentBlur}
          className={classNames(
            "appearance-none static border-box bg-white w-full py-2 px-[14px] shadow-xs rounded-lg border border-gray-300 focus:text-gray-800 focus:border-indigo-500 focus:ring-4 focus:ring-indigo-100 outline-none disabled:bg-gray-50 disabled:text-gray-500 text-xs md:text-sm leading-[16px] md:leading-5 text-start font-regular text-gray-500 placeholder-gray-300"
          )}
          value={textAreaInput}
          placeholder={commentPlaceHolder || t(SPECIFY_OTHER_PLACEHOLDER)}
        />
      )}
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-suppliers-combobox-question",
  function (props: any) {
    return createElement(SuppliersComboboxQuestion, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "dropdown",
  "suppliers-combobox-question",
  "sv-suppliers-combobox-question"
);
