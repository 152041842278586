import { ReactQuestionFactory } from "survey-react-ui";
import { QuestionExpressionModel, RendererFactory } from "survey-core";
import { createElement, FC, useEffect, useRef } from "react";
import { useTolgee } from "@tolgee/react";
import cn from "../../../utils/cn";
interface GreyExpressionProps {
  question: QuestionExpressionModel;
}

const GreyExpression: FC<GreyExpressionProps> = ({ question }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const tolgee = useTolgee();

  /**
   * Adjusts the min-height of the expression element to match its parent cell height if it's larger than default.
   * This is a hack specifically for T1 form to ensure consistent cell heights in table rows.
   * If parent cell height is smaller than default (40px), the default height is maintained.
   */
  const adjustHeightToParentCell = () => {
    if (!divRef.current) return;

    const defaultHeight = 40;
    divRef.current.style.minHeight = `${defaultHeight}px`;

    const parentCell = divRef.current.closest(".sd-table__cell");
    if (parentCell) {
      const parentHeight = parentCell.clientHeight;
      if (parentHeight > defaultHeight) {
        divRef.current.style.minHeight = `${parentHeight}px`;
      }
    }
  };

  useEffect(() => {
    adjustHeightToParentCell();
  }, []);

  return (
    <div
      ref={divRef}
      lang={tolgee.getLanguage()}
      style={{
        hyphens: "auto",
        wordBreak: "break-word",
      }}
      className={cn("p-2 rounded-md text-sm text-center", {
        "text-slate-500": question.value,
        "bg-[#E8ECEE]": !question.value,
      })}
    >
      {question.value || question.placeholder}
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-grey-expression",
  function (props: any) {
    return createElement(GreyExpression, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "grey-expression",
  "sv-grey-expression"
);
