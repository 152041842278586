import {
  PanelModel,
  Question,
  QuestionCustomModel,
  QuestionMatrixDynamicModel,
  SurveyModel,
} from "survey-core";
import { IMaterial, IProduct, ISupplier } from "../types";
import { getDefaultStore } from "jotai";
import { focusAtom } from "jotai-optics";
import { productsMaterialsAtom } from "../jotai-atoms";
import { ExpressionValidator } from "survey-core";
/**
 * Gets the bom custom questions from the survey.
 * @param survey - the survey
 * @returns the bom custom questions
 */
export function getBomCustomQuestions(
  survey: SurveyModel
): QuestionCustomModel[] {
  return survey
    .getAllQuestions()
    .filter((question) => question.getType() === "bill-of-materials")
    .map((question) => question as QuestionCustomModel);
}

/**
 * Gets the bom question by the product key.
 * @param bomCustomQuestions - the bom custom questions
 * @param productKey - the product key
 * @returns the bom question
 */
export function getBomQuestionByProductKey(
  bomCustomQuestions: QuestionCustomModel[],
  productKey: string
): QuestionMatrixDynamicModel | undefined {
  return bomCustomQuestions.find(
    (q) => q.getPropertyValue("forProductKey") === productKey
  )?.contentQuestion as QuestionMatrixDynamicModel;
}

/**
 * Updates the bom question based on the materials count.
 * It will add or remove rows from the bom question based on the materials count from the product.
 * @param bomCustomQuestions - the bom custom questions
 * @param product - the product
 */
export function updateBomQuestion(
  bomCustomQuestions: QuestionCustomModel[],
  { key: productKey, materialsCount }: IProduct
) {
  const defaultStore = getDefaultStore();
  const productMaterials = defaultStore.get(
    focusAtom(productsMaterialsAtom, (optic) =>
      optic.find((item) => item.productKey === productKey).prop("materials")
    )
  );
  const bomQuestion = getBomQuestionByProductKey(
    bomCustomQuestions,
    productKey
  );
  if (bomQuestion && materialsCount) {
    const bomValueLength = bomQuestion.value?.length ?? 0;
    if (materialsCount > bomValueLength) {
      bomQuestion.rowCount = materialsCount;
    } else if (materialsCount < bomValueLength && productMaterials) {
      const numberOfRowsToRemove = bomValueLength - materialsCount;
      removeEmptyRows(
        bomQuestion,
        getEmptyRowIndexes(productMaterials),
        numberOfRowsToRemove
      );
    }
  }
}

/**
 * Gets the products questions from the survey.
 * @param survey - the survey
 * @returns the products questions
 */
export function getProductsQuestions(
  survey: SurveyModel
): QuestionMatrixDynamicModel[] {
  return (
    survey
      .getAllQuestions()
      .filter((q) => q.getType() === "products") as QuestionCustomModel[]
  ).map((q) => q.contentQuestion) as QuestionMatrixDynamicModel[];
}

/**
 * Updates the product materials count. It will update the materials count for the product in the products questions.
 * @param productsQuestions - the products questions
 * @param productKey - the product key
 * @param materialsCount - the materials count
 */
export function updateProductMaterialsCount(
  productsQuestions: QuestionMatrixDynamicModel[],
  productKey: string,
  materialsCount?: number
) {
  if (materialsCount) {
    productsQuestions.forEach((q) => {
      const value = q.value as IProduct[];
      const product = value.find((p) => p.key === productKey);
      if (product) {
        product.materialsCount = materialsCount;
        q.value = value;
      }
    });
  }
}

/**
 * Gets the template supplier form question from the panel, if exists.
 * @param survey - the survey
 * @param panel - the panel
 * @returns the template question
 */
export function getTemplateQuestion(panel: PanelModel) {
  return panel
    .getQuestions(false)
    .find((question) =>
      question.getPropertyValue("isTemplateSupplierFormQuestion")
    );
}

/**
 * Gets the non template supplier form questions from the panel.
 * @param panel - the panel
 * @returns the non template questions
 */
export function getNonTemplateQuestions(panel: PanelModel) {
  return panel
    .getQuestions(false)
    .filter(
      (question) =>
        !question.getPropertyValue("isTemplateSupplierFormQuestion") &&
        question.getType() === "contractor-form"
    );
}

/**
 * Updates the questions based on the unique suppliers.
 * @param questions - the questions
 * @param uniqueSuppliers - the unique suppliers
 * @param panel - the panel
 */
export function updateQuestions(
  questions: Question[],
  uniqueSuppliers: ISupplier[],
  panel: PanelModel
) {
  questions.forEach((question) => {
    const supplierName = question.name;
    const supplier = uniqueSuppliers.find((s) => s.name === supplierName);
    if (supplier) {
      // Remove the supplier from the suppliers array
      uniqueSuppliers.splice(uniqueSuppliers.indexOf(supplier), 1);
    } else {
      question.value = null;
      panel.removeQuestion(question);
    }
  });
}

/**
 * Adds new questions for the suppliers.
 * @param uniqueSuppliers - the unique suppliers
 * @param panel - the panel
 * @param templateQuestion - the template question
 */
export function addNewQuestionsForSuppliers(
  uniqueSuppliers: ISupplier[],
  panel: PanelModel,
  templateQuestion: Question,
  data: any
) {
  uniqueSuppliers.forEach((supplier) => {
    const newQuestion = panel.addNewQuestion("contractor-form", supplier.name);
    newQuestion.title = supplier.name;
    newQuestion.valueName = templateQuestion?.valueName.concat(
      "#",
      supplier.name
    );
    newQuestion.setPropertyValue("tsPrefix", templateQuestion?.tsPrefix);
    newQuestion.setPropertyValue("supplierName", supplier.name);

    const contactInformationValidator = new ExpressionValidator(
      `{${newQuestion.valueName}.emailAddress} notempty or {${newQuestion.valueName}.phoneNumber} notempty or {${newQuestion.valueName}.mobileWeChatNumber} notempty`
    );

    contactInformationValidator.text =
      "%-%tolgee:supplier-must-have-at-least-one-way-of-contact-error-message%-%";

    newQuestion.validators.push(contactInformationValidator);

    let supplierData = supplier;
    if (data && data[newQuestion.valueName]) {
      supplierData = data[newQuestion.valueName];
    }

    newQuestion.value = supplierData;
  });
}

/**
 * Checks if a material row is empty.
 * @param row - the material row
 * @returns true if the material row is empty, false otherwise
 */
function isEmptyMaterialRow(row: IMaterial): boolean {
  return (
    (row.yarnSuppliers?.length ?? 0) === 0 &&
    (row.fabricSupplierName ?? "") === "" &&
    (row.composition?.length ?? 0) === 0 &&
    (row.position ?? "") === "" &&
    (row.fabricWidth ?? 0) === 0 &&
    (row.fabricUnit ?? "") === "" &&
    (row.fabricWeight ?? 0) === 0 &&
    (row.fabricWeightUnit ?? "") === "" &&
    (row.fabricUsage ?? 0) === 0 &&
    (row.fabricUsageUnit ?? "") === "" &&
    (row.fabricWaste ?? 0) === 0 &&
    (row.fabricWasteUnit ?? "") === "" &&
    (row.materialRefNumber ?? "") === ""
  );
}

/**
 * Counts the number of empty rows in a material row.
 * @param rows - the material rows
 * @returns the number of empty rows
 */
export function countEmptyRows(rows: IMaterial[]): number {
  return rows.filter(isEmptyMaterialRow).length;
}

/**
 * Gets the indexes of the empty rows in a material row.
 * @param rows - the material rows
 * @returns the indexes of the empty rows
 */
export function getEmptyRowIndexes(rows: IMaterial[]): number[] {
  return rows.reduce((emptyIndexes, row, index) => {
    const isEmpty = isEmptyMaterialRow(row);

    if (isEmpty) {
      emptyIndexes.push(index);
    }

    return emptyIndexes;
  }, [] as number[]);
}

/**
 * Removes the empty rows from the bom question.
 * @param bomQuestion - the bom question
 * @param emptyRowIndexes - the indexes of the empty rows
 */
export function removeEmptyRows(
  bomQuestion: QuestionMatrixDynamicModel,
  emptyRowIndexes: number[],
  numberOfRowsToRemove: number
) {
  const emptyRowIndexesToRemove = emptyRowIndexes.slice(
    0,
    numberOfRowsToRemove
  );

  // Iterate over the indexes in reverse order
  for (let i = emptyRowIndexesToRemove.length - 1; i >= 0; i--) {
    const index = emptyRowIndexesToRemove[i];
    bomQuestion.removeRow(index);
  }
}
