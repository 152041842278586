const ERROR_FETCHING_TITLES =
  "%-%tolgee:materials-info-error-fetching-titles%-%";

/**
 * Builds a summary of the materials info selected in the matrix dynamics conditional.
 * @param {Object} params - The parameters for the function.
 * @param {Object} params[0] - The value object containing fabric width and fabric weight.
 * @param {string} params[1] - The URL of the width unit.
 * @param {string} params[2] - The URL of the weight unit.
 * @param {string} params[3] - The URL of the material usage unit.
 * @param {string} params[4] - The URL of the material waste unit.
 */
export default function buildMaterialsInfoSummary(params) {
  const value = params[0];
  const widthUnitUrl = params[1];
  const weightUnitUrl = params[2];
  const materialUsageUnitUrl = params[3];
  const materialWasteUnitUrl = params[4];

  if (!value || !widthUnitUrl || !weightUnitUrl) {
    this.returnResult("");
    return;
  }

  Promise.all([
    fetch(widthUnitUrl).then((response) => response.json()),
    fetch(weightUnitUrl).then((response) => response.json()),
    fetch(materialUsageUnitUrl).then((response) => response.json()),
    fetch(materialWasteUnitUrl).then((response) => response.json()),
  ])
    .then(
      ([widthUnits, weightUnits, materialUsageUnits, materialWasteUnits]) => {
        const widthUnitMap = widthUnits.reduce(
          (acc, unit) => ({
            ...acc,
            [unit.value]: unit.text,
          }),
          {}
        );

        const weightUnitMap = weightUnits.reduce(
          (acc, unit) => ({
            ...acc,
            [unit.value]: unit.text,
          }),
          {}
        );

        const materialUsageUnitMap = materialUsageUnits.reduce(
          (acc, unit) => ({
            ...acc,
            [unit.value]: unit.text,
          }),
          {}
        );

        const materialWasteUnitMap = materialWasteUnits.reduce(
          (acc, unit) => ({
            ...acc,
            [unit.value]: unit.text,
          }),
          {}
        );

        const width = value.fabricWidth;
        const fabricWidthUnit = value.fabricUnit;
        const widthUnit = widthUnitMap[fabricWidthUnit] || fabricWidthUnit;
        const weight = value.fabricWeight;
        const fabricWeightUnit = value.fabricWeightUnit;
        const weightUnit = weightUnitMap[fabricWeightUnit] || fabricWeightUnit;

        const usage = value.fabricUsage;
        const materialUsageUnit = value.fabricUsageUnit;
        const usageUnit =
          materialUsageUnitMap[materialUsageUnit] || materialUsageUnit;

        const waste = value.fabricWaste;
        const materialWasteUnit = value.fabricWasteUnit;
        const wasteUnit =
          materialWasteUnitMap[materialWasteUnit] || materialWasteUnit;

        const parts = [];

        if (width) {
          const widthInfo = [width];
          if (widthUnit) {
            widthInfo.push(widthUnit);
            widthInfo.push(
              "%-%tolgee:pq-bom-t1-fabric-material-info-material-width-suffix%-%"
            );
          }
          parts.push(widthInfo.join(" "));
        }

        if (weight) {
          const weightInfo = [weight];
          if (weightUnit) {
            weightInfo.push(weightUnit);
          }
          parts.push(weightInfo.join(" "));
        }

        if (usage) {
          const usageInfo = [usage];
          if (usageUnit) {
            usageInfo.push(usageUnit);
            usageInfo.push(
              "%-%tolgee:pq-bom-t1-fabric-material-info-material-usage-suffix%-%"
            );
          }
          parts.push(usageInfo.join(" "));
        }

        if (waste) {
          const wasteInfo = [waste];
          if (wasteUnit) {
            wasteInfo.push(wasteUnit);
            wasteInfo.push(
              "%-%tolgee:pq-bom-t1-fabric-material-info-material-waste-suffix%-%"
            );
          }
          parts.push(wasteInfo.join(" "));
        }

        const summary = parts.length > 1 ? parts.join(", ") : parts[0] || "";
        this.returnResult(summary);
      }
    )
    .catch((error) => {
      this.returnResult(ERROR_FETCHING_TITLES);
      console.error(ERROR_FETCHING_TITLES, error);
    });
}
