import {
  Root,
  Item,
  Indicator,
  RadioGroupProps as RootProps,
} from "@radix-ui/react-radio-group";
import { FC } from "react";
import cn from "../../../utils/cn";

interface RadioGroupItem {
  value: string;
  title: string;
}

type RadioGroupProps = {
  items: RadioGroupItem[];
  cssClassNames?: {
    root?: string;
    item?: string;
    indicator?: string;
    label?: string;
  };
} & RootProps;

export const RadioGroup: FC<RadioGroupProps> = ({
  items,
  cssClassNames = {
    root: "flex-col",
    item: "",
    indicator: "",
    label: "",
  },
  ...props
}) => {
  /**
   * Generate a unique id for each item in the radio group
   * @param item - The item to generate an id for
   * @returns The unique id for the item
   */
  const itemId = (item: RadioGroupItem) => {
    return props.name + "-" + item.value;
  };

  return (
    <Root className={cn("flex gap-[15px]", cssClassNames?.root)} {...props}>
      {items.map((item) => (
        <div className="flex items-center" key={item.value}>
          <Item
            className={cn(
              "bg-white w-[16px] h-[16px] rounded-full border border-gray-300 focus:ring-4 shrink-0 grow-0" +
                "focus:ring-indigo-100 data-[disabled]:bg-gray-100 data-[disabled]:border-gray-200 " +
                "data-[disabled]:data-[state=checked]:border-gray-400 data-[state=checked]:bg-indigo-50 " +
                "data-[state=checked]:border-indigo-600",
              cssClassNames?.item
            )}
            value={item.value}
            id={itemId(item)}
          >
            <Indicator
              className={cn(
                "flex items-center justify-center w-full h-full relative after:content-['']" +
                  "after:block after:w-[5px] after:h-[5px] after:rounded-[50%] " +
                  "after:bg-indigo-600 data-[disabled]:after:bg-gray-400",
                cssClassNames?.indicator
              )}
            />
          </Item>
          <label
            className={cn(
              "pl-2 text-xs md:text-sm font-medium leading-[16px] md:leading-6 text-wrap",
              props.disabled ? "text-gray-300" : "text-gray-700",
              cssClassNames?.label
            )}
            htmlFor={itemId(item)}
          >
            {item.title}
          </label>
        </div>
      ))}
    </Root>
  );
};
