/**
 * This component is a custom question type that renders contractor information for a specific process.
 *
 * Form was created to reduce rendering time of Survey.js for a number of such forms.
 *
 * */

import React, { useEffect } from "react";
import {
  ElementFactory,
  ISurvey,
  Question,
  Serializer,
  SurveyError,
} from "survey-core";
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from "survey-react-ui";
import { Checkbox } from "../../../ui/checkbox";
import { RadioGroup } from "../../../ui/radio-group";
import { MultiSelectDownshift } from "../../../ui/multi-select-downshift";
import { useTranslate } from "@tolgee/react";
import { isEqual } from "lodash";
import { ComboboxOther } from "../../../ui/combobox-other";
import "./index.css";
const CUSTOM_TYPE = "pq-bop-t2-relevant-process";

interface ProcessDropdown {
  name: string;
  choices?: Array<{
    value: string;
    text: string;
  }>;
  hasOther?: boolean;
  isMultiSelect?: boolean;
  choicesByUrl?: {
    url: string;
    valueName: string;
    titleName: string;
  };
}

interface Process {
  name: string;
  title: string;
  visibleIf?: string;
  resetValueIf?: string;
  inHouseTitle?: string;
  subcontractorTitle?: string;
  dropdowns?: ProcessDropdown[];
}

export class PqBopT2RelevantProcessModel extends Question {
  private customErrors: Record<string, Record<string, string>> = {};
  private t: any;

  getType() {
    return CUSTOM_TYPE;
  }

  public setTranslation(t: any) {
    this.t = t;
  }

  /**
   * Override the validate method to implement custom validation
   * This will now only run when the form is submitted
   */
  public validate(fireCallback?: boolean, rec?: any): boolean {
    // Only validate if explicitly called (on form submit)
    if (!fireCallback) {
      return true;
    }

    super.validate(fireCallback, rec);

    const value = this.value || {};
    this.customErrors = {};
    let isValid = true;

    // Validate each process
    Object.entries(value.processes || {}).forEach(
      ([processName, process]: [string, any]) => {
        this.customErrors[processName] = {};

        // Only validate if process is selected
        if (process.processSelected) {
          // Validate process option (in-house/subcontractor)
          if (!process.processOption) {
            this.customErrors[processName].processOption = this.t(
              "pq-bop-t2-required-field"
            );
            if (isValid) {
              this.errors.push(
                new SurveyError(this.t("pq-bop-t2-form-errors"))
              );
              isValid = false;
            }
          }
        }
      }
    );

    return isValid;
  }

  public getCustomError(
    processName: string,
    fieldName: string
  ): string | undefined {
    return this.customErrors[processName]?.[fieldName];
  }

  public hasCustomError(processName: string, fieldName: string): boolean {
    return !!this.getCustomError(processName, fieldName);
  }
}

Serializer.addClass(
  CUSTOM_TYPE,
  [
    {
      name: "processes",
      type: "array",
      default: [],
    },
  ],
  function () {
    return new PqBopT2RelevantProcessModel("");
  },
  "question"
);

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new PqBopT2RelevantProcessModel(name);
});

interface FormValues {
  processes: {
    [processName: string]: {
      processSelected: boolean;
      processOption: "in-house" | "subcontractor" | "";
      dropdownValues: {
        [key: string]: string[];
      };
      otherValues: {
        [key: string]: string | undefined;
      };
    };
  };
}

interface ComponentState {
  formValues: FormValues;
}

interface ProcessItemProps {
  process: Process;
  formValues: FormValues;
  name: string;
  t: (key: string) => string;
  survey: ISurvey;
  question: PqBopT2RelevantProcessModel;
  onProcessSelection: (processName: string, checked: boolean) => void;
  onProcessOptionChange: (
    processName: string,
    value: "in-house" | "subcontractor" | ""
  ) => void;
  onDropdownChange: (
    processName: string,
    dropdownName: string,
    values: string[]
  ) => void;
  onOtherValueChange: (
    processName: string,
    dropdownName: string,
    value: string | undefined
  ) => void;
  resetProcessValues: (processName: string) => void;
}

const ProcessItem: React.FC<ProcessItemProps> = ({
  process,
  formValues,
  name,
  survey,
  question,
  t,
  onProcessSelection,
  onProcessOptionChange,
  onDropdownChange,
  onOtherValueChange,
  resetProcessValues,
}) => {
  const { visibleIf, resetValueIf, inHouseTitle, subcontractorTitle } = process;

  const processValues = formValues.processes[process.name];
  const inHouseTitleTranslated =
    inHouseTitle || t("pq-bop-t2-process-fabric-in-house"); // TODO: needs to be moved to the schema
  const subcontractorTitleTranslated =
    subcontractorTitle || t("pq-bop-t2-process-fabric-subcontractor"); // TODO: needs to be moved to the schema
  const visibleIfCondition = visibleIf ? survey.runExpression(visibleIf) : true;
  const resetValueIfCondition = resetValueIf
    ? survey.runExpression(resetValueIf)
    : false;
  const [dropdownItems, setDropdownItems] = React.useState<
    Record<string, Array<{ value: string; title: string }>>
  >({});
  const showDropdown =
    Array.isArray(process.dropdowns) &&
    process.dropdowns.length > 0 &&
    processValues?.processSelected;

  // Fetch choices when choicesByUrl is present AND dropdown is visible
  useEffect(() => {
    const fetchChoices = async (dropdown: ProcessDropdown) => {
      if (!dropdown.choicesByUrl) return;

      try {
        const response = await fetch(dropdown.choicesByUrl.url);
        const data = await response.json();

        const items = data.map((item: any) => ({
          value: item[dropdown.choicesByUrl!.valueName],
          title: item[dropdown.choicesByUrl!.titleName],
        }));

        setDropdownItems((prev) => ({
          ...prev,
          [dropdown.name]: items,
        }));
      } catch (error) {
        console.error(`Error fetching choices for ${dropdown.name}:`, error);
      }
    };

    // Only fetch if dropdown should be visible
    if (showDropdown) {
      process.dropdowns?.forEach((dropdown) => {
        if (dropdown.choicesByUrl) {
          fetchChoices(dropdown);
        }
      });
    }
  }, [process.dropdowns, showDropdown]);

  useEffect(() => {
    if (resetValueIfCondition === true) {
      // Check if any values need resetting
      const hasNonDefaultValues =
        processValues.processSelected !== false ||
        processValues.processOption !== "" ||
        Object.keys(processValues.dropdownValues).length > 0 ||
        Object.keys(processValues.otherValues).length > 0;

      if (hasNonDefaultValues) {
        resetProcessValues(process.name);
      }
    }
  }, [
    resetValueIfCondition,
    process.name,
    resetProcessValues,
    processValues.processSelected,
    processValues.processOption,
    processValues.dropdownValues,
    processValues.otherValues,
  ]);

  if (visibleIfCondition === false) {
    return null;
  }

  return (
    <div className="grid grid-cols-[1fr_1fr] gap-4 pq-bop-t2-relevant-process pb-[30px]">
      <div className="flex">
        <Checkbox
          checked={processValues?.processSelected}
          onCheckedChange={(checked: boolean) =>
            onProcessSelection(process.name, checked)
          }
          label={process.title}
          name={`${name}-${process.name}-checkbox`}
          id={`${name}-${process.name}-checkbox`}
        />
      </div>

      <div className="flex flex-col">
        <RadioGroup
          items={[
            {
              value: "in-house",
              title: inHouseTitleTranslated,
            },
            {
              value: "subcontractor",
              title: subcontractorTitleTranslated,
            },
          ]}
          cssClassNames={{
            root: `flex-row flex-wrap radiogroup ${
              question.hasCustomError(
                process.name,
                "processOption"
              )
                ? "error"
                : ""
            }`,
          }}
          value={processValues?.processOption}
          onValueChange={(value: "in-house" | "subcontractor" | "") =>
            onProcessOptionChange(
              process.name,
              value as "in-house" | "subcontractor" | ""
            )
          }
          name={`${name}-${process.name}-options`}
          disabled={!processValues?.processSelected}
        />
        {question.getCustomError(
          process.name,
          "processOption"
        ) && (
          <div className="text-red-500 text-xs mt-1">
            {question.getCustomError(
              process.name,
              "processOption"
            )}
          </div>
        )}
      </div>

      {showDropdown && (
        <div className="col-span-2 grid grid-cols-2 gap-4">
          {process.dropdowns?.map((dropdown) => {
            // Use choices from props if provided, otherwise use fetched items
            const items = dropdown.choices
              ? dropdown.choices.map((choice) => ({
                  value: choice.value,
                  title: choice.text,
                }))
              : dropdownItems[dropdown.name] || [];

            if (dropdown.isMultiSelect) {
              return (
                <MultiSelectDownshift
                  key={`${name}-${process.name}-${dropdown.name}`}
                  name={dropdown.name}
                  placeholder={t(
                    "pq-bop-t2-relevant-process-dropdown-search-placeholder"
                  )}
                  items={items}
                  values={processValues?.dropdownValues[dropdown.name] || []}
                  onValueChange={(values) =>
                    onDropdownChange(process.name, dropdown.name, values)
                  }
                  disabled={!processValues?.processSelected}
                  hasOther={dropdown.hasOther || false}
                  otherItem={{
                    value: "other",
                    title: t("pq-bop-t2-dropdown-other"),
                  }}
                  commentPlaceHolder={t("pq-bop-t2-dropdown-other-placeholder")}
                  isOtherSelected={processValues?.dropdownValues[
                    dropdown.name
                  ]?.includes("other")}
                  comment={processValues?.otherValues[dropdown.name] || ""}
                  onCommentChange={(value) =>
                    onOtherValueChange(process.name, dropdown.name, value)
                  }
                />
              );
            } else {
              return (
                <div
                  key={`${name}-${process.name}-${dropdown.name}`}
                  className="flex flex-col gap-2"
                >
                  <ComboboxOther
                    name={`${name}-${process.name}-${dropdown.name}`}
                    placeholder={t(
                      "pq-bop-t2-relevant-process-dropdown-search-placeholder"
                    )}
                    items={items}
                    value={
                      processValues?.dropdownValues[dropdown.name]?.[0] || ""
                    }
                    onValueChange={(value) =>
                      onDropdownChange(
                        process.name,
                        dropdown.name,
                        value ? [value] : []
                      )
                    }
                    disabled={!processValues?.processSelected}
                    hasOther={dropdown.hasOther || false}
                    allowClear={true}
                    otherItem={{
                      value: "other",
                      title: t("pq-bop-t2-dropdown-other"),
                    }}
                    commentPlaceHolder={t(
                      "pq-bop-t2-dropdown-other-placeholder"
                    )}
                    isOtherSelected={processValues?.dropdownValues[
                      dropdown.name
                    ]?.includes("other")}
                    comment={processValues?.otherValues[dropdown.name] || ""}
                    onCommentChange={(value) =>
                      onOtherValueChange(process.name, dropdown.name, value)
                    }
                  />
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

const PqBopT2RelevantProcessWrapper: React.FC<any> = (props) => {
  const { t } = useTranslate();
  props.question.setTranslation(t);
  return <PqBopT2RelevantProcess {...props} t={t} />;
};

export class PqBopT2RelevantProcess extends SurveyQuestionElementBase {
  state: ComponentState;

  constructor(props: any) {
    super(props);
    const questionValue = this.question.value || {};

    // Initialize state with all processes
    const initialProcesses = this.question.processes.reduce(
      (acc: FormValues["processes"], process: Process) => {
        acc[process.name] = questionValue.processes?.[process.name] || {
          processSelected: false,
          processOption: "",
          dropdownValues: {},
          otherValues: {},
        };
        return acc;
      },
      {}
    );

    this.state = {
      formValues: {
        processes: initialProcesses,
      },
    };
  }

  /**
   * Handles the process selection.
   * @param processName - The name of the process.
   * @param checked - Whether the process is selected.
   */
  handleProcessSelection = (processName: string, checked: boolean) => {
    const state = this.state;
    const formValues = state.formValues;
    const processes = formValues.processes;
    const process = processes[processName];
    const currentValue = process?.processSelected;
    if (isEqual(currentValue, checked)) return;

    const newFormValues = {
      ...formValues,
      processes: {
        ...processes,
        [processName]: {
          ...process,
          processSelected: checked,
          ...(checked
            ? {}
            : {
                processOption: "",
                dropdownValues: {},
                otherValues: {},
              }),
        },
      },
    };

    this.setState((prevState: ComponentState) => {
      const mergedFormValues = {
        ...prevState.formValues,
        ...newFormValues,
      };
      this.question.value = mergedFormValues;
      return { formValues: mergedFormValues };
    });
  };

  /**
   * Handles the process option change.
   * @param processName - The name of the process.
   * @param value - The value of the process option.
   */
  handleProcessOptionChange = (
    processName: string,
    value: "in-house" | "subcontractor" | ""
  ) => {
    const state = this.state;
    const formValues = state.formValues;
    const processes = formValues.processes;
    const process = processes[processName];
    const currentValue = process?.processOption;
    if (isEqual(currentValue, value)) return;

    const newFormValues = {
      ...formValues,
      processes: {
        ...processes,
        [processName]: {
          ...process,
          processOption: value,
        },
      },
    };

    this.setState((prevState: ComponentState) => {
      const mergedFormValues = {
        ...prevState.formValues,
        ...newFormValues,
      };
      this.question.value = mergedFormValues;
      return { formValues: mergedFormValues };
    });
  };

  /**
   * Gets the question.
   * @returns The question.
   */
  get question() {
    return this.questionBase;
  }

  /**
   * Gets the value of the question.
   * @returns The value of the question.
   */
  get value() {
    return this.question.value;
  }

  /**
   * Handles the dropdown change.
   * @param processName - The name of the process.
   * @param dropdownName - The name of the dropdown.
   * @param values - The values of the dropdown.
   */
  handleDropdownChange = (
    processName: string,
    dropdownName: string,
    values: string[]
  ) => {
    const state = this.state;
    const formValues = state.formValues;
    const processes = formValues.processes;
    const process = processes[processName];
    const currentValues = process?.dropdownValues[dropdownName] || [];
    if (isEqual(currentValues, values)) return;

    const newFormValues = {
      ...formValues,
      processes: {
        ...processes,
        [processName]: {
          ...process,
          dropdownValues: {
            ...process.dropdownValues,
            [dropdownName]: values,
          },
        },
      },
    };

    this.setState((prevState: ComponentState) => {
      const mergedFormValues = {
        ...prevState.formValues,
        ...newFormValues,
      };
      this.question.value = mergedFormValues;
      return { formValues: mergedFormValues };
    });
  };

  /**
   * Handles the other value change.
   * @param processName - The name of the process.
   * @param dropdownName - The name of the dropdown.
   * @param value - The value of the other value.
   */
  handleOtherValueChange = (
    processName: string,
    dropdownName: string,
    value: string | undefined
  ) => {
    const state = this.state;
    const formValues = state.formValues;
    const processes = formValues.processes;
    const process = processes[processName];
    const currentValue = process?.otherValues[dropdownName];
    if (isEqual(currentValue, value)) return;

    const newFormValues = {
      ...formValues,
      processes: {
        ...processes,
        [processName]: {
          ...process,
          otherValues: {
            ...process.otherValues,
            [dropdownName]: value,
          },
        },
      },
    };

    this.setState((prevState: ComponentState) => {
      const mergedFormValues = {
        ...prevState.formValues,
        ...newFormValues,
      };
      this.question.value = mergedFormValues;
      return { formValues: mergedFormValues };
    });
  };

  /**
   * Resets the process values.
   * @param processName - The name of the process.
   */
  resetProcessValues = (processName: string) => {
    this.setState((prevState: ComponentState) => {
      const processes = prevState.formValues.processes;
      const process = processes[processName];

      // Skip if process is already in initial state
      if (
        !process.processSelected &&
        !process.processOption &&
        Object.keys(process.dropdownValues).length === 0 &&
        Object.keys(process.otherValues).length === 0
      ) {
        return prevState;
      }

      const newProcessState = {
        ...process,
        processSelected: false,
        processOption: "",
        dropdownValues: {},
        otherValues: {},
      };

      const newFormValues = {
        ...prevState.formValues,
        processes: {
          ...processes,
          [processName]: newProcessState,
        },
      };

      this.question.value = newFormValues;
      return { formValues: newFormValues };
    });
  };

  /**
   * Renders the element.
   * @returns The rendered element.
   */
  renderElement() {
    if (!this.question.visible) {
      return null;
    }

    const { name } = this.question;
    const { formValues } = this.state;
    const { t } = this.props;

    return (
      <div className="grid grid-cols-4">
        <div className="col-span-1">
          <h5 className="sd-question__title text-xs md:text-sm leading-[16px] md:leading-5 !text-gray-700  whitespace-normal font-bold">
            {this.question.title}
          </h5>
        </div>
        <div className="col-span-3">
          {this.question.processes.map((process: Process) => (
            <ProcessItem
              key={process.name}
              process={process}
              formValues={formValues}
              name={name}
              t={t}
              question={this.question as PqBopT2RelevantProcessModel}
              survey={this.question.survey}
              resetProcessValues={this.resetProcessValues}
              onProcessSelection={this.handleProcessSelection}
              onProcessOptionChange={this.handleProcessOptionChange}
              onDropdownChange={this.handleDropdownChange}
              onOtherValueChange={this.handleOtherValueChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return React.createElement(PqBopT2RelevantProcessWrapper, props);
});
