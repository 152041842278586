import { T, useTolgee } from "@tolgee/react";
import { useEffect } from "react";

interface ValidationPopupProps {
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  contentMessage: string,
  errorMessage: string;
  buttonText: string;
}

export const ValidationPopup = ({
  onClose,
  onSubmit,
  title,
  contentMessage,
  errorMessage,
  buttonText,
}: ValidationPopupProps) => {
  const tolgee = useTolgee();
  const { t } = tolgee;
  const titleText = title || t("validation-popup-title");
  const contentMessageText = contentMessage || t("validation-content-message");
  const errorMessageText = errorMessage || t("validation-errors-message");
  const buttonTextText = buttonText || t("button-ok");

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="relative w-full max-w-md rounded-lg bg-[#F1F4F9] p-[20px] shadow-lg border border-red-600">
        <div
          className={
            "md:inline-block hidden w-fit rounded-[16px] py-0.5 px-2 relative top-[-3px] font-medium text-[10px] text-center text-white bg-red-600"
          }
        >
          <T keyName="please-note" />
        </div>
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
          aria-label="Close"
        >
          <svg
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        {/* Icon and Title */}
        <div className="mb-4 pt-4 flex items-center">
          <h3 className="text-[24px] leading-[32px] font-semibold text-[#4067A0]">
            {titleText}
          </h3>
        </div>

        {/* Content */}
        <div className="text-[16px] leading-[24px] text-[#294166]">
          {contentMessageText}
        </div>

        <div className="flex items-center justify-center gap-4 py-[30px]">
          <div className="border border-[#4067A0] rounded-[8px] p-2 w-[100px] h-[44px] text-[#4067A0] text-center">
            &lt;
          </div>
          <div className="border border-[#4067A0] rounded-[8px] p-2 w-[100px] h-[44px] text-[#4067A0] text-center">
            &gt;
          </div>
        </div>

        <div className="text-[16px] leading-[24px] mb-6 text-[#294166]">
          {errorMessageText}
        </div>

        {/* Buttons */}
        <div className="flex justify-end space-x-3">
          <button
            onClick={onSubmit}
            className="rounded-md bg-[#4067A0] px-[20px] py-[10px] text-[16px] leading-[24px] text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500"
          >
            {buttonTextText}
          </button>
        </div>
      </div>
    </div>
  );
};
