import { FC, createElement, useContext } from "react";
import { QuestionBooleanModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { Checkbox } from "../ui/checkbox";
import { CheckboxProps } from "@radix-ui/react-checkbox";
import { ApiServiceContext } from "../../contexts";
import { AppIcon } from "../atoms";
import { isMatrixDropdown, isMatrixDynamic } from "../../utils";

interface BooleanAsCheckboxQuestionProps {
  question: QuestionBooleanModel;
}

const BooleanAsCheckboxQuestion: FC<BooleanAsCheckboxQuestionProps> = ({
  question,
}) => {
  const {
    value,
    title,
    defaultValue,
    name,
    isReadOnly,
    icon,
    iconColor,
    parentQuestion,
  } = question;
  const { apiService } = useContext(ApiServiceContext);
  const handleValueChange: CheckboxProps["onCheckedChange"] = (checked) => {
    question.value = checked;
  };

  const labelTextSize =
    apiService.resourceType === "facility-questionnaire" ? "!text-base" : "";

  return (
    <div
      className={`${
        isMatrixDropdown(parentQuestion) || isMatrixDynamic(parentQuestion)
          ? "grid place-items-center"
          : ""
      }`}
    >
      <Checkbox
        labelClassname={labelTextSize}
        defaultValue={defaultValue}
        Icon={<AppIcon name={icon} color={`var(--${iconColor})`} />}
        label={
          isMatrixDropdown(parentQuestion) || isMatrixDynamic(parentQuestion)
            ? ""
            : title
        }
        checked={value}
        disabled={isReadOnly}
        onCheckedChange={handleValueChange}
        id={question.getValueName() ?? name}
      />
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-boolean-as-checkbox-question",
  function (props: any) {
    return createElement(BooleanAsCheckboxQuestion, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "boolean",
  "boolean-as-checkbox-question",
  "sv-boolean-as-checkbox-question"
);
