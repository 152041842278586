import { ReactQuestionFactory } from "survey-react-ui";
import { QuestionExpressionModel, RendererFactory } from "survey-core";
import { createElement, FC } from "react";
import { Edit } from "../../icons";

interface EditButtonProps {
  question: QuestionExpressionModel;
}

const EditButton: FC<EditButtonProps> = ({ question }) => {
  return (
    <button className="bg-blue-400 h-[40px] w-[40px] rounded-md flex items-center justify-center text-white">
      <Edit />
    </button>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-edit-button",
  function (props: any) {
    return createElement(EditButton, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "edit-button",
  "sv-edit-button"
);
