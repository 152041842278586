import { getDefaultStore } from "jotai";
import {
  ICustomQuestionTypeConfiguration,
  QuestionCustomModel,
  QuestionMatrixDynamicModel,
  Serializer,
  SurveyModel,
  MatrixDropdownColumn,
} from "survey-core";
import { productsAtom } from "../../../../../jotai-atoms";
import { IProduct } from "../../../../../types";
import {
  getBomCustomQuestions,
  updateBomQuestion,
} from "../../../../../utils/bom-utils";

const QUESTION_TYPE_NAME = "products";
const QUESTION_TITLE_IN_BUILDER = "Products";
const DEFAULT_QUESTION_TITLE = "Products";

interface IProductsQuestion extends ICustomQuestionTypeConfiguration {}

const productsQuestion: IProductsQuestion = {
  name: QUESTION_TYPE_NAME,
  title: QUESTION_TITLE_IN_BUILDER,
  defaultQuestionTitle: DEFAULT_QUESTION_TITLE,
  questionJSON: {
    type: "matrixdynamic",
    name: "products",
    titleLocation: "hidden",
    allowAddRows: false,
    rowCount: 1,
    allowRemoveRows: false,
    columns: [
      {
        cellType: "html",
        name: "rowIndex",
        title: " ",
        width: "25px",
        minWidth: "25px",
        html: "<div class='grid h-10 text-xs leading-[18px] font-semibold text-indigo-500 place-items-center'>{rowIndex}</div>",
      },
      {
        name: "key", // Amit: DO NOT CHANGE THIS NAME
        cellType: "text",
        visible: false,
      },
      {
        name: "styleNumber", // Amit: DO NOT CHANGE THIS NAME
        title: "%-%tolgee:style-number-title%-%",
        cellType: "text",
        placeholder: "%-%tolgee:style-number-placeholder%-%",
        minWidth: "100px",
        readOnly: true,
      },
      {
        name: "productName", // Amit: DO NOT CHANGE THIS NAME
        title: "%-%tolgee:product-name-title%-%",
        cellType: "text",
        placeholder: "%-%tolgee:product-name-placeholder%-%",
        minWidth: "100px",
        readOnly: true,
      },
      {
        name: "brand", // Amit: DO NOT CHANGE THIS NAME
        title: "%-%tolgee:brand-title%-%",
        cellType: "text",
        placeholder: "%-%tolgee:brand-placeholder%-%",
        minWidth: "100px",
        readOnly: true,
      },
      {
        name: "color", // Amit: DO NOT CHANGE THIS NAME
        title: "%-%tolgee:color-title%-%",
        cellType: "text",
        placeholder: "%-%tolgee:color-placeholder%-%",
        minWidth: "100px",
        readOnly: true,
      },
      {
        name: "poNumber", // Amit: DO NOT CHANGE THIS NAME
        title: "%-%tolgee:po-number-title%-%",
        cellType: "text",
        placeholder: "%-%tolgee:po-number-placeholder%-%",
        minWidth: "100px",
        readOnly: true,
      },
      {
        name: "season", // Amit: DO NOT CHANGE THIS NAME
        title: "%-%tolgee:season-title%-%",
        cellType: "text",
        placeholder: "%-%tolgee:season-placeholder%-%",
        minWidth: "100px",
        readOnly: true,
      },
      {
        name: "customerType", // Amit: DO NOT CHANGE THIS NAME
        cellType: "text",
        placeholder: "%-%tolgee:dropdown-search-placeholder%-%",
        title: "%-%tolgee:customer-type-title%-%",
        readOnly: true,
        minWidth: "100px",
      },

      // Amit: I Don't delete it in case they want to use it again
      {
        name: "materialsCount", // Amit: DO NOT CHANGE THIS NAME
        cellType: "dropdown",
        placeholder: "%-%tolgee:dropdown-search-placeholder%-%",
        isRequired: true,
        allowClear: false,
        title: "Materials Count",
        renderAs: "materials-count-dropdown-question",
        forProductKeyExpression: "{row.key}",
        visible: false,
        choices: [
          { value: 1, text: "1" },
          { value: 2, text: "2" },
          { value: 3, text: "3" },
          { value: 4, text: "4" },
          { value: 5, text: "5" },
          { value: 6, text: "6" },
          { value: 7, text: "7" },
          { value: 8, text: "8" },
          { value: 9, text: "9" },
          { value: 10, text: "10" },
        ],
        minWidth: "100px",
      },
    ],
  },
  onInit: () => {
    Serializer.addProperty(QUESTION_TYPE_NAME, {
      name: "tsPrefix",
      default: "",
    });
  },
  onLoaded: (question: QuestionCustomModel) => {
    const productsTable =
      question.contentQuestion as QuestionMatrixDynamicModel;
    const productsTableValue = productsTable.value as IProduct[];
    const defaultStore = getDefaultStore();
    const tsPrefix = question.getPropertyValue("tsPrefix");

    if (tsPrefix) {
      // Update matrix column names based on tsPrefix
      const columns = productsTable.columns as MatrixDropdownColumn[];
      columns.forEach((column) => {
        if (column.name === "rowIndex") {
          return;
        }

        const title = column.title;
        const placeholder = (column as any).placeholder;
        let prefixTitle = "";
        if (title && title.includes("tolgee:")) {
          prefixTitle = title.replace(
            /(%-%tolgee:)(.+?)(%-%)/,
            `$1${tsPrefix}-$2$3`
          );
        } else {
          prefixTitle = `${tsPrefix}-${title}`;
        }
        column.title = prefixTitle;

        // Handle placeholder
        if (placeholder && placeholder.includes("tolgee:")) {
          const prefixPlaceholder = placeholder.replace(
            /(%-%tolgee:)(.+?)(%-%)/,
            `$1${tsPrefix}-$2$3`
          );
          (column as any).placeholder = prefixPlaceholder;
        } else if (placeholder) {
          (column as any).placeholder = `${tsPrefix}-${placeholder}`;
        }
      });
    }
    defaultStore.set(productsAtom, productsTableValue);
  },
  onValueChanged: (question: QuestionCustomModel, _, newValue: IProduct[]) => {
    const defaultStore = getDefaultStore();
    defaultStore.set(productsAtom, newValue); // update the products table

    const billOfMaterialsQuestions = getBomCustomQuestions(
      question.survey as SurveyModel
    );

    newValue.forEach((product) => {
      updateBomQuestion(billOfMaterialsQuestions, product); // update the bom question
    });
  },
};

export default productsQuestion;
