import { FC, createElement } from "react";
import { ReactQuestionFactory } from "survey-react-ui";
import { QuestionExpressionModel, RendererFactory } from "survey-core";
import * as Icon from "../icons";
interface BlueTitleExpressionProps {
  question: QuestionExpressionModel;
}

export const BlueTitleExpression: FC<BlueTitleExpressionProps> = ({
  question,
}) => {
  return (
    <div className="text-sm mb-4 text-sky-600">
      <Icon.AlertCircle className="w-4 h-4 mr-2 text-sky-600 inline-block text-xs" />{" "}
      {question.title}
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-blue-title-expression",
  function (props: any) {
    return createElement(BlueTitleExpression, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "blue-title-expression",
  "sv-blue-title-expression"
);
