import { ComponentCollection } from "survey-core";
import {
  billOfMaterialsQuestion,
  billOfProcessesQuestion,
  contractorFormQuestion,
  productsQuestion,
} from "./components/survey/questions";

ComponentCollection.Instance.add(contractorFormQuestion);
ComponentCollection.Instance.add(billOfMaterialsQuestion);
ComponentCollection.Instance.add(productsQuestion);
ComponentCollection.Instance.add(billOfProcessesQuestion);
