import React from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from "survey-react-ui";
import { T } from "@tolgee/react";

const CUSTOM_TYPE = "pq-bop-t2-sidebar-expression";

// Define the custom question model
export class SidebarExpressionModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}

// Add question type metadata for serialization
Serializer.addClass(
  CUSTOM_TYPE,
  [
    {
      name: "subtitle",
      type: "string",
      default: "",
    },
  ],
  function () {
    return new SidebarExpressionModel("");
  },
  "question"
);

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new SidebarExpressionModel(name);
});

// Component that renders the sidebar expression
export class SidebarExpression extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  renderElement() {
    return (
      <div className="w-[266px] bg-indigo-25 rounded-[10px] p-5">
        <div className="flex flex-col text-md font-regular text-wrap">
          <div className="text-2xl font-bold pb-8 text-indigo-500">
            <T keyName="pq-bop-t2-sidebar-expression-title" />
          </div>
          <div className="text-base font-semibold  border-b border-[#C5D3E8] pb-8 text-indigo-500">
            <T
              keyName="pq-bop-t2-sidebar-expression-subtitle"
              params={{ brand: this.question.subtitle }}
            />
          </div>
          <div className="border-b border-[#C5D3E8] text-sm py-8 text-[#294166]">
            <T keyName="pq-bop-t2-sidebar-expression-description" />
          </div>
          <div className="flex flex-col gap-5 text-xs pt-8 text-indigo-500">
            <T keyName="pq-bop-t2-sidebar-expression-have-questions-text" />
          </div>
        </div>
      </div>
    );
  }
}

// Register the custom question type
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return React.createElement(SidebarExpression, props);
});
