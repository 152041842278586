import {
  ReactQuestionFactory,
  Survey,
  SurveyModel,
  SurveyQuestionElementBase,
} from "survey-react-ui";
import { DIALOG_QUESTION_TYPE } from "../../../../../models";
import { createElement } from "react";
import { Edit, X } from "react-feather";
import "./custom.css";
import { customCss } from "../../../../../survey-css";
import { themeJson } from "../../../../../theme";
import { createPortal } from "react-dom";
import { bomExtraInfoSchema } from "../../../../../inner-schemas/bom-extra-info";
import { T } from "@tolgee/react";
import { getDefaultStore } from "jotai";
import { completeTriesAtom } from "../../../../../jotai-atoms";
import cn from "../../../../../utils/cn";

const css = JSON.parse(JSON.stringify(customCss));
const theme = JSON.parse(JSON.stringify(themeJson));
theme.cssVariables["--sjs-general-backcolor"] = "#F1F4F9";
theme.cssVariables["--sjs-primary-backcolor"] = "#F1F4F9";

css.body = "p-0";
css.page = "p-0";
css.panel.title = "text-sm font-semibold text-gray-800";
css.panel.content = "pt-0";
css.matrixdynamic.headerCell = "text-sm font-medium text-gray-700";
css.matrixdynamic.columnTitleCell = "!text-left";
css.matrixdynamic.compact =
  "sd-element--with-frame sd-element--compact !border-none !pl-0 !pb-0";

// Add interface for state
interface DialogState {
  isOpen: boolean;
  value: any; // or proper type based on what value can be
}

export class SurveyQuestionDialog extends SurveyQuestionElementBase {
  state: DialogState = {
    isOpen: false,
    value: this.question.value,
  };

  componentDidMount() {
    this.initSurvey();
  }

  /**
   * Initializes the inner survey with theme, CSS, and data settings.
   * Sets up value change handlers and configures survey mode based on parent survey state.
   */
  initSurvey() {
    this.innerSurvey.applyTheme(theme);
    this.innerSurvey.css = css;
    switch (this.contentType) {
      case "bom-t1":
        this.innerSurvey.fromJSON(bomExtraInfoSchema);
        break;
    }
    this.innerSurvey.data = this.question.value;
    this.innerSurvey.onValueChanged.add(() => {
      this.question.value = this.innerSurvey.data;
    });

    if (
      this.question.survey.state === "preview" ||
      this.question.survey.state === "display"
    ) {
      this.innerSurvey.mode = "display";
    } else {
      this.innerSurvey.mode = "edit";
    }

    this.innerSurvey.onUpdateQuestionCssClasses.add(
      (sender, { question, cssClasses }) => {
        const contentElement = cssClasses.content;
        const isMaterialCompositionMatrix = contentElement.includes(
          "material-composition-matrix"
        );
        const isMaterialYarnSuppliersMatrix = contentElement.includes(
          "material-yarn-suppliers-matrix"
        );
        if (question.name === "composition" && !isMaterialCompositionMatrix) {
          cssClasses.content += " material-composition-matrix";
        }

        if (
          question.name === "yarnSuppliers" &&
          !isMaterialYarnSuppliersMatrix
        ) {
          cssClasses.content += " material-yarn-suppliers-matrix";
        }
      }
    );
  }

  get productName() {
    return this.question.getPropertyValue("productName");
  }

  get survey() {
    return this.question.survey as SurveyModel;
  }

  get innerSurvey() {
    return this.question.innerSurvey as SurveyModel;
  }

  get contentType() {
    return this.question.contentType;
  }

  get disabled() {
    return this.question.readOnly;
  }

  get dialogTitleExpression() {
    return this.question.dialogTitleExpression;
  }

  get highlightButton() {
    return this.question.highlightButton;
  }

  get dialogTitle() {
    return this.question.dialogTitle;
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  /**
   * Checks if at least one field in the inner survey has been filled
   * @returns {boolean} true if at least one field has a non-empty value
   */
  hasAtLeastOneFieldFilled(): boolean {
    const data = this.innerSurvey.data;
    if (!data) return false;

    const hasFilledFields = Object.values(data).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return (
        value !== undefined && value !== null && value !== "" && value !== 0
      );
    });
    return hasFilledFields;
  }

  get navigationMenu() {
    return (this.question.survey as SurveyModel).getPropertyValue(
      "navigationMenu"
    );
  }

  get style(): React.CSSProperties | undefined {
    return this.question.getPropertyValue("readOnly") ||
      this.question.isDesignMode
      ? { pointerEvents: "none" as const }
      : undefined;
  }

  /**
   * Disables page scrolling when dialog is open.
   * Stores current scroll position and fixes body position to prevent background scrolling.
   */
  disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  /**
   * Restores page scrolling when dialog is closed.
   * Resets body position and restores previous scroll position.
   */
  enableScroll = () => {
    document.body.style.overflow = "";
  };

  /**
   * Toggles the dialog open/closed state.
   * Manages scroll behavior when dialog state changes.
   */
  toggleDialog = () => {
    this.setState((prev: DialogState) => {
      const newIsOpen = !prev.isOpen;
      if (newIsOpen) {
        this.disableScroll();
      } else {
        this.enableScroll();
      }
      return { ...prev, isOpen: newIsOpen };
    });
  };

  componentDidUpdate(prevProps: any, prevState: any): void {
    if (this.state.isOpen) {
      const defaultStore = getDefaultStore();
      const completeTries = defaultStore.get(completeTriesAtom);
      if (completeTries && this.question.hasErrors()) {
        this.innerSurvey.validate(true); // If the question has errors and the dialog is open, validate the inner survey and show the errors
      }
    }
  }

  /**
   * Renders the modal dialog component.
   * @returns {React.ReactPortal | null} Portal containing the dialog UI or null if dialog is closed
   */
  renderDialog() {
    if (!this.state.isOpen) return null;

    return createPortal(
      <div className="fixed inset-0 z-50 survey-dialog-container">
        {/* Backdrop */}
        <div
          className="fixed inset-0 bg-black/30"
          onClick={this.toggleDialog}
        />

        {/* Dialog */}
        <div
          className={`fixed p-0 w-[70%] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-indigo-25 rounded-lg shadow-xl ${
            this.navigationMenu === "sidebar" && this.survey.state !== "preview"
              ? "ml-[133px]"
              : ""
          }`}
        >
          {/* Header */}
          <div className="flex items-center justify-between p-5 border-b border-b-indigo-300">
            <div className="flex items-center text-lg font-regular text-slate-500">
              {this.dialogTitle}
            </div>
            <button
              className="text-indigo-600 outline-none appearance-none"
              onClick={this.toggleDialog}
            >
              <X size={26} />
              <span className="sr-only">
                <T keyName="button-close" />
              </span>
            </button>
          </div>

          {/* Content */}
          <div className="p-5 max-h-[calc(100vh-200px)] overflow-y-auto">
            <div className="survey-wrapper">
              <Survey model={this.question.innerSurvey} />
            </div>
          </div>

          {/* Footer */}
          <div className="flex justify-end p-5 border-t border-t-indigo-300">
            <button
              className="appearance-none w-[85px] py-2 px-3.5 rounded-lg bg-indigo-500 text-white text-sm font-semibold"
              onClick={this.toggleDialog}
            >
              <T keyName="button-ok" />
            </button>
          </div>
        </div>
      </div>,
      document.body
    );
  }

  /**
   * Renders the button that triggers the dialog.
   * @returns {JSX.Element} Edit button component with appropriate styling and disabled state
   */
  renderTrigger() {
    return (
      <button
        disabled={this.disabled}
        onClick={this.toggleDialog}
        className={cn(
          "outline-none appearance-none select-none rounded-lg bg-indigo-500 disabled:bg-indigo-200 disabled:cursor-not-allowed text-white py-2 px-[14px]",
          {
            "border-[3px] border-success-300":
              !this.disabled && !this.hasAtLeastOneFieldFilled(),
          }
        )}
      >
        <Edit size={20} />
      </button>
    );
  }

  /**
   * Renders the complete dialog question component.
   * @returns {JSX.Element} Container with trigger button and dialog
   */
  renderElement() {
    return (
      <div style={this.style}>
        {this.renderTrigger()}
        {this.renderDialog()}
      </div>
    );
  }
}

ReactQuestionFactory.Instance.registerQuestion(DIALOG_QUESTION_TYPE, (props) =>
  createElement(SurveyQuestionDialog, props)
);
