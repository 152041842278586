import { FC, createElement, useEffect, useState } from "react";
import { Question, Serializer } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { useTolgee } from "@tolgee/react";

const FABRIC_COMPOSITION_QUESTION_TYPE = "fabriccomposition";
const FABRIC_COMPOSITION_PERCENTAGE_KEY =
  "pq-bop-t2-material-composition-percentage-fibre-percentage";
const FABRIC_COMPOSITION_MATERIAL_KEY =
  "pq-bop-t2-material-composition-percentage-fibre-composition";
const FABRIC_COMPOSITION_URL =
  "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/pq_bop_t2_material_composition_percentage_fiber_composition";

// Define the custom question type
export class FabricCompositionQuestion extends Question {
  public materialsComposition: string = "";
  public percentageKey: string = FABRIC_COMPOSITION_PERCENTAGE_KEY;
  public materialKey: string = FABRIC_COMPOSITION_MATERIAL_KEY;

  public getType(): string {
    return FABRIC_COMPOSITION_QUESTION_TYPE;
  }
}

// Register the question type and its properties
Serializer.addClass(
  FABRIC_COMPOSITION_QUESTION_TYPE,
  [
    {
      name: "materialsComposition:materialscomposition",
      default: "",
    },
    {
      name: "percentageKey",
      default: FABRIC_COMPOSITION_PERCENTAGE_KEY,
    },
    {
      name: "materialKey",
      default: FABRIC_COMPOSITION_MATERIAL_KEY,
    },
    {
      name: "materialCompositionUrl",
      default: "",
    },
    {
      name: "coverImageSrc",
      default: "",
    },
    {
      name: "explanation",
      default: "",
    },
  ],
  function () {
    return new FabricCompositionQuestion("");
  },
  "question"
);

interface FabricCompositionQuestionProps {
  question: FabricCompositionQuestion;
}

interface Material {
  value: string;
  text: string;
}

const FabricCompositionComponent: FC<FabricCompositionQuestionProps> = ({
  question,
}) => {
  const { t } = useTolgee();
  const [materialTitles, setMaterialTitles] = useState<Record<string, string>>(
    {}
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    /**
     * Fetch the materials from the API and set the material titles
     * @param {AbortSignal} signal - The signal to abort the fetch
     */
    const fetchMaterials = async (signal: AbortSignal) => {
      try {
        const url = question.materialCompositionUrl || FABRIC_COMPOSITION_URL;
        const response = await fetch(url, { signal });
        const materials: Material[] = await response.json();

        // Create a mapping of value to title
        const titleMap = materials.reduce(
          (acc, material) => ({
            ...acc,
            [material.value]: material.text,
          }),
          {}
        );

        setMaterialTitles(titleMap);
      } catch (error) {
        if (error instanceof Error && error.name !== "AbortError") {
          console.error("Error fetching material titles:", error);
        }
      }
    };

    fetchMaterials(signal);

    return () => controller.abort();
  }, [question.materialCompositionUrl]);

  /**
   * Format the composition string
   * @param {string} compositionsExpression - The expression to get the compositions from
   * @returns {string} - The formatted composition string
   */
  const formatComposition = (compositionsExpression: string): string => {
    const compositions = question.survey?.runExpression(compositionsExpression);
    const isArray = Array.isArray(compositions) && compositions.length > 0;
    if (isArray === false) {
      return "No materials were selected";
    }

    // Group and sum percentages by material
    const materialSums = compositions.reduce(
      (acc: { [key: string]: number }, curr: any) => {
        // Find keys that contain our target patterns
        const materialKey = Object.keys(curr).find((key) =>
          key.includes(question.materialKey)
        );
        const percentageKey = Object.keys(curr).find((key) =>
          key.includes(question.percentageKey)
        );

        const material = materialKey ? curr[materialKey] : "";
        const percentage = percentageKey ? parseFloat(curr[percentageKey]) : 0;

        const isMaterialValid =
          material !== "" && material !== undefined && material !== "undefined";
        const isPercentageValid = !isNaN(percentage);

        if (isMaterialValid && isPercentageValid) {
          acc[material] = (acc[material] || 0) + percentage;
        }
        return acc;
      },
      {}
    );

    const materialEntries = Object.entries(materialSums);
    if (materialEntries.length === 0) {
      return "No materials were selected";
    }

    const compositionString = materialEntries
      .map(([material, percentage]) => {
        const materialTitle = materialTitles[material] || material;
        const materialTitleLowercase = materialTitle.toLowerCase();

        return `${percentage}% ${materialTitleLowercase}`;
      })
      .join(", ");

    return compositionString;
  };

  return (
    <div className="flex flex-col gap-4 text-sm mt-2">
      <div className="bg-[#F1F4F9] rounded-[10px] max-w-[533px]">
        <h5 className="py-[10px] px-[13px]">{question.explanation}</h5>
        <div className="flex flex-col items-center justify-center h-[301px] text-center">
          {question.coverImageSrc ? (
            <img src={question.coverImageSrc} alt="cover" />
          ) : (
            <p>Example: compositions of different suppliers</p>
          )}
        </div>
      </div>

      <div>{t('fabric-composition-question-title')}: <strong>{formatComposition(question.materialsComposition)}</strong></div>
    </div>
  );
};

// Register the custom question component
ReactQuestionFactory.Instance.registerQuestion(
  FABRIC_COMPOSITION_QUESTION_TYPE,
  (props: any) =>
    createElement(
      FabricCompositionComponent,
      props as FabricCompositionQuestionProps
    )
);
