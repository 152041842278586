import * as Popover from "@radix-ui/react-popover";
import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { Command } from "cmdk";
import { ChevronDown, Search, X } from "../../icons";
import { useBreakpoint } from "../../../hooks/use-breakpoint";
import cn from "../../../utils/cn";
import { useTolgee } from "@tolgee/react";
type ComboboxOtherProps = {
  items?: { value: string; title: string }[];
  placeholder?: string;
  value?: string;
  className?: string;
  ariaLabel?: string;
  ariaRequired?: boolean;
  ariaInvalid?: boolean;
  ariaErrormessage?: string;
  autoComplete?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  dense?: boolean;
  defaultValue?: string;
  onValueChange?: (value: string | undefined) => void;
  isOtherSelected?: boolean;
  commentPlaceHolder?: string;
  allowClear?: boolean;
  hasOther?: boolean;
  hasNone?: boolean;
  otherItem?: { title: string; value: any };
  noneItem?: { title: string; value: any };
  comment?: string;
  onCommentChange?: (comment: string) => void;
};
const PLEASE_SELECT_PLACEHOLDER = "please-select-placeholder";
const PLEASE_SPECIFY_PLACEHOLDER = "please-specify-placeholder";

export const ComboboxOther = ({
  items,
  placeholder,
  value,
  className,
  ariaLabel,
  required,
  ariaInvalid,
  disabled,
  name,
  onValueChange,
  defaultValue,
  allowClear,
  hasOther,
  hasNone,
  otherItem,
  noneItem,
  isOtherSelected,
  commentPlaceHolder,
  comment,
  onCommentChange,
}: ComboboxOtherProps) => {
  const { t } = useTolgee();
  const [search, setSearch] = useState("");
  const [open, isOpen] = useState(false);
  const { isAboveMd } = useBreakpoint("md");
  const [otherValue, setOtherValue] = useState(comment || "");
  const placeholderText =
    (placeholder || t(PLEASE_SELECT_PLACEHOLDER)) + (required ? "*" : "");
  const placeholderTextOther =
    (commentPlaceHolder || t(PLEASE_SPECIFY_PLACEHOLDER)) + (required ? "*" : "");
  const comboboxValue = value || defaultValue || undefined;
  const listRef = useRef<HTMLDivElement>(null);
  const onSelect = useCallback(
    (value: string) => {
      onValueChange?.(value);
      isOpen(false);
    },
    [onValueChange]
  );
  
  const onCleanSelectedItem = useCallback(() => {
    onValueChange?.("");
    isOpen(false);
  }, [onValueChange]);

  const selectedItem = useMemo(() => {
    return items?.filter((item) => item.value === value)[0];
  }, [items, value]);

  const itemsWithKeys = useMemo(() => {
    const otherOption = {
      value: otherItem?.value || "other",
      title: otherItem?.title || "Other",
      key: "other-option",
    };
    const noneOption = {
      value: noneItem?.value || "none",
      title: noneItem?.title || "None",
      key: "none-option",
    };
    const clearOption = { value: "undefined", title: "", key: "clear-option" };
    const regularItems = items?.map((item, index) => ({
      ...item,
      key: String(item.value) + String(index),
    }));

    const finalItems = [...(regularItems || [])];
    if (hasOther) finalItems.push(otherOption);
    if (hasNone) finalItems.push(noneOption);
    if (allowClear) finalItems.unshift(clearOption);
    return finalItems;
  }, [items, otherItem, noneItem, hasOther, hasNone, allowClear]);

  /**
   * @description Handle the change event of the other input
   * @param event - The change event
   */
  const handleOtherValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const otherValue = event.target.value;
      setOtherValue(otherValue);
      onCommentChange?.(otherValue);
    },
    [onCommentChange]
  );

  const filterItems = useCallback(
    (value: string) => {
      const item = items?.find((item) => item.value === value);
      if (!item) return 0;

      const otherItemValue = otherItem?.value || "other";
      const noneItemValue = noneItem?.value || "none";
      const isOtherValue = item.value === otherItemValue;
      const isNoneValue = item.value === noneItemValue;
      const isClearValue = item.value === "undefined";

      if (isOtherValue || isNoneValue || isClearValue) {
        return 1;
      }

      if (typeof item.title === "string") {
        const itemTitle = item.title.toLowerCase();
        //Fix to solve an issue with search through the Tolgee translations
        const renderedTitle =
          listRef.current
            ?.querySelector(`[data-value="${item.value}"]`)
            ?.textContent?.toLowerCase() || "";
        const searchTerm = search.toLowerCase().trim();

        return itemTitle.includes(searchTerm) ||
          renderedTitle.includes(searchTerm)
          ? 1
          : 0;
      }

      return 1;
    },
    [items, search, otherItem, noneItem]
  );

  return (
    <>
      <Popover.Root open={open} onOpenChange={isOpen}>
        <Popover.Trigger
          className={cn(
            "group disabled:bg-gray-50 inline-flex items-center justify-between " +
              "text-start shadow-xs py-2.5 px-[14px] rounded-lg border border-gray-300 bg-white" +
              " focus:ring-4 ring-indigo-100 focus:border-indigo-500 outline-none",
            { "!text-gray-300": !comboboxValue },
            open && "ring-4 border-indigo-500",
            ariaInvalid && "border-red-500 bg-red-50",
            className
          )}
          disabled={disabled}
        >
          <div className="flex items-center gap-2.5 justify-between w-full">
            <span
              className={`w-full text-xs truncate bg-transparent outline-none appearance-none 
                cursor-pointer focus:text-gray-800 line-clamp-1 md:text-sm font-regular
                overflow-hidden text-ellipsis whitespace-nowrap ${
                  open ? "text-gray-800" : ""
                } ${selectedItem?.title ? "text-gray-500" : "text-gray-300"}`}
              onClick={() => isOpen(true)}
            >
              {selectedItem?.title || placeholderText}
            </span>
            <div className="flex items-center gap-2.5">
              <div
                hidden={disabled || !comboboxValue}
                className="text-gray-500 rounded-[5px] hover:bg-gray-100 cursor-pointer"
                onClick={onCleanSelectedItem}
              >
                <X size={18} />
              </div>
              <ChevronDown
                color={disabled ? "var(--gray-300)" : "var(--gray-500)"}
                size={isAboveMd ? 20 : 18}
                className="group-data-[state=open]:rotate-180"
              />
            </div>
          </div>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            side="top"
            className="z-50 overflow-hidden bg-white border border-gray-100 rounded-lg shadow-lg w-[var(--radix-popover-trigger-width)] max-h-[calc(var(--radix-popover-content-available-height))]"
          >
            <Command filter={filterItems} className="py-1 px-[14px] bg-white">
              <div className="flex justify-between items-center gap-2.5 py-2.5 w-full border-b border-b-gray-300 text-gray-900">
                <Command.Input
                  value={search}
                  onValueChange={setSearch}
                  placeholder={t("combobox-search-placeholder")}
                  autoFocus
                  className="w-full text-xs font-semibold text-gray-900 outline-none md:text-sm"
                />
                <Search size={18} />
              </div>
              <Command.List
                className="overflow-y-auto mx-[-14px] box-border max-h-[200px]"
                ref={listRef}
              >
                {itemsWithKeys?.map((item, index) => (
                  <Command.Item
                    key={item.key + "-" + index}
                    className="py-2.5 px-[14px] text-xs md:text-sm font-medium text-gray-900 text-wrap hover:bg-gray-50 cursor-pointer min-h-[40px]"
                    value={item.value}
                    onSelect={onSelect}
                  >
                    {item.title}
                  </Command.Item>
                ))}
              </Command.List>
            </Command>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      {isOtherSelected && (
        <input
          type="text"
          value={otherValue}
          onChange={handleOtherValueChange}
          placeholder={placeholderTextOther}
          className={classNames(
            "w-full px-3 py-2 text-sm border rounded-md",
            "focus:ring-4 focus:ring-indigo-100 focus:border-indigo-500 outline-none",
            ariaInvalid
              ? "border-red-500 bg-red-500 bg-opacity-10"
              : "border-gray-300 bg-white"
          )}
        />
      )}
    </>
  );
};
