import {
  ChangeEvent,
  FC,
  createElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { QuestionDropdownModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import classNames from "classnames";
import { Combobox } from "../ui/combobox";
import { useTranslate } from "@tolgee/react";
const SPECIFY_OTHER_PLACEHOLDER = "specify-other-placeholder";

interface ComboboxQuestionProps {
  isDisplayMode: boolean;
  question: QuestionDropdownModel;
}

export const ComboboxQuestion: FC<ComboboxQuestionProps> = ({ question }) => {
  const {
    errors,
    value,
    isReadOnly,
    name,
    allowClear,
    isRequired,
    placeholder,
    otherItem,
    noneItem,
    hasOther,
    hasNone,
    isOtherSelected,
    commentPlaceHolder,
    cssClasses,
  } = question;

  const [textAreaInput, setTextAreaInput] = useState(question.comment);
  const { t } = useTranslate();
  const isDisabled = useMemo(() => {
    return isReadOnly;
  }, [isReadOnly]);

  const onValueChange = useCallback(
    (newVal: string) => {
      question.value = newVal;
    },
    [question]
  );

  const onCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaInput(event.target.value);
  };

  const onCommentBlur = () => {
    question.comment = textAreaInput;
  };

  const getItems = useMemo(() => {
    const items = question.dataChoices.map(({ title, value }) => ({
      title,
      value,
    }));
    if (hasNone) items.push(noneItem);
    if (hasOther) items.push(otherItem);
    if (allowClear) items.unshift({ value: undefined, title: "" });
    return items;
  }, [
    question.dataChoices,
    hasNone,
    noneItem,
    hasOther,
    otherItem,
    allowClear,
  ]);

  return (
    <div className="flex flex-col gap-2">
      <Combobox
        hasError={errors?.length > 0}
        cssClasses={cssClasses}
        aria-required={question.ariaRequired}
        aria-label={question.ariaLabel}
        aria-invalid={question.ariaInvalid}
        aria-errormessage={question.ariaErrormessage}
        id={question.inputId}
        items={getItems}
        required={isRequired}
        disabled={isDisabled}
        value={value}
        name={name}
        onValueChange={onValueChange}
        placeholder={placeholder}
      />
      {isOtherSelected && (
        <textarea
          disabled={isDisabled}
          onChange={onCommentChange}
          onBlur={onCommentBlur}
          className={classNames(
            "appearance-none static border-box bg-white w-full py-2 px-[14px] shadow-xs rounded-lg border border-gray-300 focus:text-gray-800 focus:border-indigo-500 focus:ring-4 focus:ring-indigo-100 outline-none disabled:bg-gray-50 disabled:text-gray-500 text-xs md:text-sm leading-[16px] md:leading-5 text-start font-regular text-gray-500 placeholder-gray-300"
          )}
          value={textAreaInput}
          placeholder={commentPlaceHolder || t(SPECIFY_OTHER_PLACEHOLDER)}
        />
      )}
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-combobox-question",
  function (props: any) {
    return createElement(ComboboxQuestion, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "dropdown",
  "combobox-question",
  "sv-combobox-question"
);
