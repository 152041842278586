import React from "react";
import { ElementFactory, Question, Serializer, SurveyError } from "survey-core";
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from "survey-react-ui";
import { ComboboxOther } from "../../ui/combobox-other";
import { useTranslate } from "@tolgee/react";
import { Combobox } from "../../ui/combobox";

const CUSTOM_TYPE = "bop-t2-material-forms";
const LOCATION_API_URL = "https://api-dev.made2flow.com/kernel/location";
const FABRIC_COMPOSITION_URL =
  "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/pq_bop_t2_material_composition_percentage_fiber_composition";
const BLENDED_OPTION_VALUE = "RAW_MATERIAL#BLENDED";
const BLENDED_OPTION_TITLE = "pq-bop-t2-yarn-blended"

interface Material {
  value: string;
  text: string;
}

export class BopT2MaterialFormsModel extends Question {
  private customErrors: Record<string, Record<string, string>> = {};
  private t: any;

  getType() {
    return CUSTOM_TYPE;
  }

  public setTranslation(t: any) {
    this.t = t;
  }

  /**
   * Override the validate method to implement custom validation
   * This will now only run when the form is submitted
   */
  public validate(fireCallback?: boolean, rec?: any): boolean {
    // Only validate if explicitly called (on form submit)
    if (!fireCallback) {
      return true;
    }

    super.validate(fireCallback, rec);

    const oldValue = this.value || {};
    this.customErrors = {};
    let isValid = true;

    // Get number of materials to validate
    const numberOfMaterialsQuestion = this.survey?.getQuestionByName(
      this.numberOfMaterialsQuestionName
    );
    const numberOfMaterials = numberOfMaterialsQuestion?.value || 0;

    // Validate each material form
    for (let i = 0; i < numberOfMaterials; i++) {
      const materialIndex = `material-${i + 1}`;
      const formValues = oldValue[materialIndex] || {};
      this.customErrors[materialIndex] = {};
      let hasError = false;

      // Required field validation
      if (!formValues.materialType?.trim()) {
        this.customErrors[materialIndex].materialType = this.t(
          "pq-bop-t2-required-field"
        );
        hasError = true;
      }

      if (!formValues.facilityName?.trim()) {
        this.customErrors[materialIndex].facilityName = this.t(
          "pq-bop-t2-required-field"
        );
        hasError = true;
      }

      if (!formValues.country?.trim()) {
        this.customErrors[materialIndex].country = this.t(
          "pq-bop-t2-required-field"
        );
        hasError = true;
      }

      if (!formValues.stateProvince?.trim()) {
        this.customErrors[materialIndex].stateProvince = this.t(
          "pq-bop-t2-required-field"
        );
        hasError = true;
      }

      // Add validation for "other" fields
      if (
        formValues.stateProvince === "other" &&
        !formValues.stateProvinceOther?.trim()
      ) {
        this.customErrors[materialIndex].stateProvinceOther = this.t(
          "pq-bop-t2-required-field"
        );
        hasError = true;
      }

      if (formValues.city === "other" && !formValues.cityOther?.trim()) {
        this.customErrors[materialIndex].cityOther = this.t(
          "pq-bop-t2-required-field"
        );
        hasError = true;
      }

      // Add validation that at least one contact method is provided
      if (!formValues.email?.trim() && !formValues.phone?.trim() && !formValues.mobileWechat?.trim()) {
        this.customErrors[materialIndex].email = this.t('pq-bop-t2-contact-required');
        hasError = true;
      }

      // Optional field validation - only validate if they have values
      if (
        formValues.email &&
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)
      ) {
        this.customErrors[materialIndex].email = this.t(
          "pq-bop-t2-invalid-email"
        );
        hasError = true;
      }

      if (hasError && this.errors.length === 0) {
        this.errors.push(new SurveyError(this.t("pq-bop-t2-form-errors")));
        isValid = false;
      }
    }

    return isValid;
  }

  public getCustomError(
    materialIndex: string,
    fieldName: string
  ): string | undefined {
    return this.customErrors[materialIndex]?.[fieldName];
  }

  public hasCustomError(materialIndex: string, fieldName: string): boolean {
    return !!this.getCustomError(materialIndex, fieldName);
  }
}

// Add question type metadata
Serializer.addClass(
  CUSTOM_TYPE,
  [
    {
      name: "numberOfMaterialsQuestionName",
      default: "",
    },
    {
      name: "listOfMaterials",
      default: [],
    },
    {
      name: "materialKey",
      default: "",
    },
    {
      name: "locationApiUrl",
      // Doesn't reconize strings into url format for some reason
      default: "",
    },
    {
      name: "materialCompositionUrl",
      default: "",
    },
  ],
  function () {
    return new BopT2MaterialFormsModel("");
  },
  "question"
);

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new BopT2MaterialFormsModel(name);
});

// Add interface for location data
interface LocationData {
  isoCode: string;
  name: string;
}

// Add interface for form values
interface FormValues {
  materialType: string;
  facilityName: string;
  nameLocalLanguage: string;
  mobileWechat: string;
  country: string;
  stateProvince: string;
  city: string;
  email: string;
  phone: string;
  address: string;
  stateProvinceOther?: string;
  cityOther?: string;
}

// Add interfaces for state management
interface State {
  countries: LocationData[];
  statesByMaterial: Record<string, LocationData[]>;
  citiesByMaterial: Record<string, LocationData[]>;
  formValuesByMaterial: Record<string, FormValues>;
  numberOfMaterials: number;
  materials: Material[];
}

/**
 * @description Wrapper component to handle the translation
 * @param {any} props - The props
 * @returns {JSX.Element} - The wrapper component
 */
function BopT2MaterialFormsWrapper(props: any) {
  const { t } = useTranslate();
  props.question.setTranslation(t);
  return <BopT2MaterialForms {...props} t={t} />;
}

export class BopT2MaterialForms extends SurveyQuestionElementBase {
  private abortController?: AbortController;
  private fetchedStates: Set<string> = new Set();
  private fetchedCities: Set<string> = new Set();

  constructor(props: any) {
    super(props);

    const numberOfMaterialsQuestion = this.question.survey?.getQuestionByName(
      this.question.numberOfMaterialsQuestionName
    );

    this.state = {
      countries: [],
      statesByMaterial: {},
      citiesByMaterial: {},
      formValuesByMaterial: {},
      numberOfMaterials: numberOfMaterialsQuestion?.value || 0,
      materials: [],
    } as State & { materials: Material[] };
  }

  /**
   * @description Handle input change
   * @param materialIndex - The index of the material form
   * @param name - The name of the field
   * @param value - The value of the field
   */
  handleInputChange = (materialIndex: string, name: string, value: string) => {
    const state = this.state;
    const question = this.question;
    const stateFormValues = state.formValuesByMaterial[materialIndex] || {};
    const formValuesByMaterial = {
      ...state.formValuesByMaterial,
      [materialIndex]: {
        ...stateFormValues,
        [name]: value,
      },
    };

    // Update selected country/state when those fields change
    if (name === "country") {
      formValuesByMaterial[materialIndex].country = value;
      formValuesByMaterial[materialIndex].stateProvince = "";
      formValuesByMaterial[materialIndex].city = "";
      formValuesByMaterial[materialIndex].stateProvince = "";
      this.fetchStates(materialIndex, value);
    }

    if (name === "stateProvince") {
      formValuesByMaterial[materialIndex].stateProvince = value;
      // Clear the other value if a regular option is selected
      if (value !== "other") {
        formValuesByMaterial[materialIndex].stateProvinceOther = "";
      }
      formValuesByMaterial[materialIndex].city = "";
      this.fetchCities(
        materialIndex,
        formValuesByMaterial[materialIndex].country || "",
        value
      );
    }

    if (name === "city") {
      formValuesByMaterial[materialIndex].city = value;
      // Clear the other value if a regular option is selected
      if (value !== "other") {
        formValuesByMaterial[materialIndex].cityOther = "";
      }
    }

    this.setState({ formValuesByMaterial }, () => {
      question.value = formValuesByMaterial;
    });
  };

  /**
   * @description Add a new handler for the other field
   * @param materialIndex - The index of the material form
   * @param value - The value of the other field
   */
  handleStateProvinceOther = (materialIndex: string, value: string) => {
    const state = this.state;
    const question = this.question;
    const stateFormValues = state.formValuesByMaterial[materialIndex] || {};

    const formValuesByMaterial = {
      ...state.formValuesByMaterial,
      [materialIndex]: {
        ...stateFormValues,
        stateProvinceOther: value,
        stateProvince: "other",
      },
    };

    this.setState({ formValuesByMaterial }, () => {
      question.value = formValuesByMaterial;
    });
  };

  /**
   * @description Add a new handler for the other field
   * @param materialIndex - The index of the material form
   * @param value - The value of the other field
   */
  handleCityOther = (materialIndex: string, value: string) => {
    const state = this.state;
    const question = this.question;
    const stateFormValues = state.formValuesByMaterial[materialIndex] || {};

    const formValuesByMaterial = {
      ...state.formValuesByMaterial,
      [materialIndex]: {
        ...stateFormValues,
        cityOther: value,
        city: "other",
      },
    };

    this.setState({ formValuesByMaterial }, () => {
      question.value = formValuesByMaterial;
    });
  };

  /**
   * @description Fetch states from the location API
   * @param materialIndex - The index of the material form
   * @param countryCode - The ISO code of the selected country (e.g., 'US', 'CN')
   * @returns void
   */
  fetchStates = (materialIndex: string, countryCode: string) => {
    if (!countryCode) return;
    const fetchKey = `${materialIndex}-${countryCode}`;
    if (this.fetchedStates.has(fetchKey)) return;

    this.fetchedStates.add(fetchKey);
    const apiUrl = this.question.locationApiUrl || LOCATION_API_URL;
    const url = `${apiUrl}?countryCode=${countryCode}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) =>
        this.setState((prevState: any) => ({
          statesByMaterial: {
            ...prevState.statesByMaterial,
            [materialIndex]: data,
          },
        }))
      );
  };

  /**
   * @description Fetch cities from the location API
   * @param materialIndex - The index of the material form
   * @param countryCode - The ISO code of the selected country (e.g., 'US', 'CN')
   * @param stateCode - The ISO code of the selected state (e.g., 'CA', 'NY')
   * @returns void
   */
  fetchCities = (
    materialIndex: string,
    countryCode: string,
    stateCode: string
  ) => {
    if (!countryCode || !stateCode) return;
    const fetchKey = `${materialIndex}-${countryCode}-${stateCode}`;
    if (this.fetchedCities.has(fetchKey)) return;

    this.fetchedCities.add(fetchKey);
    const apiUrl = this.question.locationApiUrl || LOCATION_API_URL;
    const url = `${apiUrl}?countryCode=${countryCode}&stateCode=${stateCode}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) =>
        this.setState((prevState: any) => ({
          citiesByMaterial: {
            ...prevState.citiesByMaterial,
            [materialIndex]: data,
          },
        }))
      );
  };

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  /**
   * @description Initialize form values for each material
   * @param questionValue - The existing question value
   * @param numberOfMaterials - The number of materials to initialize
   * @returns {Record<string, FormValues>} - The initialized form values
   */
  public getFormValues(
    questionValue: Record<string, FormValues>,
    numberOfMaterials: number
  ): Record<string, FormValues> {
    const initialFormValues: Record<string, FormValues> = {};
    for (let i = 0; i < numberOfMaterials; i++) {
      const materialIndex = `material-${i + 1}`;
      initialFormValues[materialIndex] = {
        ...questionValue[materialIndex], // Spread existing values first
        // Then set defaults for any missing values
        materialType: questionValue[materialIndex]?.materialType || "",
        facilityName: questionValue[materialIndex]?.facilityName || "",
        nameLocalLanguage:
          questionValue[materialIndex]?.nameLocalLanguage || "",
        mobileWechat: questionValue[materialIndex]?.mobileWechat || "",
        country: questionValue[materialIndex]?.country || "",
        stateProvince: questionValue[materialIndex]?.stateProvince || "",
        city: questionValue[materialIndex]?.city || "",
        email: questionValue[materialIndex]?.email || "",
        phone: questionValue[materialIndex]?.phone || "",
        address: questionValue[materialIndex]?.address || "",
      };
    }
    return initialFormValues;
  }

  /**
   * @description Set initial state
   */
  componentDidMount() {
    const controller = new AbortController();
    const apiUrl = this.question.locationApiUrl || LOCATION_API_URL;
    const questionValue = this.question.value || {};

    this.setState({
      formValuesByMaterial: this.getFormValues(
        questionValue,
        this.state.numberOfMaterials
      ),
    });

    fetch(apiUrl, { signal: controller.signal })
      .then((res) => res.json())
      .then((data) => this.setState({ countries: data }))
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error("Error fetching countries:", error);
        }
      });

    const materialUrl =
      this.question.materialCompositionUrl || FABRIC_COMPOSITION_URL;
    fetch(materialUrl, { signal: controller.signal })
      .then((res) => res.json())
      .then((materials) => this.setState({ materials }))
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error("Error fetching materials:", error);
        }
      });

    // Add subscription to the referenced question
    const numberOfMaterialsQuestion = this.question.survey?.getQuestionByName(
      this.question.numberOfMaterialsQuestionName
    );

    if (numberOfMaterialsQuestion) {
      numberOfMaterialsQuestion.registerFunctionOnPropertyValueChanged(
        "value",
        () => {
          const numberOfMaterials = numberOfMaterialsQuestion.value || 0;
          this.setState({
            numberOfMaterials: numberOfMaterials,
          });
          this.setState({
            formValuesByMaterial: this.getFormValues(
              questionValue,
              numberOfMaterials
            ),
          });
        },
        "numberOfMaterialsSubscription"
      );
    }

    this.abortController = controller;
  }

  /**
   * @description Clean up subscription
   */
  componentWillUnmount() {
    // Clean up subscription
    const numberOfMaterialsQuestion = this.question.survey?.getQuestionByName(
      this.question.numberOfMaterialsQuestionName
    );

    if (this.abortController) {
      this.abortController.abort();
    }

    if (numberOfMaterialsQuestion) {
      numberOfMaterialsQuestion.unRegisterFunctionOnPropertyValueChanged(
        "value",
        "numberOfMaterialsSubscription"
      );
    }
  }

  /**
   * @description Returns unique materials from the composition list with their titles
   * @param {any[]} materials - The materials from the composition list
   * @returns {{ value: string | undefined; title: string }[]} - The unique materials formatted for dropdown
   */
  getUniqueMaterials = (materials: any[]) => {
    const uniqueMaterialValues = Array.from(
      new Set(materials.map((material) => material[this.question.materialKey]))
    ).filter((material) => material !== "" && material !== undefined);
    const { t } = this.props;

    const hasNoMaterials = uniqueMaterialValues.length === 0;
    if (hasNoMaterials) {
      return [];
    }

    const materialsList = Array.isArray(this.state.materials)
      ? this.state.materials
      : [];

    const materialTitles = materialsList.reduce(
      (acc: Record<string, string>, material: Material) => ({
        ...acc,
        [material.value]: material.text,
      }),
      {} as Record<string, string>
    );

    const formattedMaterials = uniqueMaterialValues.map((materialValue) => ({
      value: materialValue as string,
      title:
        materialTitles[materialValue as string] || (materialValue as string),
    }));

    formattedMaterials.unshift({ value: "", title: "" });
    formattedMaterials.push({
      value: BLENDED_OPTION_VALUE,
      title: t(BLENDED_OPTION_TITLE),
    });

    return formattedMaterials;
  };

  /**
   * @description Render the element
   * @returns {JSX.Element} - The element
   */
  renderElement() {
    const { numberOfMaterials } = this.state;

    return (
      <div className="flex flex-col space-y-4">
        {Array.from({ length: numberOfMaterials }, (_, index) => {
          const materialIndex = `material-${index + 1}`;
          const isLastForm = index === numberOfMaterials - 1;
          return (
            <div
              key={materialIndex}
              className={`material-section ${
                !isLastForm ? "pb-8 border-b border-gray-50" : ""
              }`}
            >
              {this.renderFormGrid(materialIndex)}
            </div>
          );
        })}
      </div>
    );
  }

  /**
   * @description Render error message
   * @param materialIndex - The index of the material form
   * @param fieldName - The name of the field
   * @returns {JSX.Element} - The error message
   */
  renderError(materialIndex: string, fieldName: string) {
    const error = (this.question as BopT2MaterialFormsModel).getCustomError(
      materialIndex,
      fieldName
    );

    if (!error) return null;

    return <div className="text-red-500 text-xs mt-1">{error}</div>;
  }

  /**
   * @description Get error state for field
   * @param materialIndex - The index of the material form
   * @param fieldName - The name of the field
   * @returns {boolean} - The error state
   */
  hasError(materialIndex: string, fieldName: string): boolean {
    return (this.question as BopT2MaterialFormsModel).hasCustomError(
      materialIndex,
      fieldName
    );
  }

  /**
   * @description Render the form grid
   * @param materialIndex - The index of the material form
   * @returns {JSX.Element} - The form grid
   */
  renderFormGrid(materialIndex: string) {
    const state = this.state;
    const { countries } = state;
    const question = this.question;
    const formValues = state.formValuesByMaterial[materialIndex] || {};
    const states = state.statesByMaterial[materialIndex] || [];
    const cities = state.citiesByMaterial[materialIndex] || [];
    const { t } = this.props;
    const listOfMaterialsExpression = question.listOfMaterials;
    const listOfMaterials =
      question.survey?.runExpression(listOfMaterialsExpression) || [];
    const materials = this.getUniqueMaterials(listOfMaterials);
    const materialIsDisabled = materials.length === 0;
    const statesIsDisabled = !formValues.country;
    const citiesIsDisabled = !formValues.country;
    const areNotStatesFetched =
      formValues.country !== undefined && states.length === 0;
    const areNotCitiesFetched =
      formValues.country !== undefined &&
      formValues.stateProvince !== undefined &&
      cities.length === 0;

    if (areNotStatesFetched) {
      this.fetchStates(materialIndex, formValues.country);
    }

    if (areNotCitiesFetched) {
      this.fetchCities(
        materialIndex,
        formValues.country,
        formValues.stateProvince
      );
    }

    const inputClassName = (
      hasError: boolean
    ) => `sd-text appearance-none static border-box  
      disabled:bg-gray-50 disabled:text-gray-500 w-full py-2.5 px-[14px] 
      shadow-xs rounded-lg border ${
        hasError
          ? "border-red-500 bg-red-500 bg-opacity-10"
          : "border-gray-300 bg-white"
      } text-xs md:text-sm leading-[16px] 
      md:leading-5 text-start font-regular text-gray-500 focus:border-indigo-500 
      focus:text-gray-800 focus:ring-4 focus:ring-indigo-100 
      outline-none placeholder-gray-300`;

    return (
      <div>
        <div className="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <div className="text-sm mb-4">
              {t("pq-bop-t2-yarn-traceability-material-title")}
            </div>

            <Combobox
              className={`w-full ${
                this.hasError(materialIndex, "materialType")
                  ? "border-red-500 bg-red-500 bg-opacity-10"
                  : ""
              }`}
              name={`pq-bop-t2-yarn-traceability-${materialIndex}-material-type`}
              placeholder={t("pq-bop-t2-material-type-placeholder") + " *"}
              items={materials}
              required={true}
              value={formValues.materialType}
              onValueChange={(value) => {
                return this.handleInputChange(
                  materialIndex,
                  "materialType",
                  value || ""
                );
              }}
              allowClear={true}
              disabled={materialIsDisabled || question.isReadOnly}
            />
            {this.renderError(materialIndex, "materialType")}
          </div>
        </div>

        <div className="mb-4">
          <div className="text-sm">
            {t("pq-bop-t2-yarn-traceability-supplier-information-name-title")}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Row 1 */}
          <div>
            <input
              name={`pq-bop-t2-yarn-traceability-${materialIndex}-supplier-information-name-facility-name`}
              type="text"
              required={true}
              value={formValues.facilityName}
              onChange={(e) =>
                this.handleInputChange(
                  materialIndex,
                  "facilityName",
                  e.target.value
                )
              }
              className={inputClassName(
                this.hasError(materialIndex, "facilityName")
              )}
              placeholder={
                t(
                  "pq-bop-t2-supplier-information-process-facility-name-placeholder"
                ) + " *"
              }
              disabled={materialIsDisabled || question.isReadOnly}
            />
            {this.renderError(materialIndex, "facilityName")}
          </div>

          <div>
            <input
              name={`pq-bop-t2-yarn-traceability-${materialIndex}-supplier-information-name-local-language`}
              type="text"
              value={formValues.nameLocalLanguage}
              onChange={(e) =>
                this.handleInputChange(
                  materialIndex,
                  "nameLocalLanguage",
                  e.target.value
                )
              }
              className={inputClassName(false)}
              placeholder={t(
                "pq-bop-t2-supplier-information-process-name-local-language-placeholder"
              )}
              disabled={materialIsDisabled || question.isReadOnly}
            />
          </div>

          <div>
            <input
              name={`pq-bop-t2-yarn-traceability-${materialIndex}-supplier-information-name-address`}
              type="text"
              value={formValues.address}
              onChange={(e) =>
                this.handleInputChange(materialIndex, "address", e.target.value)
              }
              className={inputClassName(false)}
              placeholder={t(
                "pq-bop-t2-supplier-information-process-address-placeholder"
              )}
              disabled={materialIsDisabled || question.isReadOnly}
            />
          </div>

          {/* Row 2 */}
          <div className="flex flex-col gap-2">
            <ComboboxOther
              name={`pq-bop-t2-yarn-traceability-${materialIndex}-supplier-information-name-country`}
              placeholder={t(
                "pq-bop-t2-supplier-information-process-country-placeholder"
              )}
              items={countries.map((country: LocationData) => ({
                value: country.isoCode,
                title: country.name,
              }))}
              required={true}
              value={formValues.country}
              onValueChange={(value) =>
                this.handleInputChange(materialIndex, "country", value || "")
              }
              allowClear={false}
              disabled={materialIsDisabled || question.isReadOnly}
              ariaInvalid={this.hasError(materialIndex, "country")}
            />
            {this.renderError(materialIndex, "country")}
          </div>

          <div className="flex flex-col gap-2">
            <ComboboxOther
              name={`pq-bop-t2-yarn-traceability-${materialIndex}-supplier-information-name-state-province`}
              placeholder={t(
                "pq-bop-t2-supplier-information-process-state-province-placeholder"
              )}
              items={states.map((state: LocationData) => ({
                value: state.isoCode,
                title: state.name,
              }))}
              required={true}
              disabled={
                statesIsDisabled || question.isReadOnly || materialIsDisabled
              }
              allowClear={true}
              hasOther={true}
              onCommentChange={(value) =>
                this.handleStateProvinceOther(materialIndex, value || "")
              }
              isOtherSelected={formValues.stateProvince === "other"}
              comment={formValues.stateProvinceOther}
              commentPlaceHolder={t("pq-bop-t2-dropdown-other-placeholder")}
              value={formValues.stateProvince}
              otherItem={{
                value: "other",
                title: t("pq-bop-t2-dropdown-other"),
              }}
              onValueChange={(value) =>
                this.handleInputChange(
                  materialIndex,
                  "stateProvince",
                  value || ""
                )
              }
              ariaInvalid={
                this.hasError(materialIndex, "stateProvince") ||
                this.hasError(materialIndex, "stateProvinceOther")
              }
            />
            {this.renderError(materialIndex, "stateProvince") ||
              this.renderError(materialIndex, "stateProvinceOther")}
          </div>

          <div className="flex flex-col gap-2">
            <ComboboxOther
              name={`pq-bop-t2-yarn-traceability-${materialIndex}-supplier-information-name-city`}
              placeholder={t("pq-bop-t2-supplier-information-process-city-placeholder")}
              items={cities.map((city: LocationData) => ({
                value: city.name,
                title: city.name,
              }))}
              hasOther={true}
              onCommentChange={(value) =>
                this.handleCityOther(materialIndex, value || "")
              }
              isOtherSelected={formValues.city === "other"}
              comment={formValues.cityOther}
              disabled={
                citiesIsDisabled || question.isReadOnly || materialIsDisabled
              }
              value={formValues.city}
              allowClear={true}
              otherItem={{
                value: "other",
                title: t("pq-bop-t2-dropdown-other"),
              }}
              commentPlaceHolder={t("pq-bop-t2-dropdown-other-placeholder")}
              onValueChange={(value) =>
                this.handleInputChange(materialIndex, "city", value || "")
              }
              ariaInvalid={this.hasError(materialIndex, "cityOther")}
            />
            {this.renderError(materialIndex, "cityOther")}
          </div>

          {/* Row 3 */}
          <div>
            <input
              name={`pq-bop-t2-yarn-traceability-${materialIndex}-supplier-information-name-email`}
              type="email"
              value={formValues.email}
              onChange={(e) =>
                this.handleInputChange(materialIndex, "email", e.target.value)
              }
              className={inputClassName(this.hasError(materialIndex, "email"))}
              placeholder={t(
                "pq-bop-t2-supplier-information-email-placeholder"
              )}
              disabled={materialIsDisabled || question.isReadOnly}
            />
            {this.renderError(materialIndex, "email")}
          </div>

          <div>
            <input
              name={`pq-bop-t2-yarn-traceability-${materialIndex}-supplier-information-name-phone`}
              type="tel"
              value={formValues.phone}
              onChange={(e) =>
                this.handleInputChange(materialIndex, "phone", e.target.value)
              }
              className={inputClassName(this.hasError(materialIndex, "phone"))}
              placeholder={t(
                "pq-bop-t2-supplier-information-process-phone-placeholder"
              )}
              disabled={materialIsDisabled || question.isReadOnly}
            />
            {this.renderError(materialIndex, "phone")}
          </div>

          <div>
            <input
              name={`pq-bop-t2-yarn-traceability-${materialIndex}-supplier-information-name-mobile-wechat`}
              type="text"
              value={formValues.mobileWechat}
              onChange={(e) =>
                this.handleInputChange(
                  materialIndex,
                  "mobileWechat",
                  e.target.value
                )
              }
              className={inputClassName(false)}
              placeholder={t(
                "pq-bop-t2-supplier-information-process-mobile-wechat-placeholder"
              )}
              disabled={materialIsDisabled || question.isReadOnly}
            />
          </div>
        </div>
      </div>
    );
  }
}

// Register the question type
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return React.createElement(BopT2MaterialFormsWrapper, props);
});
