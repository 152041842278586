import { ElementFactory, Question, Serializer, SurveyModel } from "survey-core";

export const DIALOG_QUESTION_TYPE = "dialog";

export class QuestionDialogModel extends Question {
  private _innerSurvey: SurveyModel;
  constructor(name: string) {
    super(name);
    this._innerSurvey = new SurveyModel();
    this.addExpressionProperty("dialogTitleExpression", (obj: any, res) => {
      if (res) {
        obj.dialogTitle = res.toString();
      }
    });
    this.addExpressionProperty("highlightButton", (obj: any, res) => {
      obj.highlightButton = res;
    });
  }

  getType() {
    return DIALOG_QUESTION_TYPE;
  }

  get tsPrefix() {
    return this.getPropertyValue("tsPrefix");
  }

  set tsPrefix(tsPrefix: string) {
    this.setPropertyValue("tsPrefix", tsPrefix);
  }

  get dialogTitle() {
    return this.getPropertyValue("dialogTitle");
  }

  get innerSurvey() {
    return this._innerSurvey;
  }

  set innerSurvey(innerSurvey: SurveyModel) {
    this._innerSurvey = innerSurvey;
  }

  set dialogTitle(dialogTitle: string) {
    this.setPropertyValue("dialogTitle", dialogTitle);
  }

  get dialogTitleExpression() {
    return this.getPropertyValue("dialogTitleExpression");
  }

  set dialogTitleExpression(dialogTitleExpression: string) {
    this.setPropertyValue("dialogTitleExpression", dialogTitleExpression);
  }

  get highlightButton() {
    return this.getPropertyValue("highlightButton");
  }

  set highlightButton(highlightButton: string) {
    this.setPropertyValue("highlightButton", highlightButton);
  }
}

export function registerDialogElement() {
  ElementFactory.Instance.registerElement(DIALOG_QUESTION_TYPE, (name) => {
    return new QuestionDialogModel(name);
  });
}

export function registerDialogSerializer() {
  Serializer.addClass(
    DIALOG_QUESTION_TYPE,
    [
      {
        name: "dialogTitle",
        category: "general",
        visibleIndex: 2,
      },
      {
        name: "dialogTitleExpression",
        type: "expression",
        category: "general",
        visibleIndex: 6,
      },
      {
        name: "highlightButton",
        type: "expression",
        category: "general",
        visibleIndex: 7,
      },
      {
        name: "innerSurvey",
        type: "survey",
        category: "general",
        isSerializable: false,
      },
    ],
    () => new QuestionDialogModel(""),
    "question"
  );
}
