const bomExtraInfoSchema = {
  showQuestionNumbers: false,
  showNavigationButtons: false,
  checkErrorsMode: "onComplete",
  elements: [
    {
      type: "panel",
      name: "material-composition-panel",
      titleLocation: "hidden",
      width: "35%",
      minWidth: "35%",
      elements: [
        {
          type: "dropdown",
          renderAs: "combobox-question",
          name: "materialType",
          title: "%-%tolgee:pq-bom-t1-material-type-question-title%-%",
          allowClear: true,
          isRequired: true,
          choicesByUrl: {
            url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/pq_bop_t2_fabric_material_info_material_type",
            valueName: "value",
            titleName: "text",
          },
        },
        {
          type: "panel",
          name: "material-composition-panel",
          title: "%-%tolgee:pq-bom-t1-material-composition-panel-title%-%",
          elements: [
            {
              type: "matrixdynamic",
              name: "composition", // Amit: DO NOT CHANGE THIS NAME
              titleLocation: "hidden",
              minRowCount: 1,
              addRowText: "%-%tolgee:pq-bom-t1-add-fiber-button%-%",
              columns: [
                {
                  name: "percentage", // Amit: DO NOT CHANGE THIS NAME
                  title: "%-%tolgee:pq-bom-t1-fiber-percentage-title%-%",
                  cellType: "text",
                  inputType: "number",
                  renderAs: "text-percentage",
                  min: 0,
                  max: 100,
                  isRequired: true,
                  placeholder:
                    "%-%tolgee:pq-bom-t1-fiber-percentage-placeholder%-%",
                  width: "50%",
                  minWidth: "50%",
                  requiredErrorText:
                    "%-%tolgee:pq-bom-t1-composition-precentage-required-error-text%-%",
                  validators: [
                    {
                      type: "numeric",
                      minValue: 0.01,
                      maxValue: 100,
                      text: "%-%tolgee:pq-bom-t1-composition-precentage-required-error-text%-%",
                    },
                  ],
                },
                {
                  name: "fibre", // Amit: DO NOT CHANGE THIS NAME
                  cellType: "dropdown",
                  title: "%-%tolgee:pq-bom-t1-fiber-title%-%",
                  renderAs: "combobox-question",
                  isRequired: true,
                  allowClear: true,
                  width: "50%",
                  minWidth: "50%",
                  requiredErrorText:
                    "%-%tolgee:pq-bom-t1-composition-fiber-required-error-text%-%",
                  choicesByUrl: {
                    url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/pq_bop_t2_material_composition_percentage_fiber_composition",
                    valueName: "value",
                    titleName: "text",
                  },
                },
              ],
            },
            {
              type: "html",
              name: "composition-percentage-divider",
              html: "<div style='height: 1px; margin-top:20px; margin-bottom: 20px;' class='w-full bg-gray-50'></div>",
            },
            {
              type: "expression",
              name: "composition-total-percentage-fiber",
              renderAs: "total-percentage-expression",
              title:
                "%-%tolgee:pq-bom-t1-composition-total-percentage-fiber-title%-%",
              expression: "sumInArray({composition}, 'percentage')",
              validators: [
                {
                  type: "expression",
                  text: "%-%tolgee:pq-bom-t1-composition-total-percentage-fiber-validation-message%-%",
                  expression: "sumInArray({composition}, 'percentage') == 100",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "panel",
      name: "material-panel",
      titleLocation: "hidden",
      startWithNewLine: false,
      width: "60%",
      minWidth: "60%",
      elements: [
        {
          type: "panel",
          name: "matrerial-information",
          title: "%-%tolgee:pq-bom-t1-material-information-title%-%",
          elements: [
            {
              type: "text",
              name: "fabricWidth", // Amit: DO NOT CHANGE THIS NAME
              title: "%-%tolgee:pq-bom-t1-fabric-width-title%-%",
              placeholder: "%-%tolgee:pq-bom-t1-fabric-width-placeholder%-%",
              minWidth: "50%",
              maxWidth: "50%",
            },
            {
              type: "dropdown",
              name: "fabricUnit", // Amit: DO NOT CHANGE THIS NAME
              title: "%-%tolgee:pq-bom-t1-fabric-unit-title%-%",
              renderAs: "dropdown-question",
              allowClear: true,
              startWithNewLine: false,
              minWidth: "50%",
              maxWidth: "50%",
              choicesByUrl: {
                url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/pq_bop_t2_fabric_material_info_material_width_unit",
                valueName: "value",
                titleName: "text",
              },
            },
            {
              type: "text",
              name: "fabricWeight", // Amit: DO NOT CHANGE THIS NAME
              title: "%-%tolgee:pq-bom-t1-fabric-weight-title%-%",
              placeholder: "%-%tolgee:pq-bom-t1-fabric-weight-placeholder%-%",
              minWidth: "50%",
              maxWidth: "50%",
            },
            {
              type: "dropdown",
              name: "fabricWeightUnit", // Amit: DO NOT CHANGE THIS NAME
              title: "%-%tolgee:pq-bom-t1-weight-unit-title%-%",
              renderAs: "dropdown-question",
              allowClear: true,
              startWithNewLine: false,
              minWidth: "50%",
              maxWidth: "50%",
              choicesByUrl: {
                url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/pq_bop_t2_fabric_material_info_material_weight_unit",
                valueName: "value",
                titleName: "text",
              },
            },
          ],
        },
        {
          type: "panel",
          name: "material-usage-panel",
          title: "%-%tolgee:pq-bom-t1-material-usage-title%-%",
          startWithNewLine: false,
          elements: [
            {
              type: "text",
              name: "fabricUsage", // Amit: DO NOT CHANGE THIS NAME
              title: "%-%tolgee:pq-bom-t1-fabric-usage-title%-%",
              placeholder: "%-%tolgee:pq-bom-t1-fabric-usage-placeholder%-%",
              isRequired: true,
              minWidth: "50%",
              maxWidth: "50%",
            },
            {
              type: "dropdown",
              name: "fabricUsageUnit", // Amit: DO NOT CHANGE THIS NAME
              title: "%-%tolgee:pq-bom-t1-fabric-usage-unit-title%-%",
              renderAs: "dropdown-question",
              isRequired: true,
              allowClear: true,
              startWithNewLine: false,
              minWidth: "50%",
              maxWidth: "50%",
              choicesByUrl: {
                url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_material_usage_units",
                valueName: "value",
                titleName: "text",
              },
            },
            {
              type: "text",
              name: "fabricWaste", // Amit: DO NOT CHANGE THIS NAME
              title: "%-%tolgee:pq-bom-t1-fabric-waste-title%-%",
              placeholder: "%-%tolgee:pq-bom-t1-fabric-waste-placeholder%-%",
              isRequired: true,
              minWidth: "50%",
              maxWidth: "50%",
            },
            {
              type: "dropdown",
              name: "fabricWasteUnit", // Amit: DO NOT CHANGE THIS NAME
              title: "%-%tolgee:pq-bom-t1-fabric-waste-unit-title%-%",
              renderAs: "dropdown-question",
              isRequired: true,
              allowClear: true,
              startWithNewLine: false,
              minWidth: "50%",
              maxWidth: "50%",
              choicesByUrl: {
                url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_waste_unit",
                valueName: "value",
                titleName: "text",
              },
            },
          ],
        },
        {
          type: "html",
          name: "material-ref-explanation",
          html: "<div><div class='w-full h-px bg-gray-50'/></div>",
        },
        {
          type: "panel",
          name: "material-traceability-panel",
          title: "%-%tolgee:pq-bom-t1-material-traceability-title%-%",
          elements: [
            {
              type: "dropdown",
              name: "fabricSupplierName", // Amit: DO NOT CHANGE THIS NAME
              title: "%-%tolgee:pq-bom-t1-fabrics-supplier-title%-%",
              placeholder: "%-%tolgee:pq-bom-t1-type-supplier-name%-%",
              renderAs: "suppliers-combobox-question",
              descriptionLocation: "underInput",
              description:
                "%-%tolgee:pq-bom-t1-material-ref-number-description%-%",
              minWidth: "70%",
              maxWidth: "70%",
              allowClear: true,
              isRequired: true,
            },
            {
              type: "text",
              name: "materialRefNumber", // Amit: DO NOT CHANGE THIS NAME
              title: "%-%tolgee:pq-bom-t1-material-ref-number-title%-%",
              startWithNewLine: false,
              minWidth: "30%",
              maxWidth: "30%",
            },
            {
              type: "matrixdynamic",
              name: "yarnSuppliers", // Amit: DO NOT CHANGE THIS NAME
              titleLocation: "hidden",
              maxRowCount: 10,
              minRowCount: 1,
              rowCount: 1,
              addRowText: "%-%tolgee:pq-bom-t1-add-yarn-supplier-button%-%",
              columns: [
                {
                  name: "name", // Amit: DO NOT CHANGE THIS NAME
                  cellType: "dropdown",
                  title:
                    "%-%tolgee:pq-bom-t1-material-traceability-yarn-supplier-name-title%-%",
                  placeholder: "%-%tolgee:pq-bom-t1-type-supplier-name%-%",
                  renderAs: "suppliers-combobox-question",
                  width: "70%",
                  allowClear: true,
                },
                {
                  name: "composition", // Amit: DO NOT CHANGE THIS NAME
                  cellType: "materials-traceability-yarn-composition-question",
                  title:
                    "%-%tolgee:pq-bom-t1-material-traceability-yarn-composition-title%-%",
                  requiredIf: "{row.name} notempty",
                  allowClear: true,
                  width: "30%",
                  materialsComposition: "composition",
                  materialCompositionUrl:
                    "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/pq_bop_t2_material_composition_percentage_fiber_composition",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export { bomExtraInfoSchema };
