/**
 * This component is a custom question type that renders contractor information for a specific process.
 * 
 * Form was created to reduce rendering time of Survey.js for a number of such forms.
 *
 * */

import React from "react";
import { ElementFactory, Question, Serializer, SurveyError } from "survey-core";
import { SurveyQuestionElementBase, ReactQuestionFactory } from "survey-react-ui";
import { ComboboxOther } from "../../ui/combobox-other";
import { T, useTranslate } from "@tolgee/react";
import * as Icon from "../../icons";

const CONTRACTOR_FORM_TYPE = "bop-t2-contractor-form";
const LOCATION_API_URL = "https://api-dev.made2flow.com/kernel/location";

export class BopT2ContractorFormModel extends Question {
  private customErrors: Record<string, Record<string, string>> = {};
  private t: any;

  getType() {
    return CONTRACTOR_FORM_TYPE;
  }

  public setTranslation(t: any) {
    this.t = t;
  }

  /**
   * Override the validate method to implement custom validation
   */
  public validate(fireCallback?: boolean, rec?: any): boolean {
    // Only validate if explicitly called (on form submit)
    if (!fireCallback) {
      return true;
    }
    

    super.validate(fireCallback, rec);

    const oldValue = this.value || {};
    this.customErrors = {};
    let isValid = true;

    // Get active processes to validate
    const activeProcesses = (this.listOfForms || []).filter((form: any) => {
      try {
        return this.survey ? this.survey.runExpression(form.isVisible) : false;
      } catch (event) {
        return false;
      }
    });

    // Validate each active process
    activeProcesses.forEach((process: any) => {
      const processName = process.processName;
      const formValues = oldValue[processName] || {};
      this.customErrors[processName] = {};
      let hasError = false;

      // Required field validation
      if (!formValues.facilityName?.trim()) {
        this.customErrors[processName].facilityName = this.t('pq-bop-t2-required-field');
        hasError = true;
      }

      if (!formValues.country?.trim()) {
        this.customErrors[processName].country = this.t('pq-bop-t2-required-field');
        hasError = true;
      }

      if (!formValues.stateProvince?.trim()) {
        this.customErrors[processName].stateProvince = this.t('pq-bop-t2-required-field');
        hasError = true;
      }

      // Add validation for "other" fields
      if (formValues.stateProvince === 'other' && !formValues.stateProvinceOther?.trim()) {
        this.customErrors[processName].stateProvinceOther = this.t('pq-bop-t2-required-field');
        hasError = true;
      }

      if (formValues.city === 'other' && !formValues.cityOther?.trim()) {
        this.customErrors[processName].cityOther = this.t('pq-bop-t2-required-field');
        hasError = true;
      }

       // Validate at least one contact method is provided
       if (!formValues.email?.trim() && !formValues.phone?.trim() && !formValues.mobileWechat?.trim()) {
        this.customErrors[processName].email = this.t('pq-bop-t2-contact-required');
        hasError = true;
      }

      // Optional field validation - only validate if they have values
      if (formValues.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
        this.customErrors[processName].email = this.t('pq-bop-t2-invalid-email');
        hasError = true;
      }

      // Only push one error if any validation failed
      if (hasError && this.errors.length === 0) {
        this.errors.push(new SurveyError(this.t('pq-bop-t2-form-errors')));
        isValid = false;
      }
    });

    return isValid;
  }

  public getCustomError(processName: string, fieldName: string): string | undefined {
    return this.customErrors[processName]?.[fieldName];
  }

  public hasCustomError(processName: string, fieldName: string): boolean {
    return !!this.getCustomError(processName, fieldName);
  }
}

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CONTRACTOR_FORM_TYPE,
  [
    {
      name: "listOfForms",
      default: [],
    },
    {
      name: "locationApiUrl",
      // Doesn't reconize strings into url format for some reason
      default: "",
    },

  ],
  function () {
    return new BopT2ContractorFormModel("");
  },
  "question"
);

ElementFactory.Instance.registerElement(CONTRACTOR_FORM_TYPE, (name) => {
  return new BopT2ContractorFormModel(name);
});

// Add interface for location data
interface LocationData {
  isoCode: string;
  name: string;
}

// Add interface for form values
interface FormValues {
  facilityName: string;
  nameLocalLanguage: string;
  mobileWechat: string;
  country: string;
  stateProvince: string;
  city: string;
  email: string;
  phone: string;
  address: string;
  stateProvinceOther?: string;
  cityOther?: string;
}

// Add interfaces for state management
interface ProcessForm {
  isVisible: string;
  title: string;
  processName: string;
}

// Add interface for error state
interface State extends React.ComponentState {
  countries: LocationData[];
  statesByProcess: Record<string, LocationData[]>;
  citiesByProcess: Record<string, LocationData[]>;
  activeProcesses: ProcessForm[];
  formValuesByProcess: Record<string, FormValues>;
}

// Create a wrapper component to handle the translation
function BopT2ContractorFormWrapper(props: any) {
  const { t } = useTranslate();
  props.question.setTranslation(t);
  return <BopT2ContractorForm {...props} t={t} />;
}

/**
 * @description A class that renders questions of the new type in the UI
 */
export class BopT2ContractorForm extends SurveyQuestionElementBase {
  private abortController?: AbortController;
  private fetchedStates: Set<string> = new Set();
  private fetchedCities: Set<string> = new Set();

  constructor(props: any) {
    super(props);
    
    this.state = {
      countries: [],
      statesByProcess: {},
      citiesByProcess: {},
      activeProcesses: [],
      formValuesByProcess: {}
    } as State;
  }

  /**
   * @description Check if process is active based on expressions and update state accordingly
   */
  setInitialState = () => {
    const { listOfForms } = this.question;
    const questionValue = this.question.value || {};
    
    if (!listOfForms?.length) {
      return;
    }

    const activeProcesses = listOfForms
      .filter((form: any) => {
        try {
          // Evaluate if the expression is visible
          const survey = this.question.survey;
          return survey ? survey.runExpression(form.isVisible) : false;
        } catch (event) {
          console.error('Error evaluating expression:', event);
          return false;
        }
      });

    // Filter formValuesByProcess to only include active processes
    const activeProcessNames = activeProcesses.map((form: any) => form.processName);
    const formValuesByProcess = Object.keys(questionValue)
      .filter(key => activeProcessNames.includes(key))
      .reduce((obj, key) => {
        obj[key] = questionValue[key];
        return obj;
      }, {} as Record<string, any>);

    this.setState({ 
      activeProcesses,
      formValuesByProcess
    });
  };

  /**
   * @description Handle input change
   * @param processName - The unique identifier for the process/form instance
   * @param name - The name of the input field
   * @param value - The value of the input field
   */
  handleInputChange = (processName: string, name: string, value: string) => {
    const state = this.state;
    const question = this.question;
    const stateFormValues = state.formValuesByProcess[processName] || {};
    const formValuesByProcess = {
      ...state.formValuesByProcess,
      [processName]: {
        ...stateFormValues,
        [name]: value
      }
    };

    // Update selected country/state when those fields change
    if (name === 'country') {
      formValuesByProcess[processName].country = value;
      formValuesByProcess[processName].stateProvince = '';
      formValuesByProcess[processName].city = '';
      formValuesByProcess[processName].stateProvince = '';
      this.fetchStates(processName, value);
    }

    if (name === 'stateProvince') {
      formValuesByProcess[processName].stateProvince = value;
      // Clear the other value if a regular option is selected
      if (value !== 'other') {
        formValuesByProcess[processName].stateProvinceOther = '';
      }
      formValuesByProcess[processName].city = '';
      this.fetchCities(processName, formValuesByProcess[processName].country || '', value);
    }

    if (name === 'city') {
      formValuesByProcess[processName].city = value;
      // Clear the other value if a regular option is selected
      if (value !== 'other') {
        formValuesByProcess[processName].cityOther = '';
      }
    }

    this.setState({ formValuesByProcess }, () => {
      const valuesByProcess = Object.entries(formValuesByProcess).reduce((acc, [process, values]) => ({
        ...acc,
        [process]: values
      }), {});

      question.value = valuesByProcess;
    });
  };

  /**
   * @description Add a new handler for the state/province other field
   * @param processName - The unique identifier for the process/form instance
   * @param value - The value of the state/province other field
   */
  handleStateProvinceOther = (processName: string, value: string) => {
    const state = this.state;
    const question = this.question;
    const stateFormValues = state.formValuesByProcess[processName] || {};

    const formValuesByProcess = {
      ...state.formValuesByProcess,
      [processName]: {
        ...stateFormValues,
        stateProvinceOther: value,
        stateProvince: 'other'
      }
    };

    this.setState({ formValuesByProcess }, () => {
      question.value = formValuesByProcess;
    });
  };

  /**
   * @description Add a new handler for the city other field
   */
  handleCityOther = (processName: string, value: string) => {
    const state = this.state;
    const question = this.question;
    const stateFormValues = state.formValuesByProcess[processName] || {};

    const formValuesByProcess = {
      ...state.formValuesByProcess,
      [processName]: {
        ...stateFormValues,
        cityOther: value,
        city: 'other'
      }
    };

    this.setState({ formValuesByProcess }, () => {
      question.value = formValuesByProcess;
    });
  };

  /**
   * @description Fetch states from the location API based on country code
   * @param processName - The unique identifier for the process/form instance
   * @param countryCode - The ISO code of the selected country (e.g., 'US', 'CN') 
   * @returns void
   */
  fetchStates = (processName: string, countryCode: string) => {
    if (!countryCode) return;
    
    const fetchKey = `${processName}-${countryCode}`;
    
    if (this.fetchedStates.has(fetchKey)) return;
    
    this.fetchedStates.add(fetchKey);

    const locationApiUrl = this.question.locationApiUrl;
    const isLocationApiUrlEmpty = locationApiUrl === "" || locationApiUrl === undefined;
    const apiUrl = isLocationApiUrlEmpty ? LOCATION_API_URL : locationApiUrl;
    fetch(`${apiUrl}?countryCode=${countryCode}`)
      .then(res => res.json())

      .then(data => this.setState((prevState: any) => ({
        statesByProcess: {
          ...prevState.statesByProcess,
          [processName]: data
        }
      })));
  };

  /**
   * @description Fetch cities from the location API based on country and state codes
   * @param processName - The unique identifier for the process/form instance
   * @param countryCode - The ISO code of the selected country (e.g., 'US', 'CN')
   * @param stateCode - The ISO code or identifier of the selected state/province
   * @returns void
   */
  fetchCities = (processName: string, countryCode: string, stateCode: string) => {
    if (!countryCode || !stateCode) return;

    const fetchKey = `${processName}-${countryCode}-${stateCode}`;
    
    if (this.fetchedCities.has(fetchKey)) return;
    
    this.fetchedCities.add(fetchKey);

    const locationApiUrl = this.question.locationApiUrl;
    const isLocationApiUrlEmpty = locationApiUrl === "" || locationApiUrl === undefined;
    const apiUrl = isLocationApiUrlEmpty ? LOCATION_API_URL : locationApiUrl;
    fetch(`${apiUrl}?countryCode=${countryCode}&stateCode=${stateCode}`)
      .then(res => res.json())

      .then(data => this.setState((prevState: any) => ({
        citiesByProcess: {
          ...prevState.citiesByProcess,
          [processName]: data
        }
      })));
  };

  /**
   * @description Get question
   */
  get question() {
    return this.questionBase;
  }

  /**
   * @description Get question value
   */
  get value() {
    return this.question.value;
  }

  /**
   * @description Fetch countries
   */
  componentDidMount() {
    const controller = new AbortController();
    const locationApiUrl = this.question.locationApiUrl;
    const isLocationApiUrlEmpty = locationApiUrl === "" || locationApiUrl === undefined;
    const apiUrl = isLocationApiUrlEmpty ? LOCATION_API_URL : locationApiUrl;


    fetch(apiUrl, { signal: controller.signal })
      .then((res) => res.json())
      .then((data) => this.setState({ countries: data }))
      .catch(error => {
        if (error.name !== 'AbortError') {
          console.error('Fetch error:', error);
        }
      });

    this.setInitialState();
    this.abortController = controller;
  }

  /**
  * @description Abort any pending requests when component unmounts
  */
  componentWillUnmount() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  /**
   * @description Update component
   * @param prevProps - Previous props
   * @param prevState 
   */
  componentDidUpdate(prevProps: any, prevState: any) {
    // Check for changes in expressions
    if (this.props.question !== prevProps.question) {
      this.setInitialState();
    }
  }

  renderElement() {
    const { activeProcesses } = this.state;

    if (!activeProcesses || activeProcesses.length === 0) {
      return null;
    }

    return (
      <div className="flex flex-col space-y-4">
        <div className="text-sm mb-4 text-sky-600">
          <Icon.AlertCircle className="w-4 h-4 mr-2 text-sky-600 inline-block text-xs" /> {this.props.question.title}
        </div>

        {activeProcesses.map((form: any, index: any) => {
          const isLastForm = index === activeProcesses.length - 1;
          return (
            <div
              key={index}
              className={`process-section ${!isLastForm ? 'pb-8 border-b border-gray-50' : ''
                }`}
            >
              <h3 className="text-sm mb-4">
                <T keyName="pq-bop-t2-supplier-information-process"/>: {form.title}
              </h3>
              {this.renderFormGrid(form.processName)}
            </div>
          );
        })}
      </div>
    );
  }

  /**
   * @description Render error message
   */
  renderError(processName: string, fieldName: string) {
    const error = (this.question as BopT2ContractorFormModel).getCustomError(processName, fieldName);

    if (!error) return null;

    return (
      <div className="text-red-500 text-xs mt-1">
        {error}
      </div>
    );
  }

  /**
   * @description Get error state for field
   */
  hasError(processName: string, fieldName: string): boolean {
    return (this.question as BopT2ContractorFormModel).hasCustomError(processName, fieldName);
  }

  /**
   * @description Render form grid
   * @param processName - The unique identifier for the process/form instance
   * @returns void
   */
  renderFormGrid(processName: string) {
    const state = this.state;
    const { countries } = state;
    const formValues = state.formValuesByProcess[processName] || {};
    const states = state.statesByProcess[processName] || [];
    const cities = state.citiesByProcess[processName] || [];
    const { t } = this.props;
    const statesIsDisabled = !formValues.country;
    const citiesIsDisabled = !formValues.country;
    const areNotStatesFetched = formValues.country !== undefined && states.length === 0;
    const areNotCitiesFetched = formValues.country !== undefined && formValues.stateProvince !== undefined && cities.length === 0;

    if (areNotStatesFetched) {
      this.fetchStates(processName, formValues.country);
    }

    if (areNotCitiesFetched) {
      this.fetchCities(processName, formValues.country, formValues.stateProvince);
    }

    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Main grid with 3 rows */}
        <div>
          <input
            name={`pq-bop-t2-supplier-information-process-${processName}-facility-name`}
            type="text"
            required={true}
            value={formValues.facilityName}
            onChange={(e) => this.handleInputChange(processName, 'facilityName', e.target.value)}
            className={`sd-text appearance-none static border-box  
              disabled:bg-gray-50 disabled:text-gray-500 w-full py-2.5 px-[14px] 
              shadow-xs rounded-lg border ${this.hasError(processName, 'facilityName') ? 'border-red-500 bg-red-500 bg-opacity-10' : 'border-gray-300 bg-white'} text-xs md:text-sm leading-[16px] 
              md:leading-5 text-start font-regular text-gray-500 focus:border-indigo-500 
              focus:text-gray-800 focus:ring-4 focus:ring-indigo-100 
              outline-none placeholder-gray-300`}
            placeholder={t("pq-bop-t2-supplier-information-process-facility-name-placeholder") + " *"}
          />
          {this.renderError(processName, 'facilityName')}
        </div>

        <div>
          <input
            name={`pq-bop-t2-supplier-information-process-${processName}-name-local-language`}
            type="text"
            value={formValues.nameLocalLanguage}
            onChange={(e) => this.handleInputChange(processName, 'nameLocalLanguage', e.target.value)}
            className={`sd-text appearance-none static border-box bg-white 
              disabled:bg-gray-50 disabled:text-gray-500 w-full py-2.5 px-[14px] 
              shadow-xs rounded-lg border border-gray-300 text-xs md:text-sm leading-[16px] 
              md:leading-5 text-start font-regular text-gray-500 focus:border-indigo-500 
              focus:text-gray-800 focus:ring-4 focus:ring-indigo-100 
              outline-none placeholder-gray-300`}
            placeholder={t("pq-bop-t2-supplier-information-process-name-local-language-placeholder")}
          />
        </div>

        <div>
          <input
            name={`pq-bop-t2-supplier-information-process-${processName}-address`}
            type="text"
            value={formValues.address}
            onChange={(e) => this.handleInputChange(processName, 'address', e.target.value)}
            className={`sd-text appearance-none static border-box bg-white 
              disabled:bg-gray-50 disabled:text-gray-500 w-full py-2.5 px-[14px] 
              shadow-xs rounded-lg border border-gray-300 text-xs md:text-sm leading-[16px] 
              md:leading-5 text-start font-regular text-gray-500 focus:border-indigo-500 
              focus:text-gray-800 focus:ring-4 focus:ring-indigo-100 
              outline-none placeholder-gray-300`}
            placeholder={t("pq-bop-t2-supplier-information-process-address-placeholder")}
          />
        </div>

        {/* Row 2 */}
        <div className="flex flex-col gap-2">
          <ComboboxOther
            name={`pq-bop-t2-supplier-information-process-${processName}-country`}
            placeholder={t("pq-bop-t2-supplier-information-process-country-placeholder")}
            items={countries.map((country: LocationData) => ({
              value: country.isoCode,
              title: country.name
            }))}
            required={true}
            allowClear={true}
            value={formValues.country}
            onValueChange={(value) => this.handleInputChange(processName, 'country', value || '')}
            ariaInvalid={this.hasError(processName, 'country')}
          />
          {this.renderError(processName, 'country')}
        </div>

        <div className="flex flex-col gap-2">
          <ComboboxOther
            name={`pq-bop-t2-supplier-information-process-${processName}-state-province`}
            placeholder={t("pq-bop-t2-supplier-information-process-state-province-placeholder")}
            items={states.map((state: LocationData) => ({
              value: state.isoCode,
              title: state.name
            }))}
            required={true}
            allowClear={true}
            hasOther={true}
            onCommentChange={(value) => this.handleStateProvinceOther(processName, value || '')}
            isOtherSelected={formValues.stateProvince === 'other'}
            comment={formValues.stateProvinceOther}
            disabled={statesIsDisabled}
            value={formValues.stateProvince}
            otherItem={{
              value: "other",
              title: t("pq-bop-t2-dropdown-other")
            }}
            commentPlaceHolder={t("pq-bop-t2-dropdown-other-placeholder")}
            onValueChange={(value) => this.handleInputChange(processName, 'stateProvince', value || '')}
            ariaInvalid={this.hasError(processName, 'stateProvince') || this.hasError(processName, 'stateProvinceOther')}
          />
          {this.renderError(processName, 'stateProvince') || this.renderError(processName, 'stateProvinceOther')}
        </div>

        <div className="flex flex-col gap-2">
          <ComboboxOther
            name={`pq-bop-t2-supplier-information-process-${processName}-city`}
            placeholder={t("pq-bop-t2-supplier-information-process-city-placeholder")}
            items={cities.map((city: LocationData) => ({
              value: city.name,
              title: city.name
            }))}
            hasOther={true}
            allowClear={true}
            onCommentChange={(value) => this.handleCityOther(processName, value || '')}
            isOtherSelected={formValues.city === 'other'}
            comment={formValues.cityOther}
            disabled={citiesIsDisabled}
            value={formValues.city}
            otherItem={{
              value: "other",
              title: t("pq-bop-t2-dropdown-other")
            }}
            commentPlaceHolder={t("pq-bop-t2-dropdown-other-placeholder")}
            onValueChange={(value) => this.handleInputChange(processName, 'city', value || "")}
            ariaInvalid={this.hasError(processName, 'cityOther')}
          />
          {this.renderError(processName, 'cityOther')}
        </div>

        {/* Row 3 */}
        <div>
          <input
            name={`pq-bop-t2-supplier-information-process-${processName}-email`}
            type="email"
            value={formValues.email}
            onChange={(e) => this.handleInputChange(processName, 'email', e.target.value)}
            className={`sd-text appearance-none static border-box  
              disabled:bg-gray-50 disabled:text-gray-500 w-full py-2.5 px-[14px] 
              shadow-xs rounded-lg border ${this.hasError(processName, 'email') ? 'border-red-500 bg-red-500 bg-opacity-10' : 'border-gray-300 bg-white'} text-xs md:text-sm leading-[16px] 
              md:leading-5 text-start font-regular text-gray-500 focus:border-indigo-500 
              focus:text-gray-800 focus:ring-4 focus:ring-indigo-100 
              outline-none placeholder-gray-300`}
            placeholder={t("pq-bop-t2-supplier-information-email-placeholder")}
          />
          {this.renderError(processName, 'email')}
        </div>

        <div>
          <input
            name={`pq-bop-t2-supplier-information-process-${processName}-phone`}
            type="tel"
            value={formValues.phone}
            onChange={(e) => this.handleInputChange(processName, 'phone', e.target.value)}
            className={`sd-text appearance-none static border-box  
              disabled:bg-gray-50 disabled:text-gray-500 w-full py-2.5 px-[14px] 
              shadow-xs rounded-lg border ${this.hasError(processName, 'phone') ? 'border-red-500 bg-red-500 bg-opacity-10' : 'border-gray-300 bg-white'} text-xs md:text-sm leading-[16px] 
              md:leading-5 text-start font-regular text-gray-500 focus:border-indigo-500 
              focus:text-gray-800 focus:ring-4 focus:ring-indigo-100 
              outline-none placeholder-gray-300`}
            placeholder={t("pq-bop-t2-supplier-information-process-phone-placeholder")}
          />
          {this.renderError(processName, 'phone')}
        </div>

        <div>
          <input
            name={`pq-bop-t2-supplier-information-process-${processName}-mobile-wechat`}
            type="text"
            value={formValues.mobileWechat}
            onChange={(e) => this.handleInputChange(processName, 'mobileWechat', e.target.value)}
            className={`sd-text appearance-none static border-box bg-white 
              disabled:bg-gray-50 disabled:text-gray-500 w-full py-2.5 px-[14px] 
              shadow-xs rounded-lg border border-gray-300 text-xs md:text-sm leading-[16px] 
              md:leading-5 text-start font-regular text-gray-500 focus:border-indigo-500 
              focus:text-gray-800 focus:ring-4 focus:ring-indigo-100 
              outline-none placeholder-gray-300`}
            placeholder={t("pq-bop-t2-supplier-information-process-mobile-wechat-placeholder")}
          />
        </div>

      </div>
    );
  }
}

ReactQuestionFactory.Instance.registerQuestion(CONTRACTOR_FORM_TYPE, (props) => {
  return React.createElement(BopT2ContractorFormWrapper, props);
});