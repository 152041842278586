import { ReactQuestionFactory } from "survey-react-ui";
import {
  QuestionExpressionModel,
  RendererFactory,
} from "survey-core";
import { createElement, FC } from "react";
import { CheckCircle, XCircle } from "react-feather";
interface ExplainerPageContentProps {
  question: QuestionExpressionModel;
}

const ExplainerPageContent: FC<ExplainerPageContentProps> = ({ question }) => {
  const value = parseFloat(question.formatedValue);
  const is100 = value === 100;
  const hasError = question.errors.length > 0 || !is100;

  return (
    <div className="flex items-center gap-2 text-sm">
      {is100 && <CheckCircle className="text-green-500" />}
      {hasError && <XCircle className="text-red-500" />}
      <span>
        {question.formatedValue} %
      </span>
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-total-percentage-expression",
  function (props: any) {
    return createElement(ExplainerPageContent, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "total-percentage-expression",
  "sv-total-percentage-expression"
);
