import React from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from "survey-react-ui";
import { useTranslate } from "@tolgee/react";
import { Dropdown } from "../../ui/dropdown";

const CUSTOM_TYPE = "materials-traceability-yarn-composition-question";
const MATERIALS_TRACEABILITY_YARN_COMPOSITION_URL =
  "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/pq_bop_t2_material_composition_percentage_fiber_composition";
const BLENDED_OPTION_VALUE = "RAW_MATERIAL#BLENDED";
const BLENDED_OPTION_TITLE = "pq-bop-t2-yarn-blended";

export class MaterialTypeQuestionModel extends Question {
  private t: any;
  public materialsComposition: string = "";
  public materialKey: string = "";

  getType() {
    return CUSTOM_TYPE;
  }

  public setTranslation(t: any) {
    this.t = t;
  }
}

// Add question type metadata
Serializer.addClass(
  CUSTOM_TYPE,
  [
    {
      name: "materialCompositionUrl",
      default: MATERIALS_TRACEABILITY_YARN_COMPOSITION_URL,
    },
    {
      name: "materialsComposition:materialscomposition",
      default: "",
    },
    {
      name: "materialKey",
      default: "",
    },
  ],
  function () {
    return new MaterialTypeQuestionModel("");
  },
  "question"
);

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new MaterialTypeQuestionModel(name);
});

interface Material {
  value: string;
  text: string;
}

/**
 * @description Wrapper component to handle the translation
 * @param {any} props - The props
 * @returns {JSX.Element} - The wrapper component
 */
function MaterialTypeQuestionWrapper(props: any) {
  const { t } = useTranslate();
  props.question.setTranslation(t);
  return <MaterialTypeQuestion {...props} t={t} />;
}

export class MaterialTypeQuestion extends SurveyQuestionElementBase {
  // Add a unique instance ID for each component instance
  private instanceId: string = `instance_${Math.random()
    .toString(36)
    .substring(2, 11)}`;
  private subscriptionId: string = "";

  state = {
    materials: [] as Material[],
    selectedFiberKeys: [] as string[],
  };

  /**
   * @description Extract fiber keys from composition data
   * @param {any} compositionValue - The composition value
   * @returns {string[]} The fiber keys
   */
  private extractFiberKeys(compositionValue: any): string[] {
    const compositions = compositionValue || [];
    return compositions
      .map((comp: any) => comp.fibre)
      .filter((fiber: string) => fiber && fiber.length > 0);
  }

  /**
   * @description Mount the component
   */
  componentDidMount() {
    const materialUrl = this.question.materialCompositionUrl;
    // Initial fetch of all available materials
    fetch(materialUrl)
      .then((res) => res.json())
      .then((materials) => this.setState({ materials }))
      .catch((error) => {
        console.error("Error fetching materials:", error);
      });

    // Add subscription to the matrix question
    const materialsQuestion = this.question.survey?.getQuestionByName(
      this.question.materialsComposition
    );

    if (materialsQuestion) {
      // Immediately get the current value to populate the dropdown for new rows
      const currentValue = materialsQuestion.value;
      if (currentValue) {
        const fiberKeys = this.extractFiberKeys(currentValue);
        this.setState({ selectedFiberKeys: fiberKeys });
      }

      // Create a truly unique subscription ID using the instance ID
      this.subscriptionId = `materialsCompositionSubscription_${this.instanceId}`;
      materialsQuestion.registerFunctionOnPropertyValueChanged(
        "value",
        (newValue: Array<{ fibre: string }>) => {
          const fiberKeys = this.extractFiberKeys(newValue);
          this.setState({ selectedFiberKeys: fiberKeys });
        },
        this.subscriptionId
      );
    }
  }

  /**
   * @description Unmount the component
   */
  componentWillUnmount() {
    // Clean up subscription
    const materialsQuestion = this.question.survey?.getQuestionByName(
      this.question.materialsComposition
    );

    if (materialsQuestion && this.subscriptionId) {
      materialsQuestion.unRegisterFunctionOnPropertyValueChanged(
        "value",
        this.subscriptionId
      );
    }
  }

  get question() {
    return this.questionBase as MaterialTypeQuestionModel;
  }

  /**
   * @description Returns unique materials from the composition list with their titles
   * @returns {{ value: string; title: string }[]} - The unique materials formatted for dropdown
   */
  getUniqueMaterials = () => {
    const { t } = this.props;
    const { materials, selectedFiberKeys } = this.state;

    if (selectedFiberKeys.length === 0) {
      return [];
    }

    // Filter materials based on selected fibers
    const formattedMaterials: { value: string | undefined; title: string }[] =
      materials
        .filter((material: Material) =>
          selectedFiberKeys.includes(material.value)
        )
        .map((material: Material) => ({
          value: material.value,
          title: material.text,
        }));

    // Add blended option if multiple fibers
    if (selectedFiberKeys.length > 1) {
      formattedMaterials.push({
        value: BLENDED_OPTION_VALUE,
        title: t(BLENDED_OPTION_TITLE),
      });
    }

    formattedMaterials.unshift({ value: undefined, title: "" });

    return formattedMaterials;
  };

  /**
   * @description Render the element
   * @returns {JSX.Element} - The element
   */
  renderElement() {
    const { t } = this.props;
    const materials = this.getUniqueMaterials();
    const materialIsDisabled = materials.length === 0;

    return (
      <div>
        <Dropdown
          className="w-full"
          name="material-type"
          placeholder={t("pq-bop-t2-material-type-placeholder")}
          items={materials}
          required={true}
          value={this.question.value}
          onValueChange={(value) => {
            this.question.value = value;
          }}
          disabled={materialIsDisabled || this.question.isReadOnly}
        />
      </div>
    );
  }
}

// Register the question type
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return React.createElement(MaterialTypeQuestionWrapper, props);
});
