export const customCss = {
  completedPage: "",
  body: "box-border p-5",
  panel: {
    title: "text-sm font-medium text-gray-800",
    content: "sd-element__content sd-panel__content",
    nested: "nested-panel sd-element--nested",
  },
  navigation: {
    complete: "!bg-indigo-500 text-white",
  },
  question: {
    mainRoot: "sd-element sd-question sd-row__question",
    requiredText: "sd-question__required-text text-error-500",
    title:
      "sd-question__title text-xs md:text-sm leading-[16px] md:leading-5 font-medium !text-gray-700  whitespace-normal",
    headerTop: "sd-element__header--location-top pb-1.5",
    descriptionUnderInput:
      "text-xs leading-[16px] font-regular text-gray-500 whitespace-normal pt-1.5",
    description: "text-xs font-regular text-gray-500 whitespace-normal",
    expanded: "pl-[50px] pt-[30px] border-t border-t-gray-50",
    collapsed: "pl-[50px] pt-[30px] border-t border-t-gray-50",
  },
  html: {
    root: "",
  },
  text: {
    root: "sd-text appearance-none static border-box bg-white disabled:bg-gray-50 disabled:text-gray-500 w-full py-2.5 px-[14px] shadow-xs rounded-lg border border-gray-300 text-xs md:text-sm leading-[16px] md:leading-5 text-start font-regular text-gray-500 focus:border-indigo-500 focus:text-gray-800 focus:ring-4 focus:ring-indigo-100 outline-none placeholder-gray-300 [&:read-only]:bg-gray-50 [&:read-only]:text-gray-500",
    controlDisabled: "",
  },
  checkbox: {
    other:
      "sd-comment sd-selectbase__other appearance-none static border-box bg-white w-full py-2.5 px-[14px] shadow-xs rounded-lg border border-gray-300 focus:text-gray-800 focus:border-indigo-500 focus:ring-4 focus:ring-indigo-100 outline-none disabled:bg-gray-50 disabled:text-gray-500 text-xs md:text-sm leading-[16px] md:leading-6 text-start font-regular text-gray-500 placeholder-gray-300",
  },
  comment: {
    root: "sd-comment appearance-none static w-full text-start shadow-xs box-border bg-white py-2.5 px-[14px] border border-gray-300 rounded-lg text-xs md:text-sm leading-[16px] md:leading-6 font-regular text-gray-500 focus:text-gray-800 focus:border-indigo-500 focus:ring-4 focus:ring-indigo-100 placeholder-gray-30 outline-none disabled:bg-gray-50 disabled:text-gray-500",
    controlDisabled: "",
  },
  matrixdynamic: {
    buttonRemove: "",
  },
};
