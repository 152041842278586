import { useMultipleSelection, useCombobox } from "downshift";
import { ChevronDown, Search, X } from "../../icons";
import classNames from "classnames";
import {
  ChangeEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import { useBreakpoint } from "../../../hooks";
import { debounce } from "lodash";
import { Checkbox } from "../checkbox";

type MultiSelectDownshiftProps = {
  items?: { value: string; title: string | ReactElement }[];
  placeholder?: string;
  values?: string[];
  className?: string;
  ariaLabel?: string;
  ariaRequired?: boolean;
  ariaInvalid?: boolean;
  ariaErrormessage?: string;
  autoComplete?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  dense?: boolean;
  defaultValue?: string;
  onValueChange?: (values: string[]) => void;
  isOtherSelected?: boolean;
  commentPlaceHolder?: string;
  allowClear?: boolean;
  hasOther?: boolean;
  hasNone?: boolean;
  otherItem?: { title: string; value: any };
  noneItem?: { title: string; value: any };
  comment?: string;
  onCommentChange?: (comment: string) => void;
};

export function MultiSelectDownshift({
  items = [],
  placeholder,
  values = [],
  className,
  ariaLabel,
  required,
  disabled,
  name,
  ariaInvalid,
  onValueChange,
  allowClear,
  hasOther,
  hasNone,
  otherItem,
  noneItem,
  isOtherSelected,
  commentPlaceHolder,
  comment,
  onCommentChange,
}: MultiSelectDownshiftProps) {
  const { isAboveMd } = useBreakpoint("md");
  const [inputValue, setInputValue] = useState("");
  const [otherValue, setOtherValue] = useState(comment || "");
  const [isProcessing, setIsProcessing] = useState(false);

  const itemsWithKeys = useMemo(() => {
    const otherOption = {
      value: otherItem?.value || "other",
      title: otherItem?.title || "Other",
      key: "other-option",
    };
    const noneOption = {
      value: noneItem?.value || "none",
      title: noneItem?.title || "None",
      key: "none-option",
    };
    const clearOption = { value: "unassigned", title: "", key: "clear-option" };
    const regularItems = items.map((item, index) => ({
      ...item,
      key: String(item.value) + String(index),
    }));

    const finalItems = [...regularItems];
    if (hasOther) finalItems.push(otherOption);
    if (hasNone) finalItems.push(noneOption);
    if (allowClear) finalItems.unshift(clearOption);
    return finalItems;
  }, [items, otherItem, noneItem, hasOther, hasNone, allowClear]);

  const filteredItems = useMemo(() => {
    const lowerCasedInputValue = inputValue.toLowerCase();
    return itemsWithKeys.filter((item) => {
      const lowerCasedTitle = item.title.toString().toLowerCase();
      const isOtherOption = item.key === "other-option";
      const isNoneOption = item.key === "none-option";
      const isClearOption = item.key === "clear-option";
      return (
        isOtherOption ||
        isNoneOption ||
        isClearOption ||
        lowerCasedTitle.includes(lowerCasedInputValue)
      );
    });
  }, [itemsWithKeys, inputValue]);

  const {
    getSelectedItemProps,
    getDropdownProps,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    selectedItems: itemsWithKeys.filter((item) => values.includes(item.value)),
    onSelectedItemsChange: (changes) => {
      onValueChange?.(changes.selectedItems?.map((item) => item.value) || []);
    },
  });

  const {
    isOpen,
    closeMenu,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    items: filteredItems,
    itemToString: (item) => item?.title?.toString() || "",
    defaultHighlightedIndex: 0,
    selectedItem: null,
    inputValue,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.ItemClick:
          // Destructure to remove highlightedIndex from changes
          const { highlightedIndex, ...restChanges } = changes;
          return {
            ...restChanges,
            isOpen: true,
          };
        default:
          return changes;
      }
    },
    onStateChange: ({
      type,
      selectedItem: newSelectedItem,
      inputValue: newInputValue,
    }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (newSelectedItem && !isProcessing) {
            setIsProcessing(true);
            if (values.includes(newSelectedItem.value)) {
              onValueChange?.(values.filter(value => value !== newSelectedItem.value));
            } else {
              onValueChange?.([...values, newSelectedItem.value]);
            }
            setInputValue("");
            setTimeout(() => setIsProcessing(false), 100);
          }
          break;
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue || "");
          break;
        default:
          break;
      }
    },
  });

  /**
   * Prevent the default behavior of the event
   * @param event - The event
   */
  const preventDefault = (event: any) => {
    event.preventDefault();
  };

  /**
   * Handle the change of the other value
   * @param event - The change event
   */
  const handleOtherValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setOtherValue(value);
    onCommentChange?.(value);
  };

  /**
   * Debounce the change of the other value
   * @param newOtherValue - The new other value
   */
  const debouncedHandleOtherValueChange = debounce((newOtherValue: string) => {
    if (newOtherValue !== String(otherValue)) {
      onCommentChange?.(newOtherValue);
    }
  }, 500);

  useEffect(() => {
    debouncedHandleOtherValueChange(otherValue);
  }, [otherValue, debouncedHandleOtherValueChange]);

  const selectInputRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [showAbove, setShowAbove] = useState(false);

  /**
   * Get the top position of the menu
   * @returns The top position
   */
  const getTopPosition = (): number => {
    if (!containerRef.current) return 0;
    return containerRef.current.getBoundingClientRect().bottom + 10;
  };

  /**
   * Get the bottom position of the menu
   * @returns The bottom position
   */
  const getBottomPosition = (): number => {
    if (!containerRef.current) return 0;
    return (
      window.innerHeight - containerRef.current.getBoundingClientRect().top + 10
    );
  };

  /**
   * Get the left position of the menu
   * @returns The left position
   */
  const getLeftPosition = (): number => {
    if (!containerRef.current) return 0;
    return containerRef.current.getBoundingClientRect().left;
  };

  useLayoutEffect(() => {
    if (isOpen && selectInputRef.current && menuRef.current) {
      const inputRect = selectInputRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - inputRect.bottom;
      const spaceAbove = inputRect.top;

      setShowAbove(spaceBelow < spaceAbove);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = (event: Event) => {
      if (!isOpen) return;

      const menuElement = menuRef.current;
      const target = event.target as Node;

      if (
        menuElement &&
        (menuElement === target || menuElement.contains(target))
      ) {
        return;
      }

      closeMenu();
    };

    if (isOpen) {
      window.addEventListener("scroll", handleScroll, true);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [isOpen, closeMenu]);

  /**
   * Calculate the maximum width for selected items based on container width
   * @param containerWidth - The width of the container element
   * @returns The maximum width in pixels for selected items
   */
  const calculateMaxItemWidth = (containerWidth: number): number => {
    const containerPadding = 10;
    const chevronWidth = 50;

    const availableWidth =
      containerWidth - containerPadding - chevronWidth;

    return availableWidth;
  };

  const [maxItemWidth, setMaxItemWidth] = useState(200);
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const updateMaxWidth: any = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        setMaxItemWidth(calculateMaxItemWidth(containerWidth));
      }
    };

    updateMaxWidth();
    window.addEventListener("resize", updateMaxWidth);
    return () => window.removeEventListener("resize", updateMaxWidth);
  }, []);

  return (
    <div>
      <div ref={containerRef}>
        <div
          className={classNames(
            "py-[2px] pr-[14px] pl-[4px] min-h-[42px] group",
            "flex items-center justify-between text-start",
            "font-regular text-xs md:text-sm leading-[16px]",
            "md:leading-5 text-gray-500 shadow-xs rounded-lg border",
            "border-gray-300 bg-white focus-within:ring-4 focus-within:ring-indigo-100",
            "focus-within:border-indigo-500 outline-none focus-within:text-gray-800",
            { "bg-gray-50": disabled },
            className
          )}
        >
          <div
            ref={selectInputRef}
            className="flex flex-wrap flex-1 gap-1 overflow-hidden whitespace-nowrap text-ellipsis"
          >
            {selectedItems.length > 0 &&
              selectedItems.map((selectedItem, index) => (
                <div
                  key={selectedItem.key}
                  {...getSelectedItemProps({ selectedItem, index })}
                  className="flex 
                  items-center gap-1 p-[5px] bg-[#E8ECEE] rounded-md 
                  overflow-hidden text-ellipsis whitespace-nowrap"
                  style={{ maxWidth: maxItemWidth }}
                >
                  <span
                    title={selectedItem.title.toString()}
                    className="overflow-hidden text-gray-700 truncate
                     text-ellipsis whitespace-nowrap cursor-pointer"
                  >
                    {selectedItem.title}
                  </span>
                  <button
                    type="button"
                    onClick={(event) => {
                      event.stopPropagation();
                      removeSelectedItem(selectedItem);
                    }}
                    className="pl-[10px] flex-shrink-0"
                  >
                    <X size={12} color="#3C4C53" />
                  </button>
                </div>
              ))}
            <span
              {...getToggleButtonProps()}
              className="flex-1 outline-none 
            bg-transparent min-w-[100px] pl-[10px] h-[30px] leading-[34px] cursor-pointer"
            >
              {placeholder}
            </span>
          </div>
          <button type="button" {...getToggleButtonProps()} className="ml-2">
            <ChevronDown
              color={disabled ? "var(--gray-300)" : "var(--gray-500)"}
              size={isAboveMd ? 20 : 18}
              className={classNames("transition-transform", {
                "rotate-180": isOpen,
              })}
            />
          </button>
        </div>
        <div
          ref={menuRef}
          className={classNames(
            "fixed z-50 bg-white border border-gray-100 rounded-lg shadow-lg",
            "max-h-60 overflow-auto max-h-[300px]",
            { hidden: !isOpen }
          )}
          style={{
            width: containerRef.current?.offsetWidth,
            ...(showAbove
              ? { bottom: getBottomPosition() }
              : { top: getTopPosition() }),
            left: getLeftPosition(),
          }}
        >
          <div className="sticky top-0 bg-white px-[10px]">
            <div className="relative flex items-center p-2 border-b border-gray-100 w-full">
              <input
                {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
                placeholder={placeholder}
                className="w-full text-xs font-semibold text-gray-900 outline-none md:text-sm py-[5px] pr-[20px]"
                disabled={disabled}
                onClick={preventDefault}
              />
              <Search size={18} className="absolute right-0 mt-[5px]" />
            </div>
          </div>
          <ul {...getMenuProps()} className="overflow-auto">
            {isOpen &&
              filteredItems?.map((item, index) => (
                <li
                  key={item.key}
                  {...getItemProps({ item, index })}
                  className={classNames(
                    "flex items-center gap-0 py-2 px-4 select-none min-h-10",
                    "text-sm font-semibold text-gray-700",
                    {
                      "bg-gray-50": highlightedIndex === index,
                    }
                  )}
                >
                  {item.key !== "clear-option" && (
                    <Checkbox
                      checked={selectedItems.some(
                        (selectedItem) => selectedItem.value === item.value
                      )}
                      label=""
                      onChange={preventDefault}
                      onKeyDown={preventDefault}
                      onKeyUp={preventDefault}
                      onFocus={preventDefault}
                      onBlur={preventDefault}
                      onClick={preventDefault}
                    />
                  )}

                  <span className="flex-1 overflow-hidden truncate text-ellipsis whitespace-nowrap">
                    {item.title}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </div>

      {isOtherSelected && (
        <input
          type="text"
          value={otherValue}
          onChange={handleOtherValueChange}
          placeholder={commentPlaceHolder || "Please specify..."}
          className={classNames(
            "w-full px-3 py-2 mt-2 text-sm border rounded-md",
            "focus:ring-4 focus:ring-indigo-100 focus:border-indigo-500 outline-none",
            ariaInvalid
              ? "border-red-500 bg-red-500 bg-opacity-10"
              : "border-gray-300 bg-white"
          )}
        />
      )}
    </div>
  );
}
