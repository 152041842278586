import { ReactQuestionFactory } from "survey-react-ui";
import {
  QuestionExpressionModel,
  RendererFactory,
  SurveyModel,
} from "survey-core";
import { createElement, FC, useMemo } from "react";
import { useTolgee } from "@tolgee/react";
import { ExplainerContent, ExplainerFooter } from "../ui";
import { ExplainerExpression } from "../../types";
import { isExplainerImage } from "../../utils";

interface ExplainerPageContentProps {
  question: QuestionExpressionModel;
}

const ExplainerPageContent: FC<ExplainerPageContentProps> = ({ question }) => {
  const tolgee = useTolgee();
  const { expression, title, survey } = question;
  
  /**
   * Parses the expression string back into an object
   * @param expression string | ExplainerExpression
   * @returns ExplainerExpression
   */
  const getExplainerExpression = (expression: string | ExplainerExpression) => {
    try {
      if (typeof expression === 'string') {
        return JSON.parse(expression);
      }
      return expression as ExplainerExpression;
    } catch (event: any) {
      console.error('Failed to parse explainer expression:', event);
      return { points: [], explainerImage: null };
    }
  }
  // Parse the expression string back into an object
  const data = getExplainerExpression(expression);

  const explainerImage = useMemo(() => {
    if (data.explainerImage) {
      if (typeof data.explainerImage === "string") {
        return {
          src: `images/explainers/${tolgee.getLanguage()}/${
            data.explainerImage
          }`,
        };
      } else if (isExplainerImage(data.explainerImage)) {
        return {
          src: `images/explainers/${tolgee.getLanguage()}/${
            data.explainerImage.src
          }`,
          size: data.explainerImage.size,
        };
      }
    }
  }, [data.explainerImage, tolgee]);

  const onBeginQuestionnaireClick = () => (survey as SurveyModel).nextPage();

  return (
    <div className="flex flex-col gap-5 md:gap-[30px]">
      <ExplainerContent
        data={{
          title,
          points: data.points,
          explainerImage,
        }}
      />
      <ExplainerFooter onClickNext={onBeginQuestionnaireClick} />
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-explainer-page-content-expression",
  function (props: any) {
    return createElement(ExplainerPageContent, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "explainer-page-content-expression",
  "sv-explainer-page-content-expression"
);
