import { getDefaultStore } from "jotai";
import {
  ICustomQuestionTypeConfiguration,
  Question,
  Serializer,
  SurveyModel,
} from "survey-core";
import {
  productsProcessesAtom,
  suppliersAtom,
} from "../../../../../jotai-atoms";
import { IProcess } from "../../../../../types";
import { focusAtom } from "jotai-optics";
import "./bill-of-processes-question.css";
import { isMatrixDropdown, isMatrixDynamic } from "../../../../../utils";

const QUESTION_TYPE_NAME = "bill-of-processes";
const QUESTION_TITLE_IN_BUILDER = "Bill of Processes";
const DEFAULT_QUESTION_TITLE = "Bill of Processes";

const SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX = "SubcontractorOrInHouse";
const SUBCONTRACTOR_NAME_SUFFIX = "SubcontractorName";

enum Processes {
  GARMENT_CUTTING = "garmentCutting",
  GARMENT_SEWING = "garmentSewing",
  GARMENT_BONDING = "garmentBonding",
  GARMENT_EMBROIDERY = "garmentEmbroidery",
  GARMENT_PRINT = "garmentPrint",
  GARMENT_WASHING_FINISHING = "garmentWashingFinishing",
  GARMENT_DYEING = "garmentDyeing",
}

interface IBillOfProcessesValue {
  garmentCutting: boolean;
  garmentSewing: boolean;
  garmentBonding: boolean;
  garmentEmbroidery: boolean;
  garmentPrint: boolean;
  garmentWashingFinishing: boolean;
  garmentDyeing: boolean;
  garmentCuttingSubcontractorOrInHouse: "in-house" | "subcontractor";
  garmentSewingSubcontractorOrInHouse: "in-house" | "subcontractor";
  garmentBondingSubcontractorOrInHouse: "in-house" | "subcontractor";
  garmentEmbroiderySubcontractorOrInHouse: "in-house" | "subcontractor";
  garmentPrintSubcontractorOrInHouse: "in-house" | "subcontractor";
  garmentWashingFinishingSubcontractorOrInHouse: "in-house" | "subcontractor";
  garmentDyeingSubcontractorOrInHouse: "in-house" | "subcontractor";
  garmentCuttingSubcontractorName: string;
  garmentSewingSubcontractorName: string;
  garmentBondingSubcontractorName: string;
  garmentEmbroiderySubcontractorName: string;
  garmentPrintSubcontractorName: string;
  garmentWashingFinishingSubcontractorName: string;
  garmentDyeingSubcontractorName: string;
  [key: string]: any;
}

interface IBillOfProcessesQuestion extends ICustomQuestionTypeConfiguration {}

const billOfProcessesQuestion: IBillOfProcessesQuestion = {
  name: QUESTION_TYPE_NAME,
  title: QUESTION_TITLE_IN_BUILDER,
  defaultQuestionTitle: DEFAULT_QUESTION_TITLE,
  elementsJSON: [
    {
      type: "expression",
      name: "materialMarkRelevantProcessLevel",
      title: "%-%tolgee:product-mark-relevant-process-level-title%-%",
    },
    {
      type: "boolean",
      name: Processes.GARMENT_CUTTING,
      title: "%-%tolgee:product-process-garment-cutting-title%-%",
      titleLocation: "hidden",
      renderAs: "boolean-as-checkbox-question",
      maxWidth: "33%",
    },
    {
      type: "radiogroup",
      name: Processes.GARMENT_CUTTING + SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX,
      titleLocation: "hidden",
      renderAs: "radio-group-inline-question",
      isRequired: true,
      enableIf: "{composite." + Processes.GARMENT_CUTTING + "} == true",
      resetValueExpression:
        "{composite." + Processes.GARMENT_CUTTING + "} <> true",
      resetValueIf: "{composite." + Processes.GARMENT_CUTTING + "} <> true",
      startWithNewLine: false,
      maxWidth: "33%",
      choices: [
        {
          value: "in-house",
          text: "%-%tolgee:pq-bom-t1-product-process-in-house%-%",
        },
        {
          value: "subcontractor",
          text: "%-%tolgee:pq-bom-t1-product-process-subcontractor%-%",
        },
      ],
    },
    {
      type: "dropdown",
      renderAs: "suppliers-combobox-question",
      title:
        "%-%tolgee:product-process-garment-cutting-subcontractor-name-title%-%",
      name: Processes.GARMENT_CUTTING + SUBCONTRACTOR_NAME_SUFFIX,
      isRequired: true,
      placeholder: "%-%tolgee:type-supplier-name%-%",
      visibleIf:
        "{composite." +
        Processes.GARMENT_CUTTING +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'subcontractor'",
      maxWidth: "66%",
      allowClear: true,
    },
    {
      type: "boolean",
      name: Processes.GARMENT_SEWING,
      title: "%-%tolgee:product-process-garment-sewing-title%-%",
      titleLocation: "hidden",
      renderAs: "boolean-as-checkbox-question",
      maxWidth: "33%",
    },
    {
      type: "radiogroup",
      name: Processes.GARMENT_SEWING + SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX,
      titleLocation: "hidden",
      renderAs: "radio-group-inline-question",
      isRequired: true,
      enableIf: "{composite." + Processes.GARMENT_SEWING + "} == true",
      resetValueExpression:
        "{composite." + Processes.GARMENT_SEWING + "} <> true",
      resetValueIf: "{composite." + Processes.GARMENT_SEWING + "} <> true",
      startWithNewLine: false,
      maxWidth: "33%",
      choices: [
        {
          value: "in-house",
          text: "%-%tolgee:pq-bom-t1-product-process-in-house%-%",
        },
        {
          value: "subcontractor",
          text: "%-%tolgee:pq-bom-t1-product-process-subcontractor%-%",
        },
      ],
    },
    {
      type: "dropdown",
      renderAs: "suppliers-combobox-question",
      title:
        "%-%tolgee:product-process-garment-sewing-subcontractor-name-title%-%",
      name: Processes.GARMENT_SEWING + SUBCONTRACTOR_NAME_SUFFIX,
      placeholder: "%-%tolgee:type-supplier-name%-%",
      isRequired: true,
      visibleIf:
        "{composite." +
        Processes.GARMENT_SEWING +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'subcontractor'",
      maxWidth: "66%",
      allowClear: true,
    },
    {
      type: "boolean",
      name: Processes.GARMENT_BONDING,
      title: "%-%tolgee:product-process-garment-bonding-title%-%",
      titleLocation: "hidden",
      renderAs: "boolean-as-checkbox-question",
      maxWidth: "33%",
    },
    {
      type: "radiogroup",
      name: Processes.GARMENT_BONDING + SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX,
      titleLocation: "hidden",
      renderAs: "radio-group-inline-question",
      isRequired: true,
      enableIf: "{composite." + Processes.GARMENT_BONDING + "} == true",
      resetValueExpression:
        "{composite." + Processes.GARMENT_BONDING + "} <> true",
      resetValueIf: "{composite." + Processes.GARMENT_BONDING + "} <> true",
      startWithNewLine: false,
      maxWidth: "33%",
      choices: [
        {
          value: "in-house",
          text: "%-%tolgee:pq-bom-t1-product-process-in-house%-%",
        },
        {
          value: "subcontractor",
          text: "%-%tolgee:pq-bom-t1-product-process-subcontractor%-%",
        },
      ],
    },
    {
      type: "dropdown",
      renderAs: "suppliers-combobox-question",
      title:
        "%-%tolgee:product-process-garment-bonding-subcontractor-name-title%-%",
      name: Processes.GARMENT_BONDING + SUBCONTRACTOR_NAME_SUFFIX,
      placeholder: "%-%tolgee:type-supplier-name%-%",
      isRequired: true,
      visibleIf:
        "{composite." +
        Processes.GARMENT_BONDING +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'subcontractor'",
      maxWidth: "66%",
      allowClear: true,
    },
    {
      type: "boolean",
      name: Processes.GARMENT_EMBROIDERY,
      title: "%-%tolgee:product-process-garment-embroidery-title%-%",
      titleLocation: "hidden",
      renderAs: "boolean-as-checkbox-question",
      maxWidth: "33%",
    },
    {
      type: "radiogroup",
      name: Processes.GARMENT_EMBROIDERY + SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX,
      titleLocation: "hidden",
      renderAs: "radio-group-inline-question",
      isRequired: true,
      enableIf: "{composite." + Processes.GARMENT_EMBROIDERY + "} == true",
      resetValueExpression:
        "{composite." + Processes.GARMENT_EMBROIDERY + "} <> true",
      resetValueIf: "{composite." + Processes.GARMENT_EMBROIDERY + "} <> true",
      startWithNewLine: false,
      maxWidth: "33%",
      choices: [
        {
          value: "in-house",
          text: "%-%tolgee:pq-bom-t1-product-process-in-house%-%",
        },
        {
          value: "subcontractor",
          text: "%-%tolgee:pq-bom-t1-product-process-subcontractor%-%",
        },
      ],
    },
    {
      type: "dropdown",
      renderAs: "suppliers-combobox-question",
      title:
        "%-%tolgee:product-process-garment-embroidery-subcontractor-name-title%-%",
      name: Processes.GARMENT_EMBROIDERY + SUBCONTRACTOR_NAME_SUFFIX,
      placeholder: "%-%tolgee:type-supplier-name%-%",
      isRequired: true,
      visibleIf:
        "{composite." +
        Processes.GARMENT_EMBROIDERY +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'subcontractor'",
      resetValueIf: `{composite.${
        Processes.GARMENT_EMBROIDERY
      }} <> true or {composite.${
        Processes.GARMENT_EMBROIDERY + SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX
      }} = 'in-house'`,
      maxWidth: "66%",
      allowClear: true,
    },
    {
      type: "matrixdynamic",
      name: "materialBillProcessGarmentMatrixSubcontractor",
      titleLocation: "hidden",
      displayMode: "table",
      maxWidth: "66%",
      isRemoteRemoveAllowed: false,
      visibleIf:
        "{composite." +
        Processes.GARMENT_EMBROIDERY +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'subcontractor'",
      resetValueIf:
        "{composite." +
        Processes.GARMENT_EMBROIDERY +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} <> 'subcontractor'",
      maxRowCount: 10,
      minRowCount: 1,
      rowCount: 1,
      addRowText: "%-%tolgee:add-embroidery-button%-%",
      columns: [
        {
          id: "embroidery-size-dropdown",
          name: "materialBillProcessGarmentAverageSize",
          cellType: "dropdown",
          placeholder: "%-%tolgee:product-process-garment-embroidery-size%-%",
          resetValueIf:
            "{composite." +
            Processes.GARMENT_EMBROIDERY +
            SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
            "} <> 'subcontractor'",
          renderAs: "dropdown-question",
          title: " ",
          allowClear: true,
          choicesByUrl: {
            url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_garment_embroidery_average_size",
            valueName: "value",
            titleName: "title",
          },
          minWidth: "100px",
        },
      ],
    },
    {
      type: "matrixdynamic",
      name: "materialBillProcessGarmentMatrixInHouse",
      titleLocation: "hidden",
      displayMode: "table",
      maxWidth: "66%",
      isRemoteRemoveAllowed: false,
      visibleIf:
        "{composite." +
        Processes.GARMENT_EMBROIDERY +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'in-house'",
      resetValueIf:
        "{composite." +
        Processes.GARMENT_EMBROIDERY +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} <> 'in-house'",
      maxRowCount: 10,
      minRowCount: 1,
      rowCount: 1,
      addRowText: "%-%tolgee:add-embroidery-button%-%",
      columns: [
        {
          name: "materialBillProcessGarmentAverageSize",
          cellType: "dropdown",
          title: " ",
          placeholder: "%-%tolgee:product-process-garment-embroidery-size%-%",
          resetValueIf:
            "{composite." +
            Processes.GARMENT_EMBROIDERY +
            SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
            "} <> 'in-house'",
          renderAs: "dropdown-question",
          allowClear: true,
          choicesByUrl: {
            url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_garment_embroidery_average_size",
            valueName: "value",
            titleName: "title",
          },
          minWidth: "100px",
        },
      ],
    },
    {
      type: "boolean",
      name: Processes.GARMENT_PRINT,
      title: "%-%tolgee:product-process-garment-print-title%-%",
      titleLocation: "hidden",
      renderAs: "boolean-as-checkbox-question",
      maxWidth: "33%",
    },
    {
      type: "radiogroup",
      name: Processes.GARMENT_PRINT + SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX,
      titleLocation: "hidden",
      renderAs: "radio-group-inline-question",
      isRequired: true,
      startWithNewLine: false,
      enableIf: "{composite." + Processes.GARMENT_PRINT + "} = true",
      resetValueExpression:
        "{composite." + Processes.GARMENT_PRINT + "} <> true",
      resetValueIf: "{composite." + Processes.GARMENT_PRINT + "} <> true",
      maxWidth: "33%",
      choices: [
        {
          value: "in-house",
          text: "%-%tolgee:pq-bom-t1-product-process-in-house%-%",
        },
        {
          value: "subcontractor",
          text: "%-%tolgee:pq-bom-t1-product-process-subcontractor%-%",
        },
      ],
    },
    {
      type: "dropdown",
      renderAs: "suppliers-combobox-question",
      title:
        "%-%tolgee:product-process-garment-print-subcontractor-name-title%-%",
      name: Processes.GARMENT_PRINT + SUBCONTRACTOR_NAME_SUFFIX,
      placeholder: "%-%tolgee:type-supplier-name%-%",
      isRequired: true,
      visibleIf:
        "{composite." +
        Processes.GARMENT_PRINT +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'subcontractor'",
      maxWidth: "66%",
      allowClear: true,
    },
    {
      type: "matrixdynamic",
      name: "materialBillProcessGarmentPrintMatrixSubcontractor",
      titleLocation: "hidden",
      displayMode: "table",
      isRemoteRemoveAllowed: false,
      addRowText: "%-%tolgee:add-print-button%-%",
      visibleIf:
        "{composite." +
        Processes.GARMENT_PRINT +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'subcontractor'",
      resetValueIf:
        "{composite." +
        Processes.GARMENT_PRINT +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} <> 'subcontractor'",
      maxRowCount: 10,
      minRowCount: 1,
      rowCount: 1,
      maxWidth: "66%",
      columns: [
        {
          name: "materialBillProcessGarmentPrintType",
          cellType: "dropdown",
          placeholder: "%-%tolgee:product-process-garment-print-type%-%",
          resetValueIf:
            "{composite." +
            Processes.GARMENT_PRINT +
            SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
            "} <> 'subcontractor'",
          renderAs: "dropdown-question",
          title: " ",
          allowClear: true,
          choicesByUrl: {
            url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_garment_print_type",
            valueName: "value",
            titleName: "title",
          },
          minWidth: "100px",
        },
        {
          name: "materialBillProcessGarmentPrintAverageSize",
          cellType: "dropdown",
          placeholder:
            "%-%tolgee:product-process-garment-print-average-size%-%",
          resetValueIf:
            "{composite." +
            Processes.GARMENT_PRINT +
            SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
            "} <> 'subcontractor'",
          renderAs: "dropdown-question",
          title: " ",
          allowClear: true,
          choicesByUrl: {
            url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_garment_print_average_size",
            valueName: "value",
            titleName: "title",
          },
          minWidth: "100px",
        },
      ],
    },
    {
      type: "matrixdynamic",
      name: "materialBillProcessGarmentPrintMatrixInHouse",
      titleLocation: "hidden",
      displayMode: "table",
      isRemoteRemoveAllowed: false,
      addRowText: "%-%tolgee:add-print-button%-%",
      visibleIf:
        "{composite." +
        Processes.GARMENT_PRINT +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'in-house'",
      resetValueIf:
        "{composite." +
        Processes.GARMENT_PRINT +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} <> 'in-house'",
      maxRowCount: 10,
      minRowCount: 1,
      rowCount: 1,
      maxWidth: "66%",
      columns: [
        {
          name: "materialBillProcessGarmentPrintType",
          cellType: "dropdown",
          placeholder: "%-%tolgee:product-process-garment-print-type%-%",
          resetValueIf:
            "{composite." +
            Processes.GARMENT_PRINT +
            SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
            "} <> 'in-house'",
          renderAs: "dropdown-question",
          title: " ",
          allowClear: true,
          choicesByUrl: {
            url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_garment_print_type",
            valueName: "value",
            titleName: "title",
          },
          minWidth: "100px",
        },

        {
          name: "materialBillProcessGarmentPrintAverageSize",
          cellType: "dropdown",
          placeholder:
            "%-%tolgee:product-process-garment-print-average-size%-%",
          resetValueIf:
            "{composite." +
            Processes.GARMENT_PRINT +
            SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
            "} <> 'in-house'",
          renderAs: "dropdown-question",
          title: " ",
          allowClear: true,
          choicesByUrl: {
            url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_garment_print_average_size",
            valueName: "value",
            titleName: "title",
          },
          minWidth: "100px",
        },
      ],
    },
    {
      type: "boolean",
      name: Processes.GARMENT_WASHING_FINISHING,
      title: "%-%tolgee:product-process-garment-washing-finishing-title%-%",
      titleLocation: "hidden",
      renderAs: "boolean-as-checkbox-question",
      maxWidth: "33%",
    },
    {
      type: "radiogroup",
      name:
        Processes.GARMENT_WASHING_FINISHING + SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX,
      titleLocation: "hidden",
      renderAs: "radio-group-inline-question",
      isRequired: true,
      startWithNewLine: false,
      maxWidth: "33%",
      enableIf:
        "{composite." + Processes.GARMENT_WASHING_FINISHING + "} = true",
      resetValueExpression:
        "{composite." + Processes.GARMENT_WASHING_FINISHING + "} <> true",
      resetValueIf:
        "{composite." + Processes.GARMENT_WASHING_FINISHING + "} <> true",
      choices: [
        {
          value: "in-house",
          text: "%-%tolgee:pq-bom-t1-product-process-in-house%-%",
        },
        {
          value: "subcontractor",
          text: "%-%tolgee:pq-bom-t1-product-process-subcontractor%-%",
        },
      ],
    },
    {
      type: "dropdown",
      renderAs: "suppliers-combobox-question",
      title:
        "%-%tolgee:product-process-garment-washing-finishing-subcontractor-name-title%-%",
      name: Processes.GARMENT_WASHING_FINISHING + SUBCONTRACTOR_NAME_SUFFIX,
      placeholder: "%-%tolgee:type-supplier-name%-%",
      isRequired: true,
      visibleIf:
        "{composite." +
        Processes.GARMENT_WASHING_FINISHING +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'subcontractor'",
      maxWidth: "65%",
      allowClear: true,
    },
    {
      type: "matrixdynamic",
      name: "materialBillProcessGarmentWashingProcess",
      titleLocation: "hidden",
      displayMode: "table",
      isRemoteRemoveAllowed: false,
      addRowText: "%-%tolgee:add-washing-finishing-button%-%",
      visibleIf:
        "{composite." +
        Processes.GARMENT_WASHING_FINISHING +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'subcontractor' or {composite." +
        Processes.GARMENT_WASHING_FINISHING +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'in-house'",
      resetValueIf:
        "{composite." + Processes.GARMENT_WASHING_FINISHING + "} <> true",
      maxRowCount: 10,
      minRowCount: 1,
      rowCount: 1,
      maxWidth: "66%",
      columns: [
        {
          name: "productProcessGarmentWashingFinishingType",
          cellType: "dropdown",
          placeholder:
            "%-%tolgee:product-process-garment-washing-finishing-type%-%",
          renderAs: "dropdown-question",
          titleLocation: "hidden",
          allowClear: true,
          choicesByUrl: {
            url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_garment_washing_finishing",
            valueName: "value",
            titleName: "title",
          },
        },
      ],
    },
    {
      type: "boolean",
      name: Processes.GARMENT_DYEING,
      title: "%-%tolgee:product-process-garment-dyeing-title%-%",
      titleLocation: "hidden",
      renderAs: "boolean-as-checkbox-question",
      maxWidth: "33%",
    },
    {
      type: "radiogroup",
      name: Processes.GARMENT_DYEING + SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX,
      titleLocation: "hidden",
      renderAs: "radio-group-inline-question",
      isRequired: true,
      startWithNewLine: false,
      enableIf: "{composite." + Processes.GARMENT_DYEING + "} = true",
      resetValueExpression:
        "{composite." + Processes.GARMENT_DYEING + "} <> true",
      resetValueIf: "{composite." + Processes.GARMENT_DYEING + "} <> true",
      maxWidth: "33%",
      choices: [
        {
          value: "in-house",
          text: "%-%tolgee:pq-bom-t1-product-process-in-house%-%",
        },
        {
          value: "subcontractor",
          text: "%-%tolgee:pq-bom-t1-product-process-subcontractor%-%",
        },
      ],
    },
    {
      type: "dropdown",
      renderAs: "suppliers-combobox-question",
      name: Processes.GARMENT_DYEING + SUBCONTRACTOR_NAME_SUFFIX,
      title:
        "%-%tolgee:product-process-garment-dyeing-subcontractor-name-title%-%",
      placeholder: "%-%tolgee:type-supplier-name%-%",
      isRequired: true,
      visibleIf:
        "{composite." +
        Processes.GARMENT_DYEING +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'subcontractor'",
      maxWidth: "66%",
      allowClear: true,
    },
    {
      type: "matrixdynamic",
      name: "materialBillProcessGarmentDyeingProcess",
      titleLocation: "hidden",
      displayMode: "table",
      isRemoteRemoveAllowed: false,
      addRowText: "%-%tolgee:add-dyeing-button%-%",
      visibleIf:
        "{composite." +
        Processes.GARMENT_DYEING +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'subcontractor' or {composite." +
        Processes.GARMENT_DYEING +
        SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX +
        "} = 'in-house'",
      resetValueIf: "{composite." + Processes.GARMENT_DYEING + "} <> true",
      maxRowCount: 10,
      minRowCount: 1,
      rowCount: 1,
      maxWidth: "66%",
      columns: [
        {
          name: "productProcessGarmentDyeingType",
          cellType: "dropdown",
          placeholder: "%-%tolgee:product-process-garment-dyeing-type%-%",
          renderAs: "dropdown-question",
          titleLocation: "hidden",
          allowClear: true,
          choicesByUrl: {
            url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_garment_dyeing_type",
            valueName: "value",
            titleName: "title",
          },
        },
        {
          name: "productProcessGarmentDyeingStuff",
          cellType: "dropdown",
          placeholder: "%-%tolgee:product-process-garment-dyeing-dyestuff%-%",
          renderAs: "dropdown-question",
          titleLocation: "hidden",
          startWithNewLine: "false",
          allowClear: true,
          choicesByUrl: {
            url: "https://api-dev.made2flow.com/questionnaire/facility-questionnaire/common-choices/fbom_t1_garment_dyeing_dyestuff",
            valueName: "value",
            titleName: "title",
          },
        },
      ],
    },
  ],
  onInit: () => {
    Serializer.addProperty(QUESTION_TYPE_NAME, {
      name: "tsPrefix",
      default: "",
    });
  },
  onLoaded(question) {
    const defaultStore = getDefaultStore();
    const productKey = question.getPropertyValue("forProductKey");
    const tsPrefix = question.getPropertyValue("tsPrefix");
    const questions = question.contentPanel.getQuestions(true);
    const survey = question.survey as SurveyModel;

    // Set up CSS classes handling
    if (survey) {
      survey.onUpdateQuestionCssClasses.add(
        (
          sender: unknown,
          { question, cssClasses }: { question: Question; cssClasses: any }
        ) => {
          const contentElement = cssClasses.content;
          const isBillOfProcesses =
            contentElement.includes("bill-of-processes");
          const isNameBillOfProcesses =
            question.name.includes("bill-of-processes");
          if (isNameBillOfProcesses && !isBillOfProcesses) {
            cssClasses.content += " bill-of-processes";
          }

          const isGarmentEmbroiderySubcontractorName = question.name.includes(
            "garmentEmbroiderySubcontractorName"
          );
          const isGarmentEmbroiderySubcontractor = contentElement.includes(
            "garment-embroidery-subcontractor"
          );
          if (
            isGarmentEmbroiderySubcontractorName &&
            !isGarmentEmbroiderySubcontractor
          ) {
            cssClasses.content += " garment-embroidery-subcontractor";
          }

          const isGarmentWashingFinishingSubcontractorName =
            question.name.includes("garmentWashingFinishingSubcontractorName");
          const isGarmentWashingFinishingSubcontractor =
            contentElement.includes("garment-washing-finishing-subcontractor");
          if (
            isGarmentWashingFinishingSubcontractorName &&
            !isGarmentWashingFinishingSubcontractor
          ) {
            cssClasses.content += " garment-washing-finishing-subcontractor";
          }

          const isGarmentPrintSubcontractorName = question.name.includes(
            "garmentPrintSubcontractorName"
          );
          const isGarmentPrintSubcontractor = contentElement.includes(
            "garment-print-subcontractor"
          );
          if (isGarmentPrintSubcontractorName && !isGarmentPrintSubcontractor) {
            cssClasses.content += " garment-print-subcontractor";
          }

          const isGarmentDyeingSubcontractorName = question.name.includes(
            "garmentDyeingSubcontractorName"
          );
          const isGarmentDyeingSubcontractor = contentElement.includes(
            "garment-dyeing-subcontractor"
          );
          if (
            isGarmentDyeingSubcontractorName &&
            !isGarmentDyeingSubcontractor
          ) {
            cssClasses.content += " garment-dyeing-subcontractor";
          }
        }
      );
    }

    if (tsPrefix) {
      questions.forEach((question: Question) => {
        const title = question.title;
        const placeholder = question.placeholder;
        if (!title) return;

        let prefixTitle = "";
        if (title.includes("tolgee:")) {
          prefixTitle = title.replace(
            /(%-%tolgee:)(.+?)(%-%)/,
            `$1${tsPrefix}-$2$3`
          );
          question.title = prefixTitle;
        }
        if (placeholder && placeholder.includes("tolgee:")) {
          question.placeholder = placeholder.replace(
            /(%-%tolgee:)(.+?)(%-%)/,
            `$1${tsPrefix}-$2$3`
          );
        }

        if (isMatrixDropdown(question) || isMatrixDynamic(question)) {
          question.addRowText = question.addRowText.replace(
            /(%-%tolgee:)(.+?)(%-%)/,
            `$1${tsPrefix}-$2$3`
          );
          const columns = question.columns as any[];
          columns.forEach((column) => {
            if (column.title && column.title.includes("tolgee:")) {
              column.title = column.title.replace(
                /(%-%tolgee:)(.+?)(%-%)/,
                `$1${tsPrefix}-$2$3`
              );
            }
            if (column.placeholder && column.placeholder.includes("tolgee:")) {
              column.placeholder = column.placeholder.replace(
                /(%-%tolgee:)(.+?)(%-%)/,
                `$1${tsPrefix}-$2$3`
              );
            }
          });
        }
      });
    }

    defaultStore.set(productsProcessesAtom, (productsProcesses) => {
      if (
        !productsProcesses.find(
          (productProcesses) => productProcesses.productKey === productKey
        )
      ) {
        return [
          ...productsProcesses,
          {
            productKey,
            processes: [],
          },
        ];
      }
      return productsProcesses;
    });
  },
  onSetQuestionValue(question, newValue: IBillOfProcessesValue) {
    const defaultStore = getDefaultStore();
    const productKey = question.getPropertyValue("forProductKey");
    const productProcesses = focusAtom(productsProcessesAtom, (optic) =>
      optic.find((p) => p.productKey === productKey).prop("processes")
    );

    if (productProcesses) {
      defaultStore.set(
        productProcesses,
        convertBillOfProcessesValueToProcesses(newValue)
      );
    }

    defaultStore.set(suppliersAtom, (suppliers) => {
      const newSuppliers = Object.values(Processes).reduce((acc, process) => {
        const isProcessIncluded = newValue[process];
        const isSubcontractor =
          newValue[process + SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX] ===
          "subcontractor";
        const supplierName = newValue[process + SUBCONTRACTOR_NAME_SUFFIX];
        if (!supplierName || supplierName === "") return acc;
        const isIncluded = acc?.find(
          (supplier) => supplier.name === supplierName
        );
        return !isIncluded && isProcessIncluded && isSubcontractor
          ? [...acc, { name: supplierName }]
          : acc;
      }, suppliers);
      return newSuppliers;
    });
  },
};

function convertBillOfProcessesValueToProcesses(
  value: IBillOfProcessesValue
): IProcess[] {
  const processes: IProcess[] = [];

  Object.values(Processes).forEach((process) => {
    if (value[process]) {
      processes.push({
        name: process,
        includeInBillOfProcesses: value[process],
        subcontractorName: value[process + SUBCONTRACTOR_NAME_SUFFIX],
        inHouseOrSubcontractor:
          value[process + SUBCONTRACTOR_OR_IN_HOUSE_SUFFIX],
      });
    }
  });

  return processes;
}

export default billOfProcessesQuestion;
