/**
 * Note: Component has a console key warning error. Radix ui doesn't support correctly the key prop.
 *
 * */
import * as Select from "@radix-ui/react-select";
import { Check, ChevronDown } from "../../icons";
import { ReactElement, forwardRef } from "react";
import { useBreakpoint } from "../../../hooks";
import cn from "../../../utils/cn";

type DropdownProps = {
  items?: {
    value: string | undefined;
    title: string | ReactElement;
    disabled?: boolean;
  }[];
  dense?: boolean;
} & Select.SelectProps &
  Select.SelectValueProps;

export function Dropdown({
  items,
  placeholder,
  value,
  className,
  dense = false,
  ...other
}: DropdownProps) {
  const { isAboveMd } = useBreakpoint("md");

  return (
    <Select.Root value={!value ? "" : value} {...other}>
      <Select.Trigger
        className={cn(
          "shrink-0 group disabled:bg-gray-50 inline-flex items-center justify-between text-start font-regular text-xs md:text-sm leading-[16px] md:leading-5 text-gray-500 shadow-xs py-2.5 px-[14px] rounded-lg border border-gray-300 bg-white focus:ring-4 focus:ring-indigo-100 focus:border-indigo-500 outline-none focus:text-gray-800",
          { "!text-gray-300": !value },
          className
        )}
      >
        <div id="selected-value" className="line-clamp-1">
          <Select.Value placeholder={placeholder} />
        </div>
        <Select.Icon>
          <ChevronDown
            color={other.disabled ? "var(--gray-300)" : "var(--gray-500)"}
            size={isAboveMd ? 20 : 18}
            className="group-data-[state=open]:rotate-180"
          />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          position="popper"
          className="z-50 overflow-hidden bg-white border border-gray-100 rounded-lg shadow-lg w-[var(--radix-select-trigger-width)] max-h-[var(--radix-select-content-available-height)]"
        >
          <Select.Viewport>
            {items?.map((item, index) => (
              <SelectItem
                key={item.value + "-" + index}
                value={item.value}
                dense={dense}
                disabled={item.disabled}
              >
                {item.title}
              </SelectItem>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}

const SelectItem = forwardRef(
  ({ children, dense, className, disabled, ...props }: any, forwardedRef) => {
    return (
      <Select.Item
        key={props.key}
        className={cn(
          "flex items-center justify-between gap-2 relative data-[disabled]:pointer-events-none data-[disabled]:opacity-50 data-[highlighted]:outline-none data-[highlighted]:bg-gray-50 w-full py-2 px-[14px] cursor-pointer font-medium text-gray-800 text-xs md:text-sm leading-[16px] md:leading-5 min-h-[38px] md:min-h-[40px]",
          className
        )}
        ref={forwardedRef}
        disabled={disabled}
        {...props}
      >
        <Select.ItemText>{children}</Select.ItemText>

        <Select.ItemIndicator>
          <Check size={20} color="var(--primary-600)" />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);
