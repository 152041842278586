import cn from "../../../utils/cn";
import { useBreakpoint } from "../../../hooks";
import * as Dialog from "@radix-ui/react-dialog";
import { T } from "@tolgee/react";
import * as Icon from "../../icons";

interface ProductPageExplainerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  className?: string;
}

const ProductPageExplainer = ({
  open,
  setOpen,
  className,
}: ProductPageExplainerProps) => {
  const { isAboveMd } = useBreakpoint("md");

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild id="explainer-dialog-trigger">
        <button
          className={cn(
            "flex items-center whitespace-normal w-fit gap-2.5 overflow-hidden rounded-lg outline-none appearance-none select-none bg-mandarin-50 p-2 md:py-2.5 md:px-5",
            className
          )}
        >
          <Icon.HelpCircle
            className="shrink-0"
            color="var(--mandarin-600)"
            size={isAboveMd ? 20 : 14}
          />
          <span className="font-medium leading-[18px] md:leading-6 truncate text-xs md:text-base text-mandarin-900 text-wrap">
            <T keyName="pq-bom-t1-product-page-instructions-modal-trigger-button-text" />
          </span>
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-40 bg-indigo-900 opacity-50" />
        <Dialog.Content className="z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] shadow-xl">
          <div className="flex flex-col gap-5 overflow-hidden bg-indigo-25 rounded-[6px]">
            <div className="flex items-center justify-between p-5 bg-mandarin-50">
              <div className="flex items-center gap-2.5">
                <Icon.HelpCircle className="text-mandarin-600" size={20} />
                <div className="text-base font-medium text-mandarin-900">
                  <T keyName="pq-bom-t1-product-page-instructions-modal-title" />
                </div>
              </div>
              <Dialog.Close
                aria-label="Close"
                className="outline-none appearance-none"
              >
                <Icon.X color="var(--gray-400)" />
              </Dialog.Close>
            </div>
            <div className="flex flex-col gap-5 px-5 py-5 pt-0">
              <div className="flex flex-col gap-[5px]">
                <div className="text-sm font-medium text-gray-800">
                  <T keyName="pq-bom-t1-product-page-instructions-modal-content-1-title" />
                </div>
                <div className="font-normal text-gray-700 text-xxs">
                  <T keyName="pq-bom-t1-product-page-instructions-modal-content-1-text" />
                </div>
              </div>
              <img
                src="/images/pq-bom-t1/product_explainer_gif_1.gif"
                alt="product-explainer-gif-1"
              />
              <div className="flex flex-col gap-[5px]">
                <div className="text-sm font-medium text-gray-800">
                  <T keyName="pq-bom-t1-product-page-instructions-modal-content-2-title" />
                </div>
                <div className="font-normal text-gray-700 text-xxs">
                  <T keyName="pq-bom-t1-product-page-instructions-modal-content-2-text" />
                </div>
              </div>
              <img
                src="/images/pq-bom-t1/product_explainer_gif_2.gif"
                alt="product-explainer-gif-2"
              />
              <div className="flex flex-row justify-between gap-5">
                <div className="flex flex-col gap-[5px]">
                  <div className="text-sm font-medium text-gray-800">
                    <T keyName="pq-bom-t1-product-page-instructions-modal-content-3-title" />
                  </div>
                  <div className="font-normal text-gray-700 text-xxs">
                    <T keyName="pq-bom-t1-product-page-instructions-modal-content-3-text" />
                  </div>
                </div>
                <Dialog.Close className="self-end bg-indigo-500 py-2 px-[14px] h-fit rounded-lg select-none text-sm font-medium text-white leading-5 appearance-none outline-none">
                  <T keyName="pq-bom-t1-product-page-instructions-modal-close-button-text" />
                </Dialog.Close>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ProductPageExplainer;
