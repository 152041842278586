import {
  ICustomQuestionTypeConfiguration,
  Question,
  Serializer,
} from "survey-core";
import { suppliersAtom } from "../../../../jotai-atoms";
import { focusAtom } from "jotai-optics";
import { getDefaultStore } from "jotai";

const QUESTION_TYPE_NAME = "contractor-form";
const QUESTION_TITLE_IN_BUILDER = "Contractor Form";
const DEFAULT_QUESTION_TITLE = "Contractor Form";
const LOCATION_API_URL = "https://api-dev.made2flow.com/kernel/location";

interface IContractorFormQuestion extends ICustomQuestionTypeConfiguration {}

const contractorFormQuestion: IContractorFormQuestion = {
  name: QUESTION_TYPE_NAME,
  title: QUESTION_TITLE_IN_BUILDER,
  defaultQuestionTitle: DEFAULT_QUESTION_TITLE,
  elementsJSON: [
    {
      type: "text",
      name: "name", // Amit: DO NOT CHANGE THIS NAME
      titleLocation: "hidden",
      visible: false,
    },
    {
      type: "text",
      name: "fullNameInEnglish", // Amit: DO NOT CHANGE THIS NAME
      titleLocation: "hidden",
      placeholder:
        "%-%tolgee:supplier-information-process-facility-name-placeholder%-%*",
      isRequired: true,
    },
    {
      type: "text",
      name: "fullNameInLocalLanguage", // Amit: DO NOT CHANGE THIS NAME
      titleLocation: "hidden",
      placeholder:
        "%-%tolgee:supplier-information-process-local-language-placeholder%-%",
      startWithNewLine: false,
    },
    {
      type: "text",
      name: "streetAddressPostalCode", // Amit: DO NOT CHANGE THIS NAME
      titleLocation: "hidden",
      placeholder:
        "%-%tolgee:supplier-information-process-address-placeholder%-%",
      startWithNewLine: false,
    },
    {
      type: "dropdown",
      renderAs: "combobox-question",
      name: "country", // Amit: DO NOT CHANGE THIS NAME
      titleLocation: "hidden",
      placeholder:
        "%-%tolgee:supplier-information-process-country-placeholder%-%*",
      isRequired: true,
      allowClear: false,
      choicesByUrl: {
        url: LOCATION_API_URL,
        valueName: "isoCode",
        titleName: "name",
      },
    },
    {
      type: "dropdown",
      renderAs: "combobox-question",
      name: "stateProvince", // Amit: DO NOT CHANGE THIS NAME
      titleLocation: "hidden",
      placeholder:
        "%-%tolgee:supplier-information-process-state-province-placeholder%-%*",
      isRequired: true,
      startWithNewLine: false,
      showOtherItem: true,
      allowClear: false,
      enableIf: "{composite.country} notempty",
      choicesByUrl: {
        url: `${LOCATION_API_URL}?countryCode={composite.country}`,
        valueName: "isoCode",
        titleName: "name",
        allowEmptyResponse: true,
      },
    },
    {
      type: "dropdown",
      name: "city", // Amit: DO NOT CHANGE THIS NAME
      renderAs: "combobox-question",
      titleLocation: "hidden",
      placeholder:
        "%-%tolgee:supplier-information-process-city-placeholder%-%*",
      isRequired: true,
      startWithNewLine: false,
      showOtherItem: true,
      allowClear: false,
      enableIf: "{composite.country} notempty",
      choicesByUrl: {
        url: `${LOCATION_API_URL}?countryCode={composite.country}&stateCode={composite.stateProvince}`,
        valueName: "name",
        titleName: "name",
        allowEmptyResponse: true,
      },
    },
    {
      type: "text",
      name: "emailAddress", // Amit: DO NOT CHANGE THIS NAME
      titleLocation: "hidden",
      placeholder:
        "%-%tolgee:supplier-information-process-email-address-placeholder%-%",
    },
    {
      type: "text",
      name: "phoneNumber", // Amit: DO NOT CHANGE THIS NAME
      titleLocation: "hidden",
      placeholder:
        "%-%tolgee:supplier-information-process-phone-number-placeholder%-%",
      startWithNewLine: false,
    },
    {
      type: "text",
      name: "mobileWeChatNumber", // Amit: DO NOT CHANGE THIS NAME
      titleLocation: "hidden",
      placeholder:
        "%-%tolgee:supplier-information-process-mobile-wechat-number-placeholder%-%",
      startWithNewLine: false,
    },
  ],
  onInit() {
    Serializer.addProperty(QUESTION_TYPE_NAME, {
      name: "supplierName",
      type: "string",
      default: "",
    });
    Serializer.addProperty(QUESTION_TYPE_NAME, {
      name: "isTemplateSupplierFormQuestion",
      type: "boolean",
      default: false,
    });
    Serializer.addProperty(QUESTION_TYPE_NAME, {
      name: "tsPrefix",
      default: "",
    });
  },
  onPropertyChanged(question, propertyName, newValue) {
    if (propertyName === "tsPrefix") {
      const questions = question.contentPanel.getQuestions(true);

      question.validators.forEach((validator) => {
        if (validator.text.includes("tolgee:")) {
          validator.text = validator.text.replace(
            /(%-%tolgee:)(.+?)(%-%)/,
            `$1${newValue}-$2$3`
          );
        }
      });

      questions.forEach((question: Question) => {
        const title = question.title;
        const placeholder = (question as any).placeholder;

        // Handle title
        if (title && title.includes("tolgee:")) {
          question.title = title.replace(
            /(%-%tolgee:)(.+?)(%-%)/,
            `$1${newValue}-$2$3`
          );
        }

        // Handle placeholder
        if (placeholder && placeholder.includes("tolgee:")) {
          (question as any).placeholder = placeholder.replace(
            /(%-%tolgee:)(.+?)(%-%)/,
            `$1${newValue}-$2$3`
          );
        }
      });
    }
  },
  onValueChanged(question) {
    const defaultStore = getDefaultStore();
    const supplierAtom = focusAtom(suppliersAtom, (optic) =>
      optic.find((supplier) => supplier.name === question.name)
    );

    const supplier = defaultStore.get(supplierAtom);

    if (supplier) {
      defaultStore.set(supplierAtom, question.value);
    }
  },
};

export default contractorFormQuestion;
