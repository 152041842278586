import { IconProps } from "react-feather";

export function GarmentPrints({
  size = 24,
  color = "black",
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width={size} height={size} fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7316 6.98673H10.6358V6.05015C10.6358 4.92183 9.77103 4 8.71991 4H6.91587C5.85729 4 5 4.92183 5 6.05015V9.18437C5 9.34661 5.11928 9.47935 5.27583 9.47935C5.43237 9.47935 5.55165 9.34661 5.55165 9.18437V6.05015C5.55165 5.24631 6.16294 4.5826 6.91587 4.5826H8.71991C9.47284 4.5826 10.0841 5.23894 10.0841 6.05015V15.5118C9.73376 15.1283 9.2492 14.8923 8.71991 14.8923H6.91587C6.40149 14.8923 5.92439 15.1062 5.55911 15.4971L5.5442 15.5118V11.8171C5.5442 11.6549 5.42492 11.5221 5.26837 11.5221C5.11182 11.5221 5 11.6622 5 11.8245V16.9499C5 18.0782 5.85729 19 6.91587 19H18.7316C18.8807 19 19.0075 18.8673 19.0075 18.705V7.28171C19.0075 7.11947 18.8882 6.98673 18.7316 6.98673ZM10.6358 16.9425V14.6047L14.1917 18.41H10.0618C10.4345 18.0192 10.6358 17.4956 10.6358 16.9425ZM5.55165 16.9425C5.55165 16.1313 6.16294 15.4749 6.91587 15.4749H8.71991C9.47284 15.4749 10.0841 16.1313 10.0841 16.9425C10.0841 17.7537 9.47284 18.41 8.71991 18.41H6.91587C6.16294 18.41 5.55165 17.7537 5.55165 16.9425ZM14.967 18.41L10.6358 13.7714V10.8732L17.6731 18.41H14.967ZM11.0383 7.56932L18.4633 15.5192V18.41H18.4558L10.6358 10.0472V7.5767H11.0309L11.0383 7.56932ZM18.4633 10.9617L15.295 7.56932H18.4633V10.9617ZM14.5197 7.56932L18.4633 11.795V14.6932L11.8062 7.5767H14.5122L14.5197 7.56932ZM8.99571 16.9498C8.99571 16.7876 8.87644 16.6548 8.71989 16.6548H6.91584C6.76675 16.6548 6.64001 16.7876 6.64001 16.9498C6.64001 17.1121 6.75929 17.2448 6.91584 17.2448H8.71989C8.86898 17.2448 8.99571 17.1121 8.99571 16.9498ZM5.55165 10.5044C5.55165 10.3422 5.42492 10.2094 5.27583 10.2094C5.11928 10.2094 5 10.3422 5 10.5044C5 10.6666 5.11928 10.7994 5.27583 10.7994C5.43237 10.7994 5.55165 10.6666 5.55165 10.5044Z"
        fill={color}
      />
    </svg>
  );
}
