import { FC, createElement, useMemo } from "react";
import { Question, Serializer } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { Dropdown } from "../../ui/dropdown";
import { useTranslate } from "@tolgee/react";
const MATERIAL_NUMBER_DROPDOWN_TYPE = "material-number-dropdown";
const FABRIC_COMPOSITION_MATERIAL_KEY =
  "pq-bop-t2-material-composition-percentage-fiber-composition";

// Define the custom question type
export class MaterialNumberDropdownQuestion extends Question {
  public numberOfMaterialsExpression: string = "";
  public materialKey: string = FABRIC_COMPOSITION_MATERIAL_KEY;
  private _placeholder: string = "";

  public getType(): string {
    return MATERIAL_NUMBER_DROPDOWN_TYPE;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
  }
}

// Register the question type and its properties
Serializer.addClass(
  MATERIAL_NUMBER_DROPDOWN_TYPE,
  [
    {
      name: "numberOfMaterialsExpression",
      default: "",
    },
    {
      name: "placeholder",
      default: "",
    },
    {
      name: "materialKey",
      default: FABRIC_COMPOSITION_MATERIAL_KEY,
    },
  ],
  function () {
    return new MaterialNumberDropdownQuestion("");
  },
  "question"
);

interface MaterialNumberDropdownProps {
  question: MaterialNumberDropdownQuestion;
}

const MaterialNumberDropdownComponent: FC<MaterialNumberDropdownProps> = ({
  question,
}) => {
  const { t } = useTranslate();
  const placeholder = question.placeholder || t("select-number-of-materials");
  /**
   * Get the unique number of materials
   * @returns {number} - The unique number of materials
   */
  const getUniqueNumberOfMaterials = () => {
    const expression = question.numberOfMaterialsExpression;
    const materials = question.survey?.runExpression(expression);
    const isArray = Array.isArray(materials) && materials.length > 0;
    if (isArray === false) {
      return 0;
    }

    // Count unique materials
    const uniqueMaterials = new Set(
      materials
        .map((item: any) => {
          const compositionKey = Object.keys(item).find((key) =>
            key.toLowerCase().includes(question.materialKey)
          );
          return compositionKey ? item[compositionKey] : undefined;
        })
        .filter(
          (material: any) =>
            material !== undefined &&
            material !== null &&
            material.trim() !== ""
        )
    );
    return uniqueMaterials.size;
  };

  const numberOfMaterials = getUniqueNumberOfMaterials();

  const items = useMemo(() => {
    const maxNumber = numberOfMaterials + 2;
    const items: {
      value: string | undefined;
      title: string;
    }[] = Array.from({ length: maxNumber }, (_, i) => ({
      value: (i + 1).toString(),
      title: (i + 1).toString(),
    }));
    items.unshift({
      value: "0",
      title: "",
    });
    return items;
  }, [numberOfMaterials]);

  const handleChange = (value: string | undefined) => {
    question.value = value ? parseInt(value, 10) : undefined;
  };

  return (
    <Dropdown
      items={items}
      className="w-[100px]"
      value={question.value?.toString()}
      onValueChange={handleChange}
      placeholder={placeholder}
      defaultValue={question.defaultValue}
      disabled={numberOfMaterials === 0 || question.isReadOnly}
      required={question.isRequired}
      aria-label="Select number of materials"
      aria-required={question.isRequired}
      aria-invalid={question.errors.length > 0}
      allow-clear={true}
      aria-errormessage={
        question.errors.length > 0 ? question.errors[0].getText() : undefined
      }
    />
  );
};

// Register the custom question component
ReactQuestionFactory.Instance.registerQuestion(
  MATERIAL_NUMBER_DROPDOWN_TYPE,
  (props: any) =>
    createElement(
      MaterialNumberDropdownComponent,
      props as MaterialNumberDropdownProps
    )
);
