import { FunctionFactory } from "survey-core";
import buildCompositionSummary from "./build-composition-summary";
import buildMaterialsInfoSummary from "./build-materials-info-summary";
import buildYarnSuppliersSummary from "./build-yarn-suppliers-summary";
import composeBomT1DialogQuestionTitle from "./compose-bom-t1-dialog-question-title";

FunctionFactory.Instance.register(
  "buildCompositionSummary",
  buildCompositionSummary,
  true
);

FunctionFactory.Instance.register(
  "buildMaterialsInfoSummary",
  buildMaterialsInfoSummary,
  true
);

FunctionFactory.Instance.register(
  "buildYarnSuppliersSummary",
  buildYarnSuppliersSummary,
  true
);

FunctionFactory.Instance.register(
  "composeBomT1DialogQuestionTitle",
  composeBomT1DialogQuestionTitle,
  true
);
