import {
  NavigationMenuItem,
  NavigationMenuItemProps,
} from "@radix-ui/react-navigation-menu";
import classNames from "classnames";
import { useBreakpoint } from "../../hooks";
import { AppIcon, NavItemLabel } from "../atoms";
import { MenuItem } from "../../types";

export const NavBarItem = ({
  item,
  disabled = false,
  active = false,
  ...props
}: {
  item: MenuItem;
  disabled?: boolean;
  active?: boolean;
} & NavigationMenuItemProps) => {
  const { isAboveMd } = useBreakpoint("md");
  return (
    <NavigationMenuItem
      className={classNames(
        "p-2.5 rounded-[5px]",
        disabled
          ? "bg-gray-50 border border-gray-300"
          : "cursor-pointer md:bg-white border border-indigo-100",
        {
          "border border-indigo-100 md:border-indigo-500 md:bg-white bg-indigo-500":
            active && !disabled,
        }
      )}
      {...props}
    >
      <div className="flex flex-col justify-center gap-3 shrink-0">
        <div className="flex items-center gap-2.5">
          {item.icon && (
            <AppIcon
              className="flex-none"
              name={item.icon}
              color={
                isAboveMd
                  ? "var(--gray-300)"
                  : active
                  ? "white"
                  : "var(--gray-300)"
              }
            />
          )}
          <div className="flex flex-col justify-center gap-1">
            <NavItemLabel
              className={
                active && !disabled
                  ? "text-white md:text-gray-800"
                  : "md:text-gray-300 text-gray-80"
              }
              label={item.title}
            />
          </div>
        </div>
      </div>
    </NavigationMenuItem>
  );
};
