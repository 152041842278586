/* eslint-disable no-process-env */
import { atom } from "jotai";
import { Question } from "survey-core";
import { QuestionExpressionModel } from "survey-core";
import {
  FacilityData,
  ISupplier,
  IProduct,
  MenuItem,
  IProductMaterials,
  IProductProcesses,
  ValidationPopupProperties,
} from "./types";
export const isNewQuestionnaireAtom = atom(false);
export const questionnaireNameAtom = atom("");
export const isMobileDrawerOpenAtom = atom(false);
export const uploadedFilesSetAtom = atom(new Set<string>());
export const sidebarContentAtom = atom<{ navigationItems: MenuItem[] }>({
  navigationItems: [],
});

//global language
export const languageQuestionAtom = atom<Question | undefined>(undefined);
export const explainerContentQuestionAtom = atom<
  QuestionExpressionModel | undefined
>(undefined);

export const completeTriesAtom = atom(0);

export const facilityDataAtom = atom<FacilityData>();

export const productsMaterialsAtom = atom<IProductMaterials[]>([]);

export const productsAtom = atom<IProduct[]>([]);

export const suppliersAtom = atom<ISupplier[]>([]);

export const productsProcessesAtom = atom<IProductProcesses[]>([]);

//this is used to store the number of times a page has been visited
export const productPageVisitCountAtom = atom(0);

//this is used to store the number of times a explainer dialog has been opened per page
export const explainerDialogOpenCountAtom = atom(0);

export const showValidationPopupAtom = atom(false);

export const validationPopupPropertiesAtom = atom<ValidationPopupProperties>(
  {}
);

/**
 * Iterate over all products materials and get the suppliers for each product material.
 */
export const suppliersByProductsMaterialsKeyAtom = atom((get) => {
  const suppliers = get(suppliersAtom);
  const productsMaterials = get(productsMaterialsAtom);
  const res = productsMaterials.map((productMaterials) => ({
    productKey: productMaterials.productKey,
    suppliers: suppliers.filter((supplier) =>
      productMaterials.materials.some((material) => {
        const suppliersSet = new Set();
        if (material.fabricSupplierName === supplier.name) {
          suppliersSet.add(supplier.name);
        }
        material.yarnSuppliers.forEach((yarnSupplier) => {
          if (yarnSupplier.name === supplier.name) {
            suppliersSet.add(supplier.name);
          }
        });
        return suppliersSet.size > 0;
      })
    ),
  }));
  return res;
});

/**
 * Iterate over all products processes and get the suppliers for each product process.
 */
export const suppliersByProductsProcessesKeyAtom = atom((get) => {
  const suppliers = get(suppliersAtom);
  const productsProcesses = get(productsProcessesAtom);
  const res = productsProcesses.map((productProcess) => ({
    productKey: productProcess.productKey,
    suppliers: suppliers.filter((supplier) =>
      productProcess.processes
        .filter(
          (process) =>
            process.includeInBillOfProcesses &&
            process.inHouseOrSubcontractor === "subcontractor"
        )
        .some((process) => process.subcontractorName === supplier.name)
    ),
  }));
  return res;
});

/**
 * Iterate over all products and get the suppliers for each product.
 *
 */
export const totalSuppliersByProductKeyAtom = atom((get) => {
  const products = get(productsAtom);
  const suppliersByProductsMaterialsKey = get(
    suppliersByProductsMaterialsKeyAtom
  );
  const suppliersByProductsProcessesKey = get(
    suppliersByProductsProcessesKeyAtom
  );
  const productAndSuppliers: Array<{
    productKey: string;
    suppliers: ISupplier[];
  }> = [];

  products.forEach((product) => {
    const suppliersSet = new Set<ISupplier>();
    const suppliers1 = suppliersByProductsMaterialsKey.find(
      (p) => p.productKey === product.key
    )?.suppliers;

    if (suppliers1) {
      suppliers1.forEach((supplier) => {
        suppliersSet.add(supplier);
      });
    }
    const suppliers2 = suppliersByProductsProcessesKey.find(
      (p) => p.productKey === product.key
    )?.suppliers;

    if (suppliers2) {
      suppliers2.forEach((supplier) => {
        suppliersSet.add(supplier);
      });
    }
    productAndSuppliers.push({
      productKey: product.key,
      suppliers: Array.from(suppliersSet),
    });
  });

  return productAndSuppliers;
});

/**
 * Iterate over all products and create a set of all suppliers from all products.
 */
export const selectedSuppliersAtom = atom((get) => {
  const totalSuppliersByProductKey = get(totalSuppliersByProductKeyAtom);
  const selectedSuppliers = new Map<string, ISupplier>();

  totalSuppliersByProductKey.forEach(({ suppliers }) => {
    suppliers.forEach((supplier) => {
      if (!selectedSuppliers.has(supplier.name)) {
        selectedSuppliers.set(supplier.name, supplier);
      }
    });
  });

  return Array.from(selectedSuppliers.values());
});

//only for development
if (process.env.NODE_ENV !== "production") {
  productsAtom.debugLabel = "productsAtom";
  productsMaterialsAtom.debugLabel = "productsMaterialsAtom";
  productsProcessesAtom.debugLabel = "productsProcessesAtom";
  suppliersAtom.debugLabel = "suppliersAtom";
  suppliersByProductsMaterialsKeyAtom.debugLabel =
    "suppliersByProductsMaterialsKeyAtom";
  suppliersByProductsProcessesKeyAtom.debugLabel =
    "suppliersByProductsProcessesKeyAtom";
  totalSuppliersByProductKeyAtom.debugLabel = "totalSuppliersByProductKeyAtom";
  facilityDataAtom.debugLabel = "facilityDataAtom";
  languageQuestionAtom.debugLabel = "languageQuestionAtom";
  explainerContentQuestionAtom.debugLabel = "explainerContentQuestionAtom";
  isNewQuestionnaireAtom.debugLabel = "isNewQuestionnaireAtom";
  questionnaireNameAtom.debugLabel = "questionnaireNameAtom";
  isMobileDrawerOpenAtom.debugLabel = "isMobileDrawerOpenAtom";
  uploadedFilesSetAtom.debugLabel = "uploadedFilesSetAtom";
  sidebarContentAtom.debugLabel = "sidebarContentAtom";
  completeTriesAtom.debugLabel = "completeTriesAtom";
  selectedSuppliersAtom.debugLabel = "selectedSuppliersAtom";
  showValidationPopupAtom.debugLabel = "showValidationPopupAtom";
  validationPopupPropertiesAtom.debugLabel = "validationPopupPropertiesAtom";
}
