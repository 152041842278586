import {
  ChangeEvent,
  FC,
  createElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { QuestionDropdownModel, RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { Dropdown } from "../../ui/dropdown";
import classNames from "classnames";
import { productsMaterialsAtom } from "../../../jotai-atoms";
import { useAtomValue } from "jotai";
import { countEmptyRows } from "../../../utils/bom-utils";

interface DropdownQuestionProps {
  isDisplayMode: boolean;
  question: QuestionDropdownModel;
}

const DropdownQuestion: FC<DropdownQuestionProps> = ({
  isDisplayMode,
  question,
}) => {
  const {
    value,
    isReadOnly,
    name,
    allowClear,
    isRequired,
    placeholder,
    otherItem,
    noneItem,
    hasOther,
    hasNone,
    isOtherSelected,
    commentPlaceHolder,
  } = question;
  const [textAreaInput, setTextAreaInput] = useState(question.comment);
  const productsMaterials = useAtomValue(productsMaterialsAtom);

  const emptyRowsCount = useMemo(() => {
    const materials = productsMaterials.find(
      (productMaterials) =>
        productMaterials.productKey ===
        question.getPropertyValue("forProductKey")
    )?.materials;
    return materials ? materials.length - countEmptyRows(materials) - 1 : 0;
  }, [productsMaterials, question]);

  const isDisabled = useMemo(() => {
    return isReadOnly;
  }, [isReadOnly]);

  const onValueChange = useCallback(
    (newVal: string) => {
      question.value = newVal;
    },
    [question]
  );

  const onCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaInput(event.target.value);
  };

  const onCommentBlur = () => {
    question.comment = textAreaInput;
  };

  const getItems = useMemo(() => {
    const items = question.dataChoices.map(({ title, value }, index) => ({
      title,
      value,
      disabled: index < emptyRowsCount,
    }));
    if (hasNone) items.push(noneItem);
    if (hasOther) items.push(otherItem);
    if (allowClear)
      items.unshift({ value: undefined, title: "", disabled: false });
    return items;
  }, [
    emptyRowsCount,
    question,
    hasNone,
    noneItem,
    hasOther,
    otherItem,
    allowClear,
  ]);

  return (
    <div className="flex flex-col gap-2">
      <Dropdown
        aria-required={question.ariaRequired}
        aria-label={question.ariaLabel}
        aria-invalid={question.ariaInvalid}
        aria-errormessage={question.ariaErrormessage}
        autoComplete={question.autocomplete}
        onClick={question.onClick}
        onKeyUp={question.onKeyUp}
        id={question.inputId}
        items={getItems}
        required={isRequired}
        disabled={isDisabled}
        value={value}
        name={name}
        dense
        onValueChange={onValueChange}
        placeholder={placeholder}
        defaultValue={question.getDefaultValue()}
      />
      {isOtherSelected && (
        <textarea
          disabled={isDisabled}
          onChange={onCommentChange}
          onBlur={onCommentBlur}
          className={classNames(
            "appearance-none static border-box bg-white w-full py-2 px-[14px] shadow-xs rounded-lg border border-gray-300 focus:text-gray-800 focus:border-indigo-500 focus:ring-4 focus:ring-indigo-100 outline-none disabled:bg-gray-50 disabled:text-gray-500 text-xs md:text-sm leading-[16px] md:leading-5 text-start font-regular text-gray-500 placeholder-gray-300"
          )}
          value={textAreaInput}
          placeholder={commentPlaceHolder}
        />
      )}
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-materials-count-dropdown-question",
  function (props: any) {
    return createElement(DropdownQuestion, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "dropdown",
  "materials-count-dropdown-question",
  "sv-dropdown-question"
);
