import { FC, createElement, useEffect, useState } from "react";
import { ExplainerDialog } from "../ui";
import { ReactQuestionFactory } from "survey-react-ui";
import {
  PageModel,
  QuestionExpressionModel,
  RendererFactory,
} from "survey-core";
import { AppIcon } from "../atoms";
import { useBreakpoint } from "../../hooks";
import { useAtomValue, useSetAtom } from "jotai";
import {
  explainerDialogOpenCountAtom,
  productPageVisitCountAtom,
} from "../../jotai-atoms";
import { toast } from "react-toastify";
import { ArrowUp } from "react-feather";
import ProductPageExplainer from "../ui/product-page-explainer";
interface PageTitleExpressionProps {
  question: QuestionExpressionModel;
}

export const PageTitleExpression: FC<PageTitleExpressionProps> = ({
  question,
}) => {
  const { title } = question;
  const page = question.page as PageModel;
  const [open, setOpen] = useState(false);
  const productPageVisitCount = useAtomValue(productPageVisitCountAtom);
  const setExplainerDialogOpenCount = useSetAtom(explainerDialogOpenCountAtom);
  const { isAboveMd } = useBreakpoint("md");
  const icon = question.getPropertyValue("icon", "");
  const withHelper: boolean = page.getPropertyValue("withHelper", false);
  const subtype = page.getPropertyValue("subtype", "");
  const infoToastText = page.getPropertyValue("infoToastText", "") as string;
  const openExplainerDialogOnFirst = question.getPropertyValue(
    "openExplainerDialogOnFirst",
    false
  );

  const Dialog =
    subtype === "productPage" ? ProductPageExplainer : ExplainerDialog;

  const handleOpenExplainerDialog = (newOpen: boolean) => {
    setOpen(newOpen);
    if (!newOpen && page.isActive) {
      setExplainerDialogOpenCount((prev) => {
        const count = prev + 1;
        if (count === 1 && productPageVisitCount === 1) {
          toast.info(infoToastText, {
            autoClose: false,
            icon: () => <ArrowUp className="text-warning-300" />,
            className: "top-[70px]",
          });
        }
        return count;
      });
    }
  };

  useEffect(() => {
    if (openExplainerDialogOnFirst && productPageVisitCount === 1) {
      setOpen(true);
    }
  }, [openExplainerDialogOnFirst, productPageVisitCount]);

  return (
    <div className="flex justify-between gap-5">
      <div className="flex items-center md:gap-[15px] gap-2">
        {icon && (
          <div className="grid w-[30px] h-[30px] shrink-0 md:w-10 md:h-10 border border-gray-100 rounded-full place-items-center">
            <AppIcon
              size={isAboveMd ? 24 : 18}
              color="var(--gray-400)"
              name={icon}
            />
          </div>
        )}
        <div className="text-sm font-medium text-gray-800 md:text-lg text-wrap">
          {title}
        </div>
      </div>
      {withHelper && <Dialog open={open} setOpen={handleOpenExplainerDialog} />}
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-page-title-expression",
  function (props: any) {
    return createElement(PageTitleExpression, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "page-title-expression",
  "sv-page-title-expression"
);
