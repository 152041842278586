const ERROR_FETCHING_COMPOSITIONS =
  "%-%tolgee:yarn-suppliers-error-fetching-compositions%-%";
const YARN_BLENDED_TEXT = "%-%tolgee:yarn-suppliers-yarn-blended%-%";
const YARN_BLENDED_VALUE = "RAW_MATERIAL#BLENDED";

/**
 * Builds a summary of the yarn suppliers selected in the matrix dynamics conditional.
 * @param {Object} params - The parameters for the function.
 * @param {Object} params[0] - The value object containing fabric supplier name, material reference number and yarn suppliers.
 * @param {string} params[1] - The URL for fetching composition texts.
 * @returns {string} The summary of the yarn suppliers.
 */
export default function buildYarnSuppliersSummary(params) {
  const value = params[0];
  const compositionUrl = params[1];

  if (!value || !compositionUrl) {
    this.returnResult("");
    return;
  }

  const fabricSupplier = value.fabricSupplierName;
  const materialRef = value.materialRefNumber;
  const yarnSuppliers = value.yarnSuppliers || [];

  fetch(compositionUrl)
    .then((response) => response.json())
    .then((compositions) => {
      const compositionMap = compositions.reduce(
        (acc, composition) => ({
          ...acc,
          [composition.value]: composition.text,
        }),
        {
          [YARN_BLENDED_VALUE]: YARN_BLENDED_TEXT,
        }
      );

      const parts = [];

      if (fabricSupplier) {
        parts.push(
          materialRef ? `${fabricSupplier} (${materialRef})` : fabricSupplier
        );
      }

      // Add yarn suppliers information with mapped composition texts
      const yarnSupplierParts = yarnSuppliers
        .filter((supplier) => supplier.name)
        .map((supplier) => {
          if (supplier.name && supplier.composition) {
            const compositionText =
              compositionMap[supplier.composition] || supplier.composition;
            return `${supplier.name} (${compositionText})`;
          }
          return supplier.name;
        });

      if (yarnSupplierParts.length > 0) {
        parts.push(...yarnSupplierParts);
      }

      const summary = parts.length > 0 ? parts.join(", ") : "";
      this.returnResult(summary);
    })
    .catch((error) => {
      this.returnResult(ERROR_FETCHING_COMPOSITIONS);
      console.error(ERROR_FETCHING_COMPOSITIONS, error);
    });
}
