import { ReactQuestionFactory } from "survey-react-ui";
import { QuestionExpressionModel, RendererFactory } from "survey-core";
import { createElement, FC } from "react";
import { useTranslate } from "@tolgee/react";
const title = "how-many-main-materials-expression";
const coverImage = "/images/pq-bom-t1/t1-overview-illustration.svg";
const description = "how-many-main-materials-expression-description";

interface MainMaterialsExpressionProps {
  question: QuestionExpressionModel;
}

const MainMaterialsExpression: FC<MainMaterialsExpressionProps> = ({
  question,
}) => {
  const { t } = useTranslate();

  return (
    <div className="w-full bg-slate-100 p-6 rounded-lg">
      <div className="font-semibold text-grey-800">{t(title)}</div>
      <div className="items-center">
        <img src={coverImage} alt={t(title)} />
      </div>
      <p className="pt-6">{t(description)}</p>
    </div>
  );
};

ReactQuestionFactory.Instance.registerQuestion(
  "sv-main-materials-expression",
  function (props: any) {
    return createElement(MainMaterialsExpression, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "expression",
  "main-materials-expression",
  "sv-main-materials-expression"
);
