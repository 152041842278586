import * as RadixCheckbox from "@radix-ui/react-checkbox";
import { FC, ReactNode } from "react";
import { Check } from "../../icons";
import classNames from "classnames";

type CheckboxProps = {
  label: string;
  Icon?: ReactNode;
  labelClassname?: string;
} & RadixCheckbox.CheckboxProps;

export const Checkbox: FC<CheckboxProps> = ({
  label,
  className,
  Icon,
  labelClassname,
  ...props
}) => {
  return (
    <div className="flex items-center gap-2 md:gap-2.5 overflow-hidden">
      <RadixCheckbox.Root
        className={classNames(
          `${props.checked
            ? "border-indigo-500 bg-indigo-50 disabled:border-gray-400"
            : "border-gray-300 bg-white disabled:border-gray-200"
          } shrink-0 appearance-none h-4 w-4 flex m-1 border rounded items-center justify-center outline-none focus:ring-4 focus:ring-indigo-100 focus:border-indigo-500 disabled:bg-gray-100`,
          className
        )}
        {...props}
      >
        <RadixCheckbox.Indicator className="rounded p-0.5">
          <Check
            size={12}
            color={`${props.disabled ? "var(--gray-400)" : "var(--indigo-500)"
              }`}
          />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      {Icon}
      <label
        className={`text-xs md:text-sm font-medium leading-[16px] md:leading-6 ${props.disabled ? "text-gray-300" : "text-gray-700"
          } pointer-events-none whitespace-normal`}
        htmlFor={props.id}
      >
        {label}
      </label>
    </div>
  );
};
