const ERROR_FETCHING_TITLES =
  "%-%tolgee:material-composition-error-fetching-titles%-%";

/**
 * Builds a summary of the material composition selected in the matrix dynamics conditional.
 * @param {Object} params - The parameters for the function.
 * @param {Object} params[0] - The value object containing the material composition.
 * @param {string} params[1] - The URL of the material composition.
 */
export default function buildCompositionSummary(params) {
  const value = params[0];
  const materialType = params[1];
  const url = params[2];
  const materialTypeUrl = params[3];
  let result = "";

  const isEmptyValue = !value || !Array.isArray(value) || value.length === 0;

  const hasMaterialType =
    materialType &&
    materialType.length > 0 &&
    materialTypeUrl &&
    materialTypeUrl.length > 0;

  if (hasMaterialType) {
    fetch(materialTypeUrl)
      .then((response) => response.json())
      .then((data) => {
        const materials = data;

        const titleMap = materials.reduce(
          (acc, material) => ({
            ...acc,
            [material.value]: material.text,
          }),
          {}
        );

        result = ` (${titleMap[materialType]})`;
      });
  }

  if (isEmptyValue || !url || url.length === 0) {
    this.returnResult(result);
  } else {
    Promise.all([
      fetch(url).then((response) => response.json()),
      fetch(materialTypeUrl).then((response) => response.json()),
    ])
      .then(([materials, materialTypes]) => {
        const materialsTitleMap = materials.reduce(
          (acc, material) => ({
            ...acc,
            [material.value]: material.text,
          }),
          {}
        );

        const materialTypesTitleMap = materialTypes.reduce(
          (acc, materialType) => ({
            ...acc,
            [materialType.value]: materialType.text,
          }),
          {}
        );

        let compositionValue =
          value
            .map((item) => {
              if (!item?.percentage || !item?.fibre) return null;
              const percentage = item.percentage;
              const material =
                materialsTitleMap[item.fibre]?.toLowerCase() || item.fibre;
              return `${percentage}% ${material}`;
            })
            .filter(Boolean)
            .join(", ") || "";

        if (hasMaterialType) {
          compositionValue = compositionValue.concat(
            ` (${materialTypesTitleMap[materialType]})`
          );
        }

        this.returnResult(compositionValue);
      })
      .catch((error) => {
        this.returnResult(ERROR_FETCHING_TITLES);
        console.error(ERROR_FETCHING_TITLES, error);
      });
  }
}
