import {
  Base,
  PageModel,
  PanelModel,
  PanelModelBase,
  Question,
  QuestionDropdownModel,
  QuestionFileModel,
  QuestionMatrixDropdownModel,
  QuestionMatrixDynamicModel,
  QuestionPanelDynamicModel,
  SurveyModel,
} from "survey-core";
import { ExplainerImage, Metadata, SidebarContent } from "../types";
import * as _ from "lodash";
import { items as facilityMenuItems } from "./facility-menu-items";
import { Report } from "../types";

export * from "./prepare-survey";

export const countQuestions = (questions: Question[]) => {
  let counter = 0;
  questions.forEach((q) => {
    if (q.isVisible && q.isParentVisible && !isExpression(q)) {
      if (isMatrixDropdown(q) || isDynamicPanel(q)) {
        q.getNestedQuestions().forEach((nq) => {
          if (nq.isRequired) counter++;
        });
      } else if (q.isRequired) {
        counter++;
      }
    }
  });

  return counter;
};

export const countAnsweredQuestions = (questions: Question[]) => {
  let counter = 0;
  questions.forEach((q) => {
    if (isMatrixDropdown(q) || isDynamicPanel(q)) {
      q.getNestedQuestions().forEach((nq) => {
        if (nq.isRequired && !nq.isEmpty()) counter++;
      });
    } else if (q.isRequired && !q.isEmpty()) {
      counter++;
    }
  });

  return counter;
};

export const isExpression = (item: Base) => {
  return isElementType(item, "expression");
};

export const isDropdown = (item: Base) => {
  return isElementType(item, "dropdown");
};

export const isBoolean = (item: Base) => {
  return isElementType(item, "boolean");
};

export const isDynamicPanel = (item: Base) => {
  return isElementType(item, "paneldynamic");
};

export const isFile = (item: Base) => {
  return isElementType(item, "file");
};

export const isRadioGroup = (item: Base) => {
  return isElementType(item, "radiogroup");
};

export const isMatrixDynamic = (item: Base) => {
  return isElementType(item, "matrixdynamic");
};

export const isMatrixDropdown = (item: Base) => {
  return isElementType(item, "matrixdropdown");
};

export const isCheckbox = (item: Base) => {
  return isElementType(item, "checkbox");
};

export const isPageTitle = (q: Question) => {
  return q.name.includes("page-title");
};

export const isPanel = (item: Base): item is PanelModelBase => {
  return isElementType(item, "panel");
};

export const isRemovingRowFromDynamicPanelOrMatrix = (
  item: Base,
  oldValue: unknown,
  newValue: unknown
): boolean => {
  if (
    !isDynamicPanel(item) &&
    !isMatrixDynamic(item) &&
    !isMatrixDropdown(item)
  ) {
    return false;
  }

  if (!Array.isArray(oldValue) || !Array.isArray(newValue)) {
    return false;
  }

  return (
    (oldValue as Array<unknown>).length > (newValue as Array<unknown>).length
  );
};

const isElementType = (item: Base, expectedType: string) => {
  if (!item) {
    return false;
  }

  return item.getType() === expectedType;
};

const isAnswerKey = (answerKey: string, searchKey: string) => {
  const isValueName = /.+__.+/.test(answerKey);

  if (isValueName) {
    return answerKey.split("__").at(1) === searchKey;
  }

  return answerKey === searchKey;
};

export const setSurveyInitialData = (
  survey: SurveyModel,
  answers: Record<string, unknown>
) => {
  const keyToBeSetInOrder = [
    "pq-bom-t1-facility-information-facility-state-province",
    "pq-bom-t1-facility-information-facility-city",
    "pq-bom-t1-hq-address-state-province",
    "pq-bom-t1-hq-address-city",
    "pq-bop-t2-traceability-material-supplier-information-weaving-state-province",
    "pq-bop-t2-traceability-material-supplier-information-weaving-city",
    "pq-bop-t2-traceability-material-supplier-information-knitting-state-province",
    "pq-bop-t2-traceability-material-supplier-information-knitting-city",
    "pq-bop-t2-facility-information-facility-state-province",
    "pq-bop-t2-facility-information-facility-city",
    "pq-bop-t2-hq-address-state-province",
    "pq-bop-t2-hq-address-city",
    "pq-bom-t1-hq-address-country",
    "pq-bom-t1-hq-address-state-province",
    "pq-bom-t1-hq-address-city",
    "facility-state-province",
    "facility-city",
    "facility-hq-state-province",
    "facility-hq-city",
    "crp-facility-state-province",
    "crp-facility-city",
    "crp-facility-hq-state-province",
    "crp-facility-hq-city",
    "fhg-facility-state-province",
    "fhg-facility-city",
    "fhg-facility-hq-state-province",
    "fhg-facility-hq-city",
  ]; // put here the keys in order that they need to be set
  const shouldSkipInitialAnswer = (key: string) =>
    keyToBeSetInOrder.some((keyToSkip) => isAnswerKey(key, keyToSkip));

  const answerKeys = Object.keys(answers);
  const answersToSetBefore = answerKeys.filter(
    (key) => !shouldSkipInitialAnswer(key)
  );

  const initialAnswers = {
    ...answersToSetBefore.reduce<Record<string, unknown>>(
      (map, key) => Object.assign(map, { [key]: answers[key] }),
      {}
    ),
  };

  Object.entries(initialAnswers).forEach(([key, value]) => {
    survey.setValue(key, value);
  });

  keyToBeSetInOrder.forEach((keyToSearch) => {
    const keyToSet = answerKeys.find((key) =>
      isAnswerKey(key, keyToSearch)
    ) as keyof typeof answers;

    if (!keyToSet) {
      return;
    }

    // this has to be done in separate steps due the api dependency country => state => city.
    // also, the syntax has to be set like that as well, directly modifying the survey.data has no effect
    survey.data = {
      ...survey.data,
      [keyToSet]: answers[keyToSet],
    };
  });
};

/**
 * A function that generates an array of the number of items to display.
 * @param total  the total items to display. let's say 30.
 * @param intervals will determine the number of items to display.
 * @returns An array with the number of items to display.
 *
 * @example total = 30 and intervals = 5 then the result will be [5, 10, 15, 20, 25, 30]
 */
export function generateItemsPerPageOptions(total: number, intervals: number) {
  const completeIntervals = Math.floor(total / intervals);

  const result = Array.from(
    { length: completeIntervals },
    (_, i) => (i + 1) * intervals
  );

  if (total % intervals !== 0) {
    result.push(total);
  }

  return result;
}

export function getPanelIndexInsideDynamicPanelFromFileQuestion<
  T extends Question
>(item: T) {
  const currentPanel = item.parent as PanelModel;
  const parentPanel = currentPanel.parentQuestion as QuestionPanelDynamicModel;
  const currentPanelIndex = parentPanel.panels.findIndex(
    (panel) => panel.id === currentPanel.id
  );

  if (currentPanelIndex < 0) {
    return undefined;
  }

  return currentPanelIndex;
}

function getRowIndexInsideMatrixDynamicFromFileQuestion(
  question: QuestionFileModel
) {
  const parentQuestion = question.parentQuestion as QuestionMatrixDynamicModel;
  const visibleRows = parentQuestion.visibleRows;

  const currentPanelIndex = visibleRows.findIndex((row) =>
    row.cells.some((cell) => cell.question.id === question.id)
  );

  if (currentPanelIndex < 0) {
    return undefined;
  }

  return currentPanelIndex;
}

// this is to solve when a matrixdropdown/dynamicpanel has a dynamicPanel as parent
// { type: "dyanmicpanel", elements: [ { type: "some_other_item" }, { type: matrixdropdow, columns: [ { type: file } ] } ] }
// we need to indicate to which index the section belongs within the metadata
// { questionKey: string, groupName: string, groupIndex: number, parentGroupIndex: number }
export const onFileEventGetRootParentDynamicPanelIndexAndValueName = (
  question: QuestionFileModel
): { parentGroupIndex?: number; parentValueName?: string } => {
  let parentInfo: any = {
    parentGroupIndex: undefined,
    parentValueName: undefined,
  };
  const { parentQuestion } = question;
  const parentFromItemParent = parentQuestion?.parentQuestion;

  if (!parentFromItemParent) {
    return parentInfo;
  }

  const isParentDynamicPanel = isDynamicPanel(parentFromItemParent);

  if (!isParentDynamicPanel) {
    return parentInfo;
  }

  const parentGroupIndex =
    getPanelIndexInsideDynamicPanelFromFileQuestion(parentQuestion);
  const parentValueName = parentQuestion?.parentQuestion?.valueName;
  parentInfo = { parentGroupIndex, parentValueName };
  return parentInfo;
};

/**
 * Check if a boolean question is renderedAs "checkbox".
 * If yes, replace it with our own basic renderer "boolean-as-checkbox-question"
 * @param question
 */
export function prepareBooleanAsChecbkoxQuestion(question: Question) {
  question.renderAs = "boolean-as-checkbox-question";
}

export function isDisplayMode(survey: SurveyModel): boolean {
  return survey.mode === "display";
}

export function replaceChoicesUrlParamValuesWithAnswers(
  answers: any,
  questions: Question[]
) {
  questions.forEach((q) => {
    if (isDropdown(q)) {
      updateDropdownQuestionUrl(q as unknown as QuestionDropdownModel, answers);
    }
  });
}

function updateDropdownQuestionUrl(
  dropdownQuestion: QuestionDropdownModel,
  answers: any
) {
  let url = dropdownQuestion.choicesByUrl.url;
  const answersKeys = Object.keys(answers);

  const queryString = url.split("?")[1];
  if (queryString) {
    const params = queryString.split("&");
    params.forEach((param) => {
      const [, value] = param.split("=");
      const paramValueWithoutBrackets = value.slice(1, -1);

      if (answersKeys.includes(paramValueWithoutBrackets)) {
        url = url.replace(value, answers[paramValueWithoutBrackets]);
      }
    });
  }

  dropdownQuestion.choicesByUrl.url = url;
}

export function buildFileMetadata(
  fieldName: string,
  question: QuestionFileModel
): Metadata {
  const { parentQuestion, selectedElementInDesign } = question;
  const metadata: Metadata = {};

  if (parentQuestion) {
    const parentValueName = parentQuestion.getValueName();
    if (parentValueName) metadata.questionKey = fieldName;
    if (isMatrixDropdown(parentQuestion)) {
      metadata.groupName =
        selectedElementInDesign.getDataFilteredProperties().row.name;
    } else if (isDynamicPanel(parentQuestion)) {
      metadata.questionIndex =
        getPanelIndexInsideDynamicPanelFromFileQuestion(question);
    } else if (isMatrixDynamic(parentQuestion)) {
      metadata.questionIndex =
        getRowIndexInsideMatrixDynamicFromFileQuestion(question);
    }

    const parentInfo =
      onFileEventGetRootParentDynamicPanelIndexAndValueName(question);
    metadata.parentGroupIndex = parentInfo.parentGroupIndex;
    metadata.parentValueName = parentInfo.parentValueName;
  }

  return metadata;
}
export type VisibleQuestionItem = {
  name: string;
  isGroup: boolean;
  isNamedGroup: boolean;
  columns?: Array<string>;
  rows?: Array<string>;
  groups?: Array<Array<VisibleQuestionItem>>;
};

export const getAllVisibleQuestionValueNames = (
  survey: SurveyModel
): Array<VisibleQuestionItem> => {
  return survey.visiblePages
    .flatMap(getNestedQuestionValueName)
    .filter(Boolean);
};

const getNestedQuestionValueName = (
  element: Question | PanelModel | PageModel
) => {
  if (!element.isVisible) {
    return undefined;
  }

  if (isMatrixDropdown(element)) {
    const rows = (element as QuestionMatrixDropdownModel).visibleRows.map(
      (row) => row.rowName
    );
    const columns = (element as QuestionMatrixDropdownModel).visibleColumns.map(
      (column) => column.name
    );

    return buildVisibleQuestionItem(
      (element as QuestionMatrixDropdownModel).getValueName(),
      {
        isGroup: true,
        isNamedGroup: true,
        rows,
        columns,
      }
    );
  }

  if (isMatrixDynamic(element)) {
    const groups = (element as QuestionMatrixDynamicModel).visibleRows.map(
      (row) =>
        row.cells.map((cell) =>
          buildVisibleQuestionItem(cell.question.getValueName())
        )
    );

    return buildVisibleQuestionItem((element as Question).getValueName(), {
      isGroup: true,
      isNamedGroup: false,
      groups,
    });
  }

  if (isDynamicPanel(element)) {
    const groups: Array<Array<VisibleQuestionItem>> = (
      element as QuestionPanelDynamicModel
    ).visiblePanels.map((panel) =>
      panel.elements
        .map((panelElement) =>
          getNestedQuestionValueName(panelElement as Question)
        )
        .filter(Boolean)
    );

    return buildVisibleQuestionItem((element as Question).getValueName(), {
      isGroup: true,
      isNamedGroup: false,
      groups,
    });
  }

  if (element.isQuestion) {
    return buildVisibleQuestionItem((element as Question).getValueName());
  }

  return element.elements.flatMap(getNestedQuestionValueName);
};

const buildVisibleQuestionItem = (
  name: string,
  options: Omit<VisibleQuestionItem, "name"> = {
    isGroup: false,
    isNamedGroup: false,
  }
): VisibleQuestionItem => {
  return {
    name,
    ...options,
  };
};

export function isExplainerImage(
  explainerImage: string | ExplainerImage
): explainerImage is ExplainerImage {
  return (explainerImage as ExplainerImage).src !== undefined;
}

export function removeKeysFromArray(arr: any[], keysToRemove: Set<string>) {
  const clonedArr = _.cloneDeep(arr);
  return clonedArr?.map((obj) => removeKeysFromObject(obj, keysToRemove));
}

export function removeKeysFromObject(obj: any, keysToRemove: Set<string>) {
  const clonedObj = _.cloneDeep(obj);

  deepCleanObject(clonedObj, keysToRemove);

  return clonedObj;
}

function deepCleanObject(obj: any, keysToRemove: Set<string>) {
  // Recursively iterate over the object and remove keys present in the keysToRemove set
  _.forOwn(obj, (value, key) => {
    if (keysToRemove.has(key)) {
      delete obj[key]; // Remove the key if it's found in the set
      keysToRemove.delete(key);
    } else if (_.isObject(value)) {
      // If the value is an object, recursively clean that object
      deepCleanObject(value, keysToRemove);
    }
  });
}

export function isEqual(obj1: any, obj2: any) {
  return _.isEqual(obj1, obj2);
}

export function compareFilteredChanges<T extends object | any[]>(
  newVal: T,
  oldVal: T,
  keys: Set<string>,
  removeKeys: (val: T, keys: Set<string>) => T
) {
  const filteredNewVal = removeKeys(newVal, keys);

  if (!isEqual(filteredNewVal, newVal)) {
    return false;
  }

  const filteredOldVal = removeKeys(oldVal, keys);
  return isEqual(filteredOldVal, oldVal);
}

export function getSideBarContent(
  survey: SurveyModel,
  resourceType: string
): SidebarContent {
  /**
   * Get the navigation menu items based on the resource type
   *
   * @param resourceType
   * @returns
   */
  const getNavigationMenuItems = (resourceType: string) => {
    if (resourceType === "facility-questionnaire") {
      return facilityMenuItems;
    }

    return survey.pages.slice(1).map((page, index) => ({
      id: index.toString(),
      title: page.num.toString(),
      pages: [{ name: page.name }],
    }));
  };

  return {
    navigationItems: getNavigationMenuItems(resourceType),
  };
}

export function sumOf(reports: Report[], columnNames: string[]): number {
  let totalSum = 0;
  for (const columnName of columnNames) {
    const compareKeyWith =
      // eslint-disable-next-line no-process-env
      process.env.NODE_ENV === "production"
        ? `__${columnName}`
        : `${columnName}`;

    for (const obj of reports) {
      for (const outerKey in obj) {
        const innerObj = obj[outerKey];
        let foundMatch = false;

        for (const innerKey in innerObj) {
          if (!innerKey.endsWith(compareKeyWith)) continue;

          foundMatch = true;
          const rawValue = innerObj[innerKey];
          if (rawValue === null || rawValue === undefined) continue;

          try {
            const value = Number(rawValue);
            totalSum += !isNaN(value) ? value : 0;
          } catch (e) {
            console.error(`Error parsing value for ${innerKey}:`, e);
          }
        }

        if (!foundMatch) break;
      }
    }
  }
  return totalSum;
}

export function countColumnsWithValues(
  reports: Array<Report | null>,
  columnNames: string[]
): number {
  let count = 0;
  for (const columnName of columnNames) {
    const compareKeyWith =
      // eslint-disable-next-line no-process-env
      process.env.NODE_ENV === "production"
        ? `__${columnName}`
        : `${columnName}`;

    for (const obj of reports) {
      for (const outerKey in obj) {
        const innerObj = obj[outerKey];
        let foundMatch = false;

        for (const innerKey in innerObj) {
          if (!innerKey.endsWith(compareKeyWith)) continue;

          foundMatch = true;
          const rawValue = innerObj[innerKey];
          if (rawValue === null || rawValue === undefined) continue;

          if (typeof rawValue === "boolean" && rawValue) {
            count++;
            break;
          }
        }

        if (!foundMatch) break;
      }
    }
  }
  return count;
}

export function getProductionRatio(reports: Report[]): number {
  let facilitySum = 0;
  let clientSum = 0;

  for (const obj of reports) {
    for (const outerKey in obj) {
      const innerObj = obj[outerKey];

      for (const innerKey in innerObj) {
        const compareKey =
          // eslint-disable-next-line no-process-env
          process.env.NODE_ENV === "production" ? `__${innerKey}` : innerKey;

        if (
          compareKey.includes(
            "crp-ext-reported-productions-from-facility-column"
          )
        ) {
          const rawValue = innerObj[innerKey];
          if (rawValue === null || rawValue === undefined) continue;

          try {
            const value = Number(rawValue);
            facilitySum += !isNaN(value) ? value : 0;
          } catch (e) {
            console.error(`Error parsing facility value for ${innerKey}:`, e);
          }
        }

        if (
          compareKey.includes("crp-ext-reported-productions-from-client-column")
        ) {
          const rawValue = innerObj[innerKey];
          if (rawValue === null || rawValue === undefined) continue;

          try {
            const value = Number(rawValue);
            clientSum += !isNaN(value) ? value : 0;
          } catch (e) {
            console.error(`Error parsing client value for ${innerKey}:`, e);
          }
        }
      }
    }
  }

  return facilitySum === 0 ? 0 : (clientSum / facilitySum) * 100;
}

export function hasRatioField(
  report: Record<string, any> | undefined | null,
  ratioFields: string[]
): boolean {
  if (!report) {
    return false;
  }

  // Check each month's data
  for (const monthData of Object.values(report)) {
    // For each month's inner object
    for (const key of Object.keys(monthData)) {
      // Check if any of the ratio fields exists in the key
      if (ratioFields.some((field) => key.includes(field))) {
        return true;
      }
    }
  }
  return false;
}
