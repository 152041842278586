export default function composeBomT1DialogQuestionTitle(params) {
  const styleNumber = params[0];
  const position = params[1];
  const positionsUrl = params[2];

  if (!position || !positionsUrl) {
    this.returnResult("");
    return;
  }

  fetch(positionsUrl)
    .then((response) => response.json())
    .then((positions) => {
      const positionText = positions.find((p) => p.value === position)?.title;
      this.returnResult(
        `${
          styleNumber ? `${styleNumber} | ` : ""
        }%-%tolgee:pq-bom-t1-dialog-title-position%-%: ${positionText}`
      );
    })
    .catch((error) => {
      this.returnResult(
        "%-%tolgee:pq-bom-t1-dialog-title-error-fetching-positions%-%"
      );
      console.error(error);
    });
}
