/**
 * QuestionMatrixDynamicModel doesn't provide renderAs option, so we need to extend it
 * This file implements a styled version of the Matrix Dynamic question type with custom rendering
 */

import * as React from "react";
import { ReactQuestionFactory, SurveyQuestionMatrixDropdownBase } from "survey-react-ui";
import { QuestionMatrixDynamicModel, Serializer, QuestionMatrixDropdownRenderedTable} from "survey-core";
import * as Icon from "../../../icons";
import "./index.css";

const MATRIX_DYNAMIC_TYPE = "matrixdynamic-conditional";

/**
 * Custom implementation of the Matrix Dynamic rendered table that provides styled buttons
 * and custom row actions for drag & drop, remove, and detail panel functionality
 */
class QuestionMatrixDynamicRenderedTableConditional extends QuestionMatrixDropdownRenderedTable {
  protected get question(): QuestionMatrixDynamicModel {
    return this.matrix as QuestionMatrixDynamicModel;
  }
}

/**
 * Extended Matrix Dynamic question model that adds styling capabilities
 * and custom properties like hasRemoveRows
 */
export class QuestionMatrixDynamicConditionalModel extends QuestionMatrixDynamicModel {
  constructor(name: string) {
    super(name);
    this.hasRemoveRows = true;
    this.allowRemoveRows = true;
  }

  /**
   * Controls whether remove row buttons should be displayed
   */
  public get hasRemoveRows(): boolean {
    return this.getPropertyValue("hasRemoveRows", false);
  }
  
  public set hasRemoveRows(val: boolean) {
    this.setPropertyValue("hasRemoveRows", val);
  }

  /**
   * Returns the question type identifier
   */
  getType() { 
    return MATRIX_DYNAMIC_TYPE; 
  }

  /**
   * Creates a custom rendered table instance for this question
   */
  protected createRenderedTable(): QuestionMatrixDropdownRenderedTable {
    return new QuestionMatrixDynamicRenderedTableConditional(this);
  }

  /**
   * Updates the rows number dropdown with the given number
   * @param number - The number to set the rows number dropdown to
   */
  protected updateRowsNumberDropdown(number: number): void {
    const rowsNumberDropdown = this.survey?.getQuestionByName(this.rowsNumberDropdownName);
    if (rowsNumberDropdown) {
      rowsNumberDropdown.value = number.toString();
    }
  }

  /**
   * Override removeRow to prevent value update
   * This ensures only the row deletion event is triggered
   */
  public removeRow(index: number): void {
    if (index < 0 || index >= this.rowCount) return;
    if (this.isValueEmpty(this.value)) return;
    var val = this.createNewValue();
    if (!val) val = [];
    val.splice(index, 1);
    this.rows.splice(index, 1);
    this.value = val;
    this.updateRowsNumberDropdown(this.rowCount);
  }
}

/**
 * React component that renders the styled Matrix Dynamic question
 * Provides custom rendering for add/remove row buttons and empty state
 */
export class SurveyQuestionMatrixDynamicConditional extends SurveyQuestionMatrixDropdownBase {
  get question(): QuestionMatrixDynamicConditionalModel {
    return this.questionBase as QuestionMatrixDynamicConditionalModel;
  }

  /**
   * Renders the main question element including table, buttons and empty state
   */
  protected renderElement(): JSX.Element {
    const cssClasses = this.question.cssClasses;
    const showTable = this.question.renderedTable.showTable;
    
    return (
      <div className="matrix-dynamic-conditional text-sm">
        {this.renderAddRowButtonOnTop(cssClasses)}
        {showTable ? this.renderTableDiv() : this.renderNoRowsContent(cssClasses)}
        {this.renderAddRowButtonOnBottom(cssClasses)}
      </div>
    );
  }

  /**
   * Renders the add row button at the top of the matrix if enabled
   * @param cssClasses - CSS classes to apply to the button
   */
  protected renderAddRowButtonOnTop(cssClasses: any): JSX.Element | null {
    if (!this.question.renderedTable.showAddRowOnTop) return null;
    return this.renderAddRowButton(cssClasses);
  }

  /**
   * Renders the add row button at the bottom of the matrix if enabled
   * @param cssClasses - CSS classes to apply to the button
   */
  protected renderAddRowButtonOnBottom(cssClasses: any): JSX.Element | null {
    if (!this.question.renderedTable.showAddRowOnBottom) return null;
    return this.renderAddRowButton(cssClasses);
  }

  /**
   * Renders content shown when the matrix has no rows
   * @param cssClasses - CSS classes to apply to the empty state
   */
  protected renderNoRowsContent(cssClasses: any): JSX.Element {
    return (
      <div className={cssClasses.emptyRowsSection}>
        <div className={cssClasses.emptyRowsText}>
          {this.renderLocString(this.question.locEmptyRowsText)}
        </div>
        {this.question.renderedTable.showAddRow && 
          this.renderAddRowButton(cssClasses, true)
        }
      </div>
    );
  }

  /**
   * Updates the rows number dropdown with the given number
   * @param number - The number to set the rows number dropdown to
   */
  updateRowsNumberDropdown(number: number) {
    const rowsNumberDropdown = this.question.survey?.getQuestionByName(this.question.rowsNumberDropdownName);
    if (rowsNumberDropdown) {
      rowsNumberDropdown.value = number.toString();
    }
  }

  /**
   * Renders an add row button with consistent styling
   * @param cssClasses - CSS classes to apply to the button
   * @param isEmptySection - Whether this button is being rendered in the empty state
   */
  protected renderAddRowButton(
    cssClasses: any,
    isEmptySection: boolean = false
  ): JSX.Element {
    return (
      <button 
        className="add-row-button"
        onClick={
          () => {
            this.question.addRowUI();
            this.updateRowsNumberDropdown(this.question.rowCount);
          }
        }
      >
        <Icon.PlusCircle color="var(--indigo-500)" />
        {this.question.addRowText}
      </button>
    );
  }
}

// Register the custom component
Serializer.addClass(
  MATRIX_DYNAMIC_TYPE,
  [
    {
      name: "rowsNumberDropdownName",
      default: "",
    },
  ],
  function() {
    return new QuestionMatrixDynamicConditionalModel("");
  },
  "matrixdynamic"
);

ReactQuestionFactory.Instance.registerQuestion(MATRIX_DYNAMIC_TYPE, (props) => {
  return React.createElement(SurveyQuestionMatrixDynamicConditional, props);
});